import React from "react";
import WebUserProfile from "./web/WebUserProfile";
import MobileUserProfile from "./mobile/mobileUserProfile";
function Contiener(props) {
  return (
    <div>
      {props.deviceType == "web" ? <WebUserProfile /> : <MobileUserProfile />}
    </div>
  );
}

export default Contiener;
