import React, { useState } from "react";
import WebCard from "./card";
import WebSearch from "./search";
import BreadCrumbs from "../../../../components/breadCrumbs/breadCrumbs";
import { t as __translate } from "i18next";

function WebCardList(props) {
  return (
    <React.Fragment>
      <BreadCrumbs
        crumpsDetails={[
          {
            href: "/",
            titel: __translate("public.bread_crumbs.home"),
          },
          {
            titel: __translate("public.bread_crumbs.my_cargos"),
            color: "black",
          },
        ]}
      />

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex ">
        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3  ">
          <WebSearch
            handelSearchBtn={props.handelSearchBtn}
            isLoading={props.isLoading}
          />
        </div>
        <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 cardList__container cardList__container--userCargos">
          <WebCard data={props.data} isLoading={props.isLoading} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default WebCardList;
