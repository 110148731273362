import React from "react";
import Dropzone from "react-dropzone";
import LightboxGallary from "../LightBox/LightBox";
import { uploadImg, firebase, ref } from "../../config/firebase";
import { v4 as generateRandomID } from "uuid";
import CircularProgressbar from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { t as __translate } from "i18next";
import Alert from "react-s-alert";
// import Confirm from "../confirm/confirm";
import { config } from "../../config/config";
import "./dropZone.css";
import { CircularProgress } from "@mui/material";

class FileDropzone extends React.Component {
  initialState = {
    username: "",
    isUploading: false,
    generatingThumb: false,
    progress: 0,
  };

  constructor(props) {
    super();
    this.state = this.initialState;
  }

  onDrop = async (files) => {
    try {
      this.setState({ progress: 0, generatingThumb: true, isUploading: true });

      // Create the file metadata
      var metadata = {};
      var file = files[0];
      if (!file) {
        this.setState({
          progress: 0,
          generatingThumb: false,
          openAlert: true,
          alertMessage: {
            message: "fdxcubhdfcv",
            type: "error",
          },
        });
        return;
      }
      var fileName = this.props.filePrefix
        ? this.props.filePrefix + generateRandomID()
        : generateRandomID();
      var ext = this.extractExtension(file.name);

      // Ensure there is an extension in the filename
      if (!this.extractExtension(fileName)) {
        fileName += this.extractExtension(file.name);
      }
      var resizeOriginal = false;

      if (ext == ".png" || ext == ".jpg") {
        var resizeOriginal = true;
      }
      let thumbURL = await uploadImg(fileName, files);
      this.setState({ isUploading: false, originalFile: thumbURL });
      this.props.imageChangehandler("originalURL", thumbURL);
      this.setState({ progress: 0, generatingThumb: false });
      const url = config.cloudFunctionURL + "/generateThumb?imageURL=" + thumbURL + "&resizeOriginal=" + resizeOriginal
      fetch(url)
        .then(response => response.json())
        .then(blob => {
          this.props.imageChangehandler("thumnailURL", blob.imageUrl);
          this.setState({ progress: 0, generatingThumb: false });
        })
        .catch(error => console.error(error));

    } catch (error) {
      console.log(error);

      this.setState({
        progress: 0,
        generatingThumb: false,
        openAlert: true,
        alertMessage: { message: "", type: "error" },
      });
    }
  };

  extractExtension = (filename) => {
    return /(?:\.([^.]+))?$/.exec(filename)[0];
  };

  resetComponent = () => {
    this.props.imageChangehandler("originalURL", "");
    this.props.imageChangehandler("thumnailURL", "");
    this.setState({ progress: 0 });
  };

  render() {
    return (
      <>
        {this.props.thumnail && (
          <div style={{ position: "relative", top: 0, left: 0 }} height={this.props.height} width={this.props.width}>
            <LightboxGallary
              originalFile={this.props.originalFile}
              imageSource={[this.props.thumnail]}
              isTruckingCompanyPhoto={this.props.isTruckingCompanyPhoto}
              height={this.props.height}
              width={this.props.width}
              resetComponent={this.resetComponent}
              showCloseIcon={this.props.showCloseIcon}
              label={this.props.label}
            />
          </div>
        )}

        {!this.props.thumnail && (
          <Dropzone
            maxSize={this.props.maxSize}
            onDrop={this.onDrop.bind(this)}
            style={{ width: "100%" }}
            activeStyle={{ border: "solid 2px #0F0" }}
            accept={this.props.acceptedFiles}>

            <div
              className='dropzone'
              style={{
                display: this.props.display ? this.props.display : "",
                flexDirection: this.props.flexDirection ? this.props.flexDirection : "",
                justifyContent: this.props.justifyContent ? this.props.justifyContent : "",
                width: this.props.width ? (this.props.isTruckingCompanyPhoto ? "117px" : this.props.width) : "100%",
                height: this.props.height ? (this.props.isTruckingCompanyPhoto ? "115px" : this.props.height) : "100%",
                minHeight: this.props.minHeight ? this.props.minHeight : "10px",
                maxHeight: this.props.maxHeight ? this.props.maxHeight : "211px",
                margin: this.props.isTruckingCompanyPhoto ? "unset" : "auto",
                textAlign: "center",
              }}>
              {this.state.progress > 0 && !this.state.generatingThumb && (
                <div style={{ width: '30%', height: '30%', margin: 'auto' }}>
                  <CircularProgressbar percentage={Math.round(this.state.progress)} />
                  {/* <CircularProgress /> */}
                </div>
              )}

              {this.state.generatingThumb && (
                <div
                  style={{ width: "100%", margin: "auto", textAlign: "center" }}
                  className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                  <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12'>
                    <label className='dropZoneLoader'></label>
                  </div>
                  <div className='col-xs-12 col-sm-12 col-md-12 col-lg-12' style={{ marginTop: "25px" }}>
                    <label> {__translate("delivery_report.process_image")}</label>
                  </div>
                </div>
              )}

              {this.state.progress === 0 && (
                <label style={{ display: "block" }}>
                  {" "}
                  {this.props.isTruckingCompanyPhoto ? "شعار الشركة" : this.props.label}{" "}
                </label>
              )}
            </div>
          </Dropzone>
        )}
      </>
    );
  }
}

export default FileDropzone;
