import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Details from "./userProfileItems/details";
import History from "./userProfileItems/history";
import Rating from "./userProfileItems/rating";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import InfoIcon from "@mui/icons-material/Info";
import ArchiveIcon from "@mui/icons-material/Archive";
const drawerWidth = 240;
function WebUserProfile() {
  const [renderDetails, setRenderDetails] = useState(true);
  const [renderhistory, setRenderHistory] = useState(false);
  const [renderRating, setRenderRating] = useState(false);

  const renderTargetPage = (pageName) => {
    setRenderHistory(false);
    setRenderRating(false);
    setRenderDetails(false);

    if (pageName == "details") {
      setRenderDetails(true);
    }
    if (pageName == "history") {
      setRenderHistory(true);
    }
    if (pageName == "rating") {
      setRenderRating(true);
    }
  };

  return (
    <React.Fragment>
      <div
        // className="container"
        style={{
          width: "70%",
          height: "60%",
          minHeight: "44.6vh",
          margin: "2% auto auto auto",
          position: "relative",
          marginTop: "",
          border: "1px solid #E0E0E0",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            position="absolute"
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              padding: "0",
              margin: "0",
              right: `${drawerWidth}px`,
              // mt: "4%",
              // mr: `${drawerWidth}px`,
            }}
          >
            <Toolbar>
              <Typography variant="h6" noWrap component="div">
                Permanent drawer
              </Typography>
            </Toolbar>
          </AppBar>

          <Drawer
            sx={{
              width: `${drawerWidth}px`,
              flexShrink: 0,
              "& .MuiDrawer-root": {
                position: "absolute",
                width: `${drawerWidth}px`,
              },
              "& .MuiPaper-root": {
                position: "absolute",
                width: `${drawerWidth}px`,
              },
            }}
            variant="permanent"
            anchor="right"
          >
            <Toolbar />
            <Divider />
            <List style={{ padding: "0" }}>
              <ListItem key={Math.random() * 100} disablePadding>
                <ListItemButton
                  onClick={() => {
                    renderTargetPage("details");
                  }}
                  selected={renderDetails}
                >
                  <InfoIcon />
                  <ListItemText
                    style={{ textAlign: "right", margin: "0 1vh" }}
                    primary={"تفاصيل"}
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem key={Math.random() * 100} disablePadding>
                <ListItemButton
                  onClick={() => {
                    renderTargetPage("history");
                  }}
                  selected={renderhistory}
                >
                  <ArchiveIcon />
                  <ListItemText
                    style={{ textAlign: "right", margin: "0 1vh" }}
                    primary={"سجلي"}
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem key={Math.random() * 100} disablePadding>
                <ListItemButton
                  onClick={() => {
                    renderTargetPage("rating");
                  }}
                >
                  <StarHalfIcon />
                  <ListItemText
                    style={{ textAlign: "right", margin: "0 1vh" }}
                    primary={"تقيماتي"}
                  />
                </ListItemButton>
              </ListItem>
              <Divider />
            </List>
          </Drawer>

          <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: "background.default", p: 2, mt: "6%" }}
          >
            {renderDetails && <Details />}
            {renderhistory && <History />}
            {renderRating && <Rating />}
          </Box>
        </Box>
      </div>
    </React.Fragment>
  );
}

export default WebUserProfile;
