import React, { useState, useEffect, useContext } from "react";
import WebList from "./web/container";
import MobileList from "./mobile/container";
import axios from "../../../config/axios/axios";
import { t as __translate } from "i18next";
import { UserContext } from "../../../useContext";
import { Alert, Snackbar, Stack } from "@mui/material";

function Container(props) {
  const [data, setData] = useState([]);
  const [searchBtnIsClicked, setSearchBtnIsClicked] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const context = useContext(UserContext);
  const [offset, setOffset] = useState(0);

  // const [fulldata, setFulldata] = useState([]);
  // const loadMore = () => {
  //   setOffset(offset + 15);
  // };
  useEffect(() => {
    if (context.checkLogIn() && context.systemReady) {
      getWaybillOrderList();
      context.setRefreshMyCargoList(false);
    }
    // setSearchBtnIsClicked(false);
  }, [
    searchBtnIsClicked,
    offset,
    context.systemReady,
    context.refreshMyCargoList,
  ]);

  const handelSearchBtn = (triger) => {
    setSearchBtnIsClicked(triger);
  };

  const getWaybillOrderList = () => {
    setIsLoading(true);
    let params = {
      method: "available_land_trips_platform",
      declaration_number: context.advanceSearchResult
        ? context.advanceSearchResult.declaration_number == ""
          ? null
          : context.advanceSearchResult.declaration_number
        : null,
      bl: context.advanceSearchResult
        ? context.advanceSearchResult.bl == ""
          ? null
          : context.advanceSearchResult.bl
        : null,
      cid: context.advanceSearchResult
        ? context.advanceSearchResult.cid == ""
          ? null
          : context.advanceSearchResult.cid
        : null,
      offset: offset,
      // limit: 1000,
    };

    let params2 = {
      method: "searchMyCargo",
      declaration_number: context.advanceSearchResult
        ? context.advanceSearchResult.declaration_number == ""
          ? null
          : context.advanceSearchResult.declaration_number
        : null,
      bl: context.advanceSearchResult
        ? context.advanceSearchResult.bl == ""
          ? null
          : context.advanceSearchResult.bl
        : null,
      cid: context.advanceSearchResult
        ? context.advanceSearchResult.cid == ""
          ? null
          : context.advanceSearchResult.cid
        : null,
      offset: offset,
      limit: 1000,
    };

    axios
      .get("/waybill_order", { params: params2 })
      .then((res) => {
        let containers = res.data;
        axios
          .get("/cargo", { params: params })
          .then((res) => {
            res.data &&
              res.data.forEach((ele) => {
                containers.push(ele);
              });
            console.log(containers, "containers");
            // setFulldata([...fulldata, ...data]);
            setData(containers);
            setIsLoading(false);
            console.log(data, "dta");
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log.error(error);
      });
  };
  return (
    <div>
      {context.renderLogin(
        __translate("public.login_message.watch_the_cargo"),
        getWaybillOrderList
      )}
      {props.deviceType == "web" ? (
        <WebList
          data={data}
          handelSearchBtn={handelSearchBtn}
          isLoading={isLoading}
          // loadMore={loadMore}
        />
      ) : (
        <div className="cardList ">
          <MobileList
            data={data}
            handelSearchBtn={handelSearchBtn}
            isLoading={isLoading}
            // loadMore={loadMore}
          />
        </div>
      )}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={context.globalAleartTriger}
          autoHideDuration={6000}
          onClose={() => {
            context.handelGlobalAleart(false);
          }}
        >
          <Alert
            severity="success"
            onClose={() => {
              context.handelGlobalAleart(false);
            }}
            sx={{ width: "100%" }}
          >
            {__translate("public.snackbar_reserved")}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default Container;
