import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import DriveRImg from "../../../../assets/arabia.png";
import Rating from "@mui/material/Rating";
import ChartBody from "../../../../components/chart/chart";
import { OverlayTrigger, Tooltip, Popover, Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import WebSckeleton from "../../../../components/sckeltonLoadingIndecator/webSckeleton";
import axios from "../../../../config/axios/axios";

import { t as __translate } from "i18next";
import Autocomplete from "@mui/material/Autocomplete";
import { muiCoustomStyles } from "../../../../assets/mui_style";
import BreadCrumbs from "../../../../components/breadCrumbs/breadCrumbs";
import Instractions from "../../../../components/instractions/instractions";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import HomeIcon from "@mui/icons-material/Home";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import CircularProgress from "@mui/material/CircularProgress";
import { green, red } from "@mui/material/colors";
import { Alert, Divider, Snackbar, Stack } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../../../../useContext";
import Tags from "../../../../components/tags/tags";

export default function Container(props) {
  const context = useContext(UserContext);
  const classes = muiCoustomStyles();
  const navigate = useNavigate();

  const [base_price, setBase_price] = useState(null);
  const [discharge_delay, setDischarge_delay] = useState(null);
  const [inspection_delay, setInspection_delay] = useState(null);
  const [statistics, setStatistics] = useState(null);
  const [driver_info, setdriver_info] = useState(null);
  const [details, setDetails] = useState(null);
  const [create_wo_error_msg, setCreate_wo_error_msg] = useState("");
  const [withReturnCheckbox, setWithReturnCheckbox] = useState(null);
  const [withInspectionCheckbox, setWithInspectionCheckbox] = useState(null);
  const [printPriceInBoneCheckbox, setPrintPriceInBoneCheckbox] =
    useState(null);
  const [showStatisticsAndDriver, setShowStatisticsAndDriver] = useState(true);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmBtnText, setConfirmBtnText] = useState(
    __translate("public.confirmation_modal.request_confirmation")
  );
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [required, setRequired] = useState(false);

  // Locations
  const [locations, setLocations] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [locationId, setLocationId] = useState(null);
  const [locationObj, setLocationObj] = useState(null);
  const [subLocationObj, setSubLocationObj] = useState(null);
  const [locationFreeText, setLocationFreeText] = useState(null);

  const [price_message, setPriceMessage] = useState(null);







  // --------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------
  const [driver_mobile_in_discharge_location, setDriverMobileInDischargeLocation] = useState("");
  const [discharge_time_details, setDischargeTimeDetails] = useState("");
  const [export_containers_details, setExportContainersDetails] = useState("");
  const [late_fine_every_day, setLateFineEvryDay] = useState("");
  // --------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------

















  useEffect(() => {
    // get the location and city from options to bedefualt value in case wabill order
    
    if (props.details) {

      fillLocations();
      setLocationId(locationId ? locationId : props.details.location_id);
      getPriceDetails();

      let props_sub_location_ob = {};
      props_sub_location_ob.id =
        typeof props.details.location_sub_id == "undefined"
          ? ""
          : props.details.location_sub_id;
      props_sub_location_ob.name =
        typeof props.details.location_sub_name == "undefined"
          ? ""
          : __translate("wo.sub_location." + props.details.location_sub_id);
      setSubLocationObj(props_sub_location_ob);

      let props_location_ob = {};
      props_location_ob.id =
        typeof props.details.location_id == "undefined"
          ? ""
          : props.details.location_id;
      props_location_ob.name =
        typeof props.details.location_name == "undefined"
          ? ""
          : __translate("wo.location." + props.details.location_id);
      setLocationObj(props_location_ob);

      setLocationFreeText(
        locationFreeText
          ? locationFreeText
          : typeof props.details.location_free_text == "undefined"
            ? ""
            : props.details.location_free_text
      );












      // --------------------------------------------------------------------------------------------------
      // --------------------------------------------------------------------------------------------------
      // --------------------------------------------------------------------------------------------------
      setDriverMobileInDischargeLocation(
        driver_mobile_in_discharge_location
          ? driver_mobile_in_discharge_location
          : typeof props.details.driver_mobile_in_discharge_location == "undefined"
            ? ""
            : props.details.driver_mobile_in_discharge_location
      )
      setDischargeTimeDetails(
        discharge_time_details
          ? discharge_time_details
          : typeof props.details.discharge_time_details == "undefined"
            ? ""
            : props.details.discharge_time_details
      )
      setExportContainersDetails(
        export_containers_details
          ? export_containers_details
          : typeof props.details.export_containers_details == "undefined"
            ? ""
            : props.details.export_containers_details
      )
      setLateFineEvryDay(
        late_fine_every_day
          ? late_fine_every_day
          : typeof props.details.late_fine_every_day == "undefined"
            ? ""
            : props.details.late_fine_every_day
      )
      // --------------------------------------------------------------------------------------------------
      // --------------------------------------------------------------------------------------------------
      // --------------------------------------------------------------------------------------------------


      setWithReturnCheckbox(
        withReturnCheckbox ? withReturnCheckbox : props.details.empty_return
      );
      setWithInspectionCheckbox(
        withInspectionCheckbox
          ? withInspectionCheckbox
          : props.details.with_inspection
      );
      setPrintPriceInBoneCheckbox(
        printPriceInBoneCheckbox
          ? printPriceInBoneCheckbox
          : props.details.show_price
      );
      setBase_price(base_price ? base_price : props.details.base_price);
      setDischarge_delay(
        discharge_delay ? discharge_delay : props.details.discharge_delay
      );
      setInspection_delay(
        inspection_delay ? inspection_delay : props.details.inspection_delay
      );
      setStatistics(props.details ? props.details.statistics : null);
      setdriver_info(props.details ? props.details.driver_info : null);
      setDetails(props.details ? props.details : null);


      if (props.details.price_message) {
        setPriceMessage(props.details.price_message)
      }

    }
  }, [props]);

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
    ...(fail && {
      bgcolor: red[500],
      "&:hover": {
        bgcolor: red[700],
      },
    }),
  };

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      let params = {
        method: "createCargoForCa",
        id: props.details.id,
        tender_id: 21,
        discharge_delay: discharge_delay,
        base_price: base_price,
        inspection_delay: inspection_delay,
        // notes:  ,
        location_id: locationId, // location id
        location_sub_id: subLocationObj.id, // locatino sub_id
        location_free_text: locationFreeText, // location free text
        show_price: printPriceInBoneCheckbox,
        price_message: price_message,
        empty_return: withReturnCheckbox,
        with_inspection: withInspectionCheckbox,
        // -----------------------------------------------------------------------------------------------------
        // -----------------------------------------------------------------------------------------------------
        // -----------------------------------------------------------------------------------------------------
        driver_mobile_in_discharge_location,
        discharge_time_details,
        export_containers_details,
        late_fine_every_day,

        // -----------------------------------------------------------------------------------------------------
        // -----------------------------------------------------------------------------------------------------
        // -----------------------------------------------------------------------------------------------------

      };

      axios
        .get("/cargo", { params: params })
        .then((res) => {
          setRequired(false);
          setFail(false);
          setSuccess(true);
          setConfirmBtnText(
            __translate("public.confirmation_modal.request_confirmed", {
              cid: details.cid,
            })
          );
          setDetails({ ...details, flag_available: false });
          setLoading(false);
          setShowStatisticsAndDriver(false);
          context.handelGlobalAleart(true);
          setTimeout(() => {
            navigate("/my_cargos");
          }, 1000);
        })
        .catch((error) => {
          let errorMsg = "";
          let errorBindParam = [];

          try {
            let errorObj = JSON.parse(error.response.data.MESSAGE);
            errorMsg = errorObj.message;
            errorBindParam = errorObj.params;
          } catch (err) {
            errorMsg = error.response.data.MESSAGE;
            errorBindParam = [];
          }
          setFail(true);
          setCreate_wo_error_msg(__translate(errorMsg, errorBindParam));
          setSuccess(false);
          setLoading(false);
          setConfirmBtnText(
            __translate("public.confirmation_modal.confirmation_failed")
          );
        });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    if (
      locationObj.id &&
      subLocationObj.id
    ) {
      setRequired(false);
      return;
    } else {
      setRequired(true);
    }
  };

  const handleClose = () => {
    setCreate_wo_error_msg(null);
    setRequired(false);
    setOpen(false);
    setFail(false);
    setSuccess(false);
    setLoading(false);
    setConfirmBtnText(
      __translate("public.confirmation_modal.request_confirmation")
    );
  };

  const fillLocations = () => {
    // Mo7afazat
    axios
      .get("/location", {
        params: {
          method: "getContainerLocation",
          filter: '{"parent_id":91000000}',
        },
      })
      .then((res) => {
        let arr = [];
        res.data.data.map((ele) => {
          arr.push({
            name: __translate("wo.location." + ele.id),
            id: ele.id,
          });
        });
        setLocations(arr);
      })
      .catch((error) => {
        Alert.error(error);
      });
  };

  const handelLocationClick = (location_id, location_name) => {
    setLocationId(location_id);
    setLocationObj({
      id: location_id,
      name: __translate("wo.location." + location_id),
    });
    fillSubLocation(location_id);


  };

  function getPriceDetails(location_id, location_sub_id) {

    axios
      .get("/waybill_order", {
        params: {
          method: "getPriceDetails",
          location_id: `${location_id}`,
          location_sub_id: `${location_sub_id}`,
          land_trip_id: `${props.details.id}`,
        },
      })
      .then((res) => {
        console.log(res.data);

        // hide price fields
        if (!res.data.flag) {
          setPriceMessage(res.data.value);
        }

        // show minimum price
        else {
          setBase_price(res.data.value);
        }


      })
      .catch((error) => {
        // navigate("/my_cargos")
        // Alert.error(error);
      });

  }


  const fillSubLocation = (location_id) => {
    axios
      .get("/location", {
        params: {
          method: "getContainerSubLocation",
          parent_id: `${location_id}`,
        },
      })
      .then((res) => {
        let arr = [];
        res.data.data.map((ele) => {
          arr.push({
            name: __translate("wo.sub_location." + ele.id),
            id: ele.id,
          });
        });
        setSubLocations(arr);
        setSubLocationObj({
          id: res.data.data[0].id,
          name: __translate("wo.sub_location." + res.data.data[0].id),
        });

        getPriceDetails(location_id, res.data.data[0].id);

      })
      .catch((error) => {
        // navigate("/my_cargos")
        // Alert.error(error);
      });
  };

  return (
    <>
      <BreadCrumbs
        crumpsDetails={[
          {
            href: "/",
            titel: __translate("public.bread_crumbs.home"),
          },
          {
            titel: __translate("public.bread_crumbs.my_cargos"),
            href: "/my_cargos",
          },
          {
            titel: __translate("public.bread_crumbs.cargos_details"),
            color: "black",
          },
        ]}
      />
      {locations && details && !loading ? (
        <>
          <div class="clearingAgentDetails mt-1">
            <div class="d-flex clearingAgentDetails__contiener">
              <div class="col  p-4 border-left border-bottom ">
                <div class="col  multipelDetailedCard">
                  <div className="multipelDetailedCard__title fontMedium">
                    {" "}
                    {__translate("my_cargos.land_trip_details.titel")}
                  </div>
                  <div className="multipelDetailedCard__body fontLarge flex-column">
                    <div className="clearingAgentDetails__detailsCategory__text">{`${__translate(
                      "my_cargos.land_trip_details.cid"
                    )}: ${details.cid ? details.cid : ""}`}</div>
                    <div className="clearingAgentDetails__detailsCategory__text">
                      {" "}
                      {`${__translate(
                        "my_cargos.land_trip_details.declaration"
                      )}  : ${details.declaration_number
                        ? details.declaration_number
                        : ""
                        }`}
                    </div>
                    <div className="clearingAgentDetails__detailsCategory__text">{`${__translate(
                      "my_cargos.land_trip_details.bol"
                    )}  : ${details.bl ? details.bl : ""}`}</div>
                    <div className="clearingAgentDetails__detailsCategory__text">{`${__translate(
                      "my_cargos.land_trip_card.create_date"
                    )}  : ${details.create_date
                      ? details.create_date.split(" ")[0]
                      : "--"
                      }`}</div>

                    {/* {details.flag_available ? ( */}
                    <FormGroup>
                      <FormControlLabel
                        sx={{ margin: "0" }}
                        onChange={(e) => {
                          setWithReturnCheckbox(e.target.checked);
                        }}
                        checked={withReturnCheckbox}
                        disabled={!details.flag_available}
                        className={classes.mui_label}
                        control={<Checkbox />}
                        label={__translate(
                          "my_cargos.land_trip_details.empty_return"
                        )}
                      />
                      <FormControlLabel
                        sx={{ margin: "0" }}
                        onChange={(e) => {
                          setWithInspectionCheckbox(e.target.checked);
                        }}
                        checked={withInspectionCheckbox}
                        disabled={!details.flag_available}
                        className={classes.mui_label}
                        control={<Checkbox />}
                        label={__translate(
                          "my_cargos.land_trip_details.with_inspection"
                        )}
                      />
                      <FormControlLabel
                        sx={{ margin: "0" }}
                        onChange={(e) => {
                          setPrintPriceInBoneCheckbox(e.target.checked);
                        }}
                        checked={printPriceInBoneCheckbox}
                        disabled={!details.flag_available}
                        className={classes.mui_label}
                        control={<Checkbox />}
                        label={__translate(
                          "my_cargos.land_trip_details.print_price"
                        )}
                      />
                    </FormGroup>
                    {/* // ) : (
                    //   <div className="mt-3">
                    //     <Tags tagItems={props.details.tags}></Tags>
                    //   </div>
                    // )} */}
                  </div>
                </div>
              </div>
              <div class="col p-4 border-left border-bottom">
                <div class="col  multipelDetailedCard">
                  <div className="multipelDetailedCard__title fontMedium">
                    {__translate("my_cargos.land_trip_discharge.title")}
                  </div>
                  <div className="multipelDetailedCard__body fontLarge flex-column">
                    {/* الموقع الرئيسي */}
                    <FormControl
                      sx={{ margin: "2%", width: "95%" }}
                      size="small"
                    >
                      {/* <InputLabel sx={{ textAlign: "right" }} id="demo-select-small">المحافظة</InputLabel> */}

                      <Autocomplete
                        size="small"
                        id="country-select-demo"
                        InputLabelProps={{
                          style: {
                            width: "125%",
                            textAlign: "center",
                          },
                        }}
                        options={locations}
                        autoHighlight
                        disabled={!details.flag_available}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {/* <img
                                                                loading="lazy"
                                                                width="20"
                                                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                alt=""
                                                            /> */}
                            {option.name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            // error={
                            //   selectedCities && !details.flag_available
                            //     ? false
                            //     : true
                            // }
                            {...params}
                            label={__translate(
                              "my_cargos.land_trip_discharge.discharge_location"
                            )}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                              shrink: true,
                            }}
                            className={classes.mui_label}
                          />
                        )}
                        onChange={(event, value) =>
                          handelLocationClick(value.id, value.name)
                        }
                        // defaultValue={locationId}
                        value={locationObj}
                      />
                    </FormControl>

                    {/* الموقع الفرعي */}
                    <FormControl
                      sx={{ margin: "2%", width: "95%" }}
                      size="small"
                    >
                      <Autocomplete
                        size="small"
                        id="country-select-demo"
                        options={subLocations}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            // error={
                            //   selectedLoction && !details.flag_available
                            //     ? false
                            //     : true
                            // }
                            {...params}
                            label={__translate(
                              "my_cargos.land_trip_discharge.discharge_sub_location"
                            )}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                            className={classes.mui_label}
                          />
                        )}
                        onChange={(event, value) => setSubLocationObj(value)}
                        value={subLocationObj}
                        disabled={!details.flag_available}
                      />
                    </FormControl>


















                    {/* --------------------------------------------------------------------------------- */}
                    {/* --------------------------------------------------------------------------------- */}
                    {/* --------------------------------------------------------------------------------- */}

                    <TextField
                      disabled={!details.flag_available}
                      // InputLabelProps={{
                      //   style: {
                      //     width: "93%",
                      //   },
                      // }}
                      label={__translate(
                        "my_cargos.land_trip_discharge.person_phone"
                      )}
                      id="outlined-size-small"
                      sx={{ margin: "2%", width: "95%" }}
                      size="small"
                      onChange={(event) => {
                        setDriverMobileInDischargeLocation(event.target.value);
                      }}
                      value={driver_mobile_in_discharge_location}
                      className={classes.mui_label}
                    />
                    <TextField
                      disabled={!details.flag_available}
                      // InputLabelProps={{
                      //   style: {
                      //     width: "93%",
                      //   },
                      // }}
                      label={__translate(
                        "my_cargos.land_trip_discharge.dischargeTimeDetails"
                      )}
                      id="outlined-size-small"
                      sx={{ margin: "2%", width: "95%" }}
                      size="small"
                      onChange={(event) => {
                        setDischargeTimeDetails(event.target.value);
                      }}
                      value={discharge_time_details}
                      className={classes.mui_label}
                    />
                    <TextField
                      disabled={!details.flag_available}
                      // InputLabelProps={{
                      //   style: {
                      //     width: "93%",
                      //   },
                      // }}
                      label={__translate(
                        "my_cargos.land_trip_discharge.exportContainerDetails"
                      )}
                      id="outlined-size-small"
                      sx={{ margin: "2%", width: "95%" }}
                      size="small"
                      onChange={(event) => {
                        setExportContainersDetails(event.target.value);
                      }}
                      value={export_containers_details}
                      className={classes.mui_label}
                    />

                    <TextField
                      disabled={!details.flag_available}
                      // InputLabelProps={{
                      //   style: {
                      //     width: "93%",
                      //   },
                      // }}
                      label={__translate(
                        "my_cargos.land_trip_discharge.discharge_notes"
                      )}
                      id="outlined-size-small"
                      sx={{ margin: "2%", width: "95%" }}
                      size="small"
                      onChange={(event) => {
                        setLocationFreeText(event.target.value);
                      }}
                      value={locationFreeText}
                      className={classes.mui_label}
                    />


                    {/* --------------------------------------------------------------------------------- */}
                    {/* --------------------------------------------------------------------------------- */}
                    {/* --------------------------------------------------------------------------------- */}
                  </div>
                </div>
              </div>
              <div class="col p-4  border-bottom">
                <div class="col  multipelDetailedCard">
                  <div className="multipelDetailedCard__title fontMedium">
                    {__translate("my_cargos.land_trip_price.title")}
                  </div>


                  <div className="multipelDetailedCard__body fontLarge flex-column" >

                    <div className="clearingAgentDetails__detailsCategory__text">{price_message}</div>

                    {!price_message &&
                      <div id="price_div">
                        <TextField
                          disabled={!details.flag_available}
                          InputLabelProps={{
                            style: {
                              width: "125%",
                            },
                          }}
                          // error={base_price ? false : true}
                          label={__translate("my_cargos.land_trip_price.basic")}
                          id="price_txt"
                          sx={{ margin: "2%", width: "95%" }}
                          size="small"
                          onChange={(event) => {
                            setBase_price(event.target.value);
                          }}
                          value={base_price}
                        />
                        <TextField
                          // error={
                          //   inspection_delay && !details.flag_available
                          //     ? false
                          //     : true
                          // }
                          disabled={!details.flag_available}
                          InputLabelProps={{
                            style: {
                              width: "125%",
                            },
                          }}
                          label={__translate(
                            "my_cargos.land_trip_price.inspection"
                          )}
                          id="inspection_txt"
                          sx={{ margin: "2% ", width: "95%" }}
                          size="small"
                          onChange={(event) => {
                            setInspection_delay(event.target.value);
                          }}
                          value={inspection_delay}
                        />
                        <TextField
                          disabled={!details.flag_available}
                          InputLabelProps={{
                            style: {
                              width: "125%",
                            },
                          }}
                          // error={
                          //   discharge_delay && !details.flag_available
                          //     ? false
                          //     : true
                          // }
                          label={__translate(
                            "my_cargos.land_trip_price.discharge"
                          )}
                          id="discharge_txt"
                          sx={{ margin: "2% ", width: "95%" }}
                          size="small"
                          onChange={(event) => {
                            setDischarge_delay(event.target.value);
                          }}
                          value={discharge_delay}
                        />












                        {/* -------------------------------------------------------------------------------- */}
                        {/* -------------------------------------------------------------------------------- */}
                        {/* -------------------------------------------------------------------------------- */}

                        <TextField
                          disabled={!details.flag_available}
                          InputLabelProps={{
                            style: {
                              width: "125%",
                            },
                          }}
                          // error={
                          //   discharge_delay && !details.flag_available
                          //     ? false
                          //     : true
                          // }
                          label={__translate(
                            "my_cargos.land_trip_price.lateFineEveryDay"
                          )}
                          id="lateFineEveryDay_txt"
                          sx={{ margin: "2% ", width: "95%" }}
                          size="small"
                          onChange={(event) => {
                            if (isNaN(Number(event.target.value))) {
                              setLateFineEvryDay(late_fine_every_day)
                            }
                            else {
                              setLateFineEvryDay(event.target.value)
                            }
                          }}
                          value={late_fine_every_day}
                        />
                        {/* -------------------------------------------------------------------------------- */}
                        {/* -------------------------------------------------------------------------------- */}
                        {/* -------------------------------------------------------------------------------- */}










                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center  ">
              {details.flag_available ? (
                <Button
                  className="primary__btn my-4"
                  variant="contained"
                  onClick={handleClickOpen}
                >
                  {__translate("my_cargos.land_trip_details.confirm_requerst")}
                </Button>
              ) : showStatisticsAndDriver ? (
                <div class="d-flex">
                  <div id="statistics" class="col-8 p-4 border-left ">
                    <div class="col  multipelDetailedCard">
                      <div className="multipelDetailedCard__title fontMedium">
                        {__translate("my_cargos.land_trip_stats.title")}
                      </div>
                      <div
                        id="statistics"
                        className="multipelDetailedCardChartAndBody__body fontLarge d-flex flex-column justify-content-center"
                      >
                        {props.details.stage || props.details.stage == 0 ? (
                          <Instractions
                            steps={[
                              __translate("public.instractions.accept_cargo"),
                              __translate(
                                "public.instractions.receive_declaration"
                              ),
                            ]}
                            icons={{
                              1: <i class="fa-solid fa-check-to-slot"></i>,
                              2: <i class="fa-solid fa-file-invoice"></i>,
                            }}
                            // stage={props.details.stage}
                            stage={0}
                          />
                        ) : (
                          <ChartBody
                            chartType="ColumnChart"
                            data={statistics}
                            options={{
                              isStacked: true,
                              height: "96%",
                              legend: { position: "top", maxLines: 3 },
                              vAxis: { minValue: 0 },
                              colors: ["#db4437"],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  <div class="col-4 p-4 ">
                    <div class="col  multipelDetailedCard">
                      <div className="multipelDetailedCard__title fontMedium">
                        {__translate("my_cargos.land_trip_reserve.title")}
                      </div>

                      <div className="multipelDetailedCardChartAndBody__body fontLarge flex-column">
                        {driver_info ? (
                          <>
                            <div class=" col-12 pt-3 fontMedium">
                              {`${__translate(
                                "my_cargos.land_trip_driver.name"
                              )}  : ${driver_info.name
                                ? driver_info.name
                                : __translate(
                                  "my_cargos.land_trip_driver.not_availabel"
                                )
                                }`}
                            </div>
                            <div class=" col-12 pt-3 fontMedium">
                              {`${__translate(
                                "my_cargos.land_trip_driver.phone"
                              )}  : ${driver_info.phone
                                ? driver_info.phone
                                : __translate(
                                  "my_cargos.land_trip_driver.not_availabel"
                                )
                                }`}
                            </div>
                            <div class=" col-12 pt-3 fontMedium">
                              {`${__translate(
                                "my_cargos.land_trip_driver.reserve_date"
                              )}  : ${driver_info.reserve_date
                                ? driver_info.reserve_date
                                : __translate(
                                  "my_cargos.land_trip_driver.not_availabel"
                                )
                                }`}
                            </div>
                          </>
                        ) : (
                          <div class="  col-12  text-center pt-3">
                            {__translate(
                              "my_cargos.land_trip_driver.not_found"
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </>
      ) : (
        <WebSckeleton />
      )}
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {!required ? (
          <React.Fragment>
            <DialogTitle
              sx={
                localStorage.getItem("dir") === "rtl"
                  ? { textAlign: "right" }
                  : { textAlign: "left" }
              }
            >
              {create_wo_error_msg ? null : (
                <React.Fragment>
                  {__translate(
                    "public.confirmation_modal.waybill_order",
                    details ? { cid: details.cid } : ""
                  )}
                </React.Fragment>
              )}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ m: 1, position: "relative" }}>
                    {create_wo_error_msg ? null : (
                      <Button
                        variant="contained"
                        sx={{
                          ...buttonSx,
                          width: "20vh",
                          height: "5vh",
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                        disabled={loading}
                        onClick={handleButtonClick}
                      >
                        {success ? (
                          <i
                            class="fa-solid fa-check-double"
                            style={{
                              fontSize: "1rem",
                            }}
                          ></i>
                        ) : fail ? (
                          <i
                            class="fa-solid fa-xmark"
                            style={{
                              fontSize: "1rem",
                            }}
                          ></i>
                        ) : (
                          <i
                            class="fa-solid fa-check"
                            style={{
                              fontSize: "1rem",
                            }}
                          ></i>
                        )}
                        {confirmBtnText}
                      </Button>
                    )}
                    {loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: green[500],
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                    {create_wo_error_msg && (
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="error">{create_wo_error_msg}</Alert>
                      </Stack>
                    )}
                  </Box>
                </Box>
              </DialogContentText>

              <Divider />
              <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#dc3545" }}
                  disabled={loading}
                  onClick={handleClose}
                >
                  {__translate("public.confirmation_modal.close")}
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <DialogTitle>
              {__translate("public.confirmation_modal.required_info")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></Box>
              </DialogContentText>

              <Divider />
              <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#dc3545" }}
                  disabled={loading}
                  onClick={handleClose}
                >
                  {__translate("public.confirmation_modal.close")}
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
    </>
  );
}
