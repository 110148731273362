const backEndConfigration = {
  backEndURL: "https://api.minagate.com/", // LIVE
  // backEndURL: "http://localhost/waybill-api/", // Hamzah  ,
  // backEndURL: "http://192.168.1.51/waybill-api/", // Hassan  ,
  // backEndURL: "http://192.168.1.38/waybill-api/", // Slhab  ,
  // backEndURL: "http://localhost/waybill-api/", // local  , 

  // fronEndURL: "http://localhost:3000",
  fronEndURL: "https://rafeef.minagate.com",

  cloudFunctionURL: "https://us-central1-waybill-project.cloudfunctions.net",
}

export const config = backEndConfigration;