import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
export default function ChartBody(props) {
  const [data, setData] = useState([]);
  const [chartType, setChartType] = useState(null);
  const [options, setOptions] = useState(null);

  useEffect(() => {
    setData(props.data);
    setChartType(props.chartType);
    setOptions(props.options);
  }, [props]);
  return (
    <Chart
      chartType={chartType}
      data={data}
      width="100%"
      options={options}
      legendToggle
    />
  );
}
