import React, { useState, useEffect, useContext } from "react";
import Tags from "../../../../components/tags/tags";
import { useNavigate } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import WebSckeleton from "../../../../components/sckeltonLoadingIndecator/webSckeleton";
import { t as __translate } from "i18next";
import { Button, Divider } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Form from "../../../../components/form/form";

function ProductCard(props) {
  let arr = [1, 2, 3, 4, 5, 6];
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [first, setFirst] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {props.isLoading &&
        arr.map((ele) => {
          return <WebSckeleton />;
        })}
      {localStorage.getItem("userRole").includes("CA_MANAGER") && (
        <div className="addCargo__contiener">
          <div>{__translate("wo.having_cargo")}</div>
          <div>
            <button
              className="btn secondary__btn"
              onClick={() => {
                setOpen(true);
              }}
            >
              {__translate("wo.add_now")}
            </button>
          </div>
        </div>
      )}
      {props.data.length > 0
        ? props.data.map((ele) => (
            <LazyLoad>
              <div className="d-flex justify-content-center row mb-3 ">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="row  card__container">
                    <div
                      className={
                        " col-xs-2 col-sm-2 col-md-2 col-lg-2 card__detailsContainer"
                      }
                    >
                      <div
                        className=" card__button"
                        type="button"
                        onClick={() => {
                          ele.flag_available
                            ? navigate(
                                `/my_cargos/details/${ele.id}/${ele.flag_available}`
                              )
                            : navigate(`/my_cargos/details/${ele.id}`);
                        }}
                      >
                        {ele.flag_available ? (
                          <React.Fragment>
                            {__translate(
                              "my_cargos.land_trip_card.view_make_available"
                            )}
                            <div>
                              <i class="fa-solid fa-truck-moving largeIcon parentOrderTruck__icon">
                                <i class="fa-solid fa-circle-plus xsmallIcon childOrderTruck__icon"></i>
                              </i>
                            </div>
                            {/* <i className="fa-solid fa-truck-medical mediumIcon mediumIcon--opacity"></i> */}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {__translate("my_cargos.land_trip_card.view_more")}

                            <i className="fa-solid fa-eye mediumIcon mediumIcon--opacity"></i>
                          </React.Fragment>
                        )}
                      </div>
                    </div>

                    <Divider
                      orientation="vertical"
                      sx={{ margin: "1rem 0", borderRightWidth: "medium" }}
                      flexItem
                    />

                    <div className="col-md-10 row p-0 m-0">
                      {ele.flag_available ? (
                        <div className="col-md-2 cardType cardType__landTrip">
                          {__translate("my_cargos.land_trip_card.container")}
                        </div>
                      ) : (
                        <div className="col-md-2 cardType cardType__waybillOrder">
                          {__translate(
                            "my_cargos.land_trip_card.waybill_order"
                          )}{" "}
                        </div>
                      )}
                      <div className="col-md-10 p-2">
                        <div className="d-flex justify-content-between">
                          {ele.cid && (
                            <h4 className="clearingAgentCard__titel m-0">
                              {ele.cid}
                            </h4>
                          )}
                          <span></span>
                          <span>
                            {__translate(
                              "my_cargos.waybill_order.status." + ele.status
                            )}
                          </span>
                        </div>

                        {ele.tags && (
                          <div className="spec-1 d-flex">
                            <Tags tagItems={ele.tags}></Tags>
                          </div>
                        )}
                        {/* <div className="col-md-12  col-lg-12  col-sm-12  col-xs-12 clearingAgentCard__subTitle">
                          <h6>
                            {" "}
                            {__translate(
                              "my_cargos.land_trip_card.create_date"
                            )}
                            :{" "}
                            {ele.create_date
                              ? ele.create_date.split(" ")[0]
                              : "--"}
                          </h6>
                        </div> */}
                        {/* {ele.bl ||
                          (ele.declaration_number && (
                            <div className="col-md-12  col-lg-12  col-sm-12  col-xs-12 clearingAgentCard__subTitle">
                              {ele.bl && (
                                <h6>
                                  {" "}
                                  {__translate(
                                    "my_cargos.land_trip_card.bol"
                                  )}: {ele.bl}
                                </h6>
                              )}
                              {ele.declaration_number && (
                                <h6>
                                  {" "}
                                  {__translate(
                                    "my_cargos.land_trip_card.declaration"
                                  )}
                                  : {ele.declaration_number}
                                </h6>
                              )}
                            </div>
                          ))} */}

                        <div className="clearingAgentCard__details">
                          {ele.description ? (
                            <span>
                              {ele.description.substring(0, 200)}
                              <span>...</span>
                              <span
                                className="card__showMore"
                                onClick={() => {
                                  ele.flag_available
                                    ? navigate(
                                        `/my_cargos/details/${ele.id}/${ele.flag_available}`
                                      )
                                    : navigate(`/my_cargos/details/${ele.id}`);
                                }}
                              >
                                <i className="fa-solid fa-arrow-left smallIcon showMore__smallIcon--arrow ">
                                  {" "}
                                </i>
                                <span className="ml-2">
                                  {__translate(
                                    "my_cargos.land_trip_card.view_more"
                                  )}
                                </span>
                              </span>
                            </span>
                          ) : (
                            <span>{__translate("wo.card.noDetails")}</span>
                          )}
                        </div>
                        <div className="d-flex">
                          <div className="standerdTag d-flex flex-column">
                            {/* <CalendarMonthIcon sx={{ fontSize: "1.3rem" }} />{" "} */}
                            {ele.create_date.split(" ")[0]}
                          </div>
                          {ele.bl && (
                            <div className="standerdTag d-flex flex-column mx-3">
                              {/* <span>
                                {__translate("my_cargos.land_trip_card.bol")}
                              </span> */}
                              <span>{ele.bl}</span>
                            </div>
                          )}
                          {ele.declaration_number && (
                            <div className="standerdTag d-flex flex-column mx-3">
                              {/* <span>
                                {" "}
                                {__translate(
                                  "my_cargos.land_trip_card.declaration"
                                )}
                              </span> */}
                              <span>{ele.declaration_number}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </LazyLoad>
          ))
        : props.data.length == 0 && (
            <div className="noCargosFound__contiener">
              <div className="d-flex justify-content-center  flex-column col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                  {__translate("my_cargos.land_trip_my_cargos.no_cargo_found")}
                </div>
                <br />
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                  <Button
                    className="primary__btn"
                    variant="contained"
                    // endIcon={<FilterAltIcon />}
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    {__translate("my_cargos.land_trip_my_cargos.press_here")}
                  </Button>
                </div>
              </div>
            </div>
          )}
      <Dialog
        sx={{
          "& .MuiDialog-paperWidthSm": {
            maxWidth: "40%",
            width: "20%",
            minWidth: "25%",
            height: "65%",
            minHeight: "50%",
          },
        }}
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <React.Fragment>
          <DialogTitle
            sx={{
              textAlign:
                localStorage.getItem("dir") === "rtl" ? "right" : "left",
            }}
          >
            {__translate("my_cargos.create_manual_lt.title")}
          </DialogTitle>
          <DialogContent>
            <Form setOpen={setOpen} />
            <Divider />
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
              <Button variant="contained" color="error" onClick={handleClose}>
                {__translate("public.confirmation_modal.close")}
              </Button>
            </DialogActions>
          </DialogContent>
        </React.Fragment>
      </Dialog>
    </>
  );
}

export default ProductCard;
