import React, { useEffect, useState } from 'react'
import Button from "@mui/material/Button";
import { t as _translate } from "i18next";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DownloadPwa() {



  const [openSecondDialog, setOpenSecondDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);



  const handleCloseSecondDialog = () => {
    setOpenSecondDialog(false);
  };

  


  useEffect(()=>{
    // check if the device is android or not
    const isAndroid = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      return userAgent.indexOf("android") > -1;
    }


    // check if app is already installed as pwa
    const isPwa = () => {
  return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
}

    // chec if the user using the pwa or web
    const isStandalone = () => {
      return window.navigator.standalone;
    }

    if(isAndroid() && !isPwa()&& !isStandalone()){
      setOpen(true)
    }
  },[])



  const handleClose = () => {
    setOpen(false);
  };



  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleInstallPrompt);
    return () => {
      window.removeEventListener('beforeinstallprompt', handleInstallPrompt);
    };
  }, []);
  const handleInstallPrompt = (event) => {
    // Prevent the default prompt from showing
    console.log(event, "event")
    event.preventDefault();
    // Store the event for later use
    setDeferredPrompt(event);
  };
  const handleInstallClick = () => {
    // If there's a deferred prompt, show it
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          setOpen(false)
        } else {
          setOpen(false)

        }
        // Reset the deferred prompt
        setDeferredPrompt(null);
      });
    } else {
      setOpen(false)
      setOpenSecondDialog(true)
    }
  };

  return (
    //     <div className='download_pwa_div' style={{ fontWeight: "900",}}>
    //         {_translate("downLoad_app_msg")}
    //         <Button
    //     sx={{ fontWeight: "900", fontSize: "90%" , borderRadius:"20px" , margin:"2% 5%" }}
    //     variant="contained"
    // onClick={handleInstallClick}

    // >
    //     {" "}
    //     {_translate("downLoad_app")}
    // </Button>
    // </div>

    <div>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>  {_translate("downLoad_app_msg")}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {_translate("downLoad_app_msg")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{ display: "flex", justifyContent: "space-evenly", width: "100%" }}>
            <Button onClick={handleClose} variant="contained" sx={{ backgroundColor: "#df3849", width: "40%" }}>     {_translate("dontDownLoad_app")}</Button>
            <Button onClick={handleInstallClick} variant="contained" sx={{ width: "40%" }} >     {_translate("downLoad_app")}</Button>

          </div>
        </DialogActions>
      </Dialog>



      <Dialog
        open={openSecondDialog}
        onClose={handleCloseSecondDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {_translate("error_in_dawnload_pwa_message")}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <div >

            <Button variant="contained" sx={{ backgroundColor: "#df3849" }} onClick={handleCloseSecondDialog} autoFocus>
              {_translate("dontDownLoad_app")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>

    </div>
  )
}