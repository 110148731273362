import React, { useContext, useEffect, useState } from "react";
import Tags from "../../../../components/tags/tags";
import { useNavigate } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import NoResultImg from "./no-result-data.png";
import WebSckeleton from "../../../../components/sckeltonLoadingIndecator/webSckeleton";
import { UserContext } from "../../../../useContext";
import { t as __translate } from "i18next";
import { Divider } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
function ProductCard(props) {
  const context = useContext(UserContext);
  let arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  useEffect(() => {}, [props.data]);
  const navigate = useNavigate();

  return (
    <div>
      {localStorage.getItem("isAuthenticated") === "false" && (
        <div className="addCargo__contiener">
          <div>{__translate("wo.having_cargo")}</div>
          <div>
            <button
              className="btn secondary__btn"
              onClick={() => {
                navigate("/my_cargos");
              }}
            >
              {__translate("wo.add_now")}
            </button>
          </div>
        </div>
      )}
      {!props.isLoading ? (
        props.data.length > 0 ? (
          props.data.map((ele) => (
            <LazyLoad>
              <div className="d-flex justify-content-center row mb-3 ">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="row  card__container">
                    <div
                      className={
                        " col-xs-2 col-sm-2 col-md-2 col-lg-2 card__detailsContainer"
                      }
                    >
                      <div
                        className="  card__button"
                        type="button"
                        onClick={() => {
                          navigate(`/details/${ele.id}`);
                          // getContienerDetails(ele.id);
                        }}
                      >
                        {__translate("wo.list.view_details")}

                        <i className="fa-solid fa-eye mediumIcon mediumIcon--opacity"></i>
                      </div>
                    </div>
                    <Divider
                      orientation="vertical"
                      sx={{ margin: "1rem 0", borderRightWidth: "medium" }}
                      flexItem
                    />

                    <div className="col-md-10 mt-1">
                      <div className="spec-1 d-flex">
                        <Tags tagItems={ele.tags}></Tags>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="card__details d-flex flex-column">
                          {ele.details ? (
                            <span>
                              {ele.details.substring(0, 200)}
                              <span>...</span>
                              <span
                                className="card__showMore"
                                onClick={() => {
                                  navigate(`/details/${ele.id}`);
                                }}
                              >
                                <i className="fa-solid fa-arrow-left smallIcon showMore__smallIcon--arrow ">
                                  {" "}
                                </i>
                                <span className="ml-2">
                                  {__translate("wo.list.view_more")}
                                </span>
                              </span>
                            </span>
                          ) : (
                            <span>{__translate("wo.card.noDetails")}</span>
                          )}

                          <div
                            className="d-flex justify-content-between"
                            style={{ width: "40%" }}
                          >
                            <div>
                              <i className="fa-solid fa-location-dot"></i>{" "}
                              {__translate("wo.list.view_location")} :
                              {__translate(
                                "wo.location." + ele.location.location_id
                              )}{" "}
                              {/* {ele.sub_location ? "/" : null}
                              {__translate(
                                "wo.sub_location." +
                                  ele.sub_location.sub_location_id
                              )} */}
                            </div>
                            <div>
                              <CalendarMonthIcon sx={{ fontSize: "1.3rem" }} />{" "}
                              {ele.create_date.split(" ")[0]}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </LazyLoad>
          ))
        ) : (
          <div
            className="col-xs-12 col-sm-12 col-md-12 cpl-lg-12 d-flex justify-content-center  "
            style={{
              height: "200px",
              maxWidth: "25%",
              display: "flex",
              flexDirection: "column",
              margin: "auto",
              marginTop: "25%",
            }}
          >
            {Object.keys(context.advanceSearchResult).length ? (
              <React.Fragment>
                <img src={NoResultImg}></img>
                <div className=" my-3">
                  <p style={{ fontSize: "18px", textAlign: "center" }}>
                    {__translate("wo.search.result_found")}
                  </p>
                </div>
              </React.Fragment>
            ) : (
              <div className=" my-3">
                <p style={{ fontSize: "18px", textAlign: "center" }}>
                  {__translate("wo.no_cargo")}
                </p>
              </div>
            )}
          </div>
        )
      ) : (
        arr.map(() => {
          return <WebSckeleton />;
        })
      )}
    </div>
  );
}

export default ProductCard;
