import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.png";
import Login from "../../pages/login/login";
import { t } from "i18next";
import { UserContext } from "../../useContext";
import $ from "jquery";

function NavBar() {
  const navigate = useNavigate();
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const context = useContext(UserContext);
  const changeDir = (lang) => {
    context.changeLang(lang);
  };

  const closeModal = (flage) => {
    setOpenLoginModal(flage);
  };
  useEffect(() => {
    let navItem__unactive =
      document.getElementsByClassName("navItem__unactive");
    for (var i = 0; i < navItem__unactive.length; i++) {
      $(".navItem__unactive").remove();
    }
    if (window.location.href.includes("my_cargos")) {
      $("#home").append(` <span class="navItem__unactive"></span>`);
      document.getElementById("my_cargos").classList.add("navItem__active");
      document.getElementById("home").classList.remove("navItem__active");
    } else {
      document.getElementById("my_cargos").classList.remove("navItem__active");
      $("#my_cargos").append(` <span class="navItem__unactive"></span>`);
      document.getElementById("home").classList.add("navItem__active");
    }
  }, [context.render, window.location.href]);

  return (
    <div>
      <nav className="navbar navbar-expand-lg" style={{ zIndex: "1" }}>
        <img
          src={Logo}
          className="navbar-brand"
          width={"150px"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
        />

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-5 ml-auto">
            <li className="nav-item" id="home">
              <span
                className="nav-link"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/");
                }}
              >
                {t("public.bread_crumbs.home")}
              </span>
            </li>
            <li className="nav-item" id="my_cargos">
              <span
                className="nav-link"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/my_cargos");
                }}
              >
                {t("public.menu.my_cargo")}
              </span>
            </li>
          </ul>
          <div class="form-group mx-4 my-0">
            <select
              class="form-control p-1"
              onChange={(event) => {
                changeDir(event.target.value);
              }}
            >
              <option
                selected={localStorage.getItem("lang") === "ar" ? true : false}
                value={"ar"}
              >
                {t("public.menu.ar")}
                &nbsp;&nbsp; 🇯🇴
              </option>
              <option
                selected={localStorage.getItem("lang") === "en" ? true : false}
                value={"en"}
              >
                {t("public.menu.en")}
                &nbsp;&nbsp; 🇺🇸
              </option>
            </select>
          </div>
          {localStorage.getItem("isAuthenticated") === "true" ? (
            <span className=" dropdown" style={{}}>
              <div
                className="nav-link dropdown-toggle userProfileOptions"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="mx-2">
                  {/* <span className="mx-1">{t("public.menu.welcome")}</span> */}
                  {localStorage.getItem("userName") &&
                    localStorage.getItem("userName")}
                </span>
                <img
                  src={
                    localStorage.getItem("userPhoto") != "null"
                      ? localStorage.getItem("userPhoto")
                      : "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
                  }
                  style={{ objectFit: "cover" }}
                  width="40"
                  height="40"
                  className="rounded-circle"
                />
              </div>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
                style={{ textAlign: "center" }}
              >
                <span
                  className="dropdown-item"
                  onClick={() => {
                    navigate("/user_profile");
                  }}
                >
                  {t("public.menu.my_profile")}
                </span>
                <a className="dropdown-item" href="#">
                  {t("public.menu.settings")}
                </a>
                <a className="dropdown-item" href="#">
                  {t("public.menu.help")}
                </a>
                <div className="dropdown-divider"></div>
                <div className="d-flex justify-content-center">
                  <button className="btn btn-danger " onClick={()=>{context.signout()}}>
                    {" "}
                    {t("public.logout")}
                  </button>
                </div>
              </div>
            </span>
          ) : (
            <button
              className="btn primary__btn"
              onClick={() => {
                setOpenLoginModal(true);
              }}
            >
              {t("public.login")}
            </button>
          )}
        </div>
        {openLoginModal && <Login closeModal={closeModal} publicPage={true} />}
      </nav>
    </div>
  );
}

export default NavBar;
