import React, { useEffect, useState } from "react";
import WebDetails from "./web/WebDetails";
import MobileDetails from "./mobile/MobileDetails";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { onMessageListener } from "../../../config/firebase";
import { t as __translate } from "i18next";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Container(props) {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    onMessageListener()
      .then((payload) => {
        if (payload.data.type == "waybill_order_taken") {
          setShow(true); // use this for display the alert
          // window.location.reload();
        }
        // console.log("payload", payload);
      })
      .catch((err) => console.log("failed: ", err));
  }, [show]);
  return (
    <div className=" mt-3">
      {props.deviceType == "web" ? <WebDetails /> : <MobileDetails />}
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={show}
          autoHideDuration={20000}
          onClose={() => {
            setShow(false);
          }}
        >
          <Alert
            severity="warning"
            onClose={() => {
              setShow(false);
            }}
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            action={
              <Button
                variant="contained"
                sx={{ marginRight: "1rem " }}
                className="primary__btn"
                onClick={() => {
                  navigate("/");
                }}
              >
                {__translate("wo.details.back_home")}
              </Button>
            }
          >
            {__translate("wo.details.already_reserved")}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default Container;
