import React, { useContext, useEffect, useRef, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Tags from "../../../../components/tags/tags";
import Carousel from "react-material-ui-carousel";
import { UserContext } from "../../../../useContext";
import axios from "../../../../config/axios/axios";
import WebSckeleton from "../../../../components/sckeltonLoadingIndecator/webDetailsSckeleton";
import { t as __translate } from "i18next";
import BreadCrumbs from "../../../../components/breadCrumbs/breadCrumbs";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { green, red } from "@mui/material/colors";
import { Alert } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";
// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & {
//     children: React.ReactElement<any, any>;
//   },
//   ref: React.Ref<unknown>,
// ) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });
export default function DetailsCardWeb(props) {
  const params = useParams();
  const context = useContext(UserContext);
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [detailsIsLoading, setDetailsIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmBtnText, setConfirmBtnText] = useState(
    __translate("wo.details.confirmation_modal.reservation_confirmation")
  );
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [selectedTn, setSelectedTn] = useState(null);
  const [selectedTnNumber, setSelectedTnNumber] = useState(null);
  const [confirmationChbox, setConfirmationChbox] = useState(false);
  const [tcCompanies, setTcCompanies] = useState([]);
  const [selectedTc, setSelectedTc] = useState(null);
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
    ...(fail && {
      bgcolor: red[500],
      "&:hover": {
        bgcolor: red[700],
      },
    }),
  };

  useEffect(() => {
    searchTcCompanies();
    setDetailsIsLoading(true);
    if (context.checkLogIn()) {
      getWaybillDetails();
    }
  }, []);
  const searchTcCompanies = () => {
    axios
      .get("/tender_company", {
        params: {
          method: "searchTenderCompanyForRafeef",
        },
      })
      .then((response) => {
        let data = response.data.data.map((element) => {
          return {
            label: element.name,
            value: element.id,
          };
        });
        setTcCompanies(data);
      });
  };

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      axios
        .get("/waybill_order", {
          params: {
            method: "pendWaybillOrder",
            waybill_order_id: params.id,
            tc_id: selectedTc,
            queue_id: selectedTn,
          },
        })
        .then((response) => {
          setFail(false);
          setSuccess(true);
          setLoading(false);
          setConfirmBtnText(
            __translate("wo.details.confirmation_modal.reservation_confirmed")
          );
          setTimeout(() => {
            navigate("/my_cargos");
          }, 1000);
        })
        .catch((err) => {
          setFail(true);
          setSuccess(false);
          setLoading(false);
          setConfirmBtnText(
            __translate("wo.details.confirmation_modal.reservation_failed")
          );
          console.log("error", err);
        });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFail(false);
    setSuccess(false);
    setLoading(false);
    setConfirmBtnText(
      __translate("wo.details.confirmation_modal.reservation_confirmation")
    );
    setConfirmationChbox(false);
    setSelectedTc(null);
  };

  const getWaybillDetails = () => {
    axios
      .get("/waybill_order", {
        params: {
          method: "getContainerPlatformWaybillOrder",
          waybill_order_id: params.id,
        },
      })
      .then((response) => {
        setData(response.data);
        setDetailsIsLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const reserve = () => {
    axios
      .get("/waybill_order", {
        params: {
          method: "pendWaybillOrder",
          waybill_order_id: params.id,
        },
      })
      .then((response) => { })
      .catch((err) => {
        console.log("error", err);
      });
  };

  return (
    <React.Fragment>
      {context.renderLogin(
        __translate("public.login_message.watch_the_cargo"),
        getWaybillDetails
      )}

      <BreadCrumbs
        crumpsDetails={[
          {
            href: "/",
            titel: __translate("public.bread_crumbs.home"),
          },
          {
            titel: __translate("public.bread_crumbs.wo_details"),
            color: "black",
          },
        ]}
      />
      {data ? (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center details">
          <React.Fragment>
            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 details__cardContiener">

              {data.price_message ?
                <React.Fragment>
                  <div className="description__titel fontMedium">{__translate("my_cargos.land_trip_price.title")}</div>
                  <div className="fontLarge description__titel">{data.price_message}</div>
                </React.Fragment>
                :
                <React.Fragment>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12  cardContiener">
                    <div className="cardContiener__cartTitel fontMedium">
                      <div className="cardContiener__cartTitel--glossyEffect"></div>
                      {__translate("wo.details.basic_price")}
                    </div>
                    <div className="cardContiener__cardBody fontLarge">
                      <span>
                        {data.base_price}
                        {__translate("public.currency.jod")}
                      </span>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 cardContiener">
                    <div className="cardContiener__cartTitel fontMedium">
                      <div className="cardContiener__cartTitel--glossyEffect"></div>
                      {__translate("wo.details.inspection_price")}
                    </div>

                    <div className="cardContiener__cardBody fontLarge">
                      <span>
                        {data.inspection_delay} {__translate("public.currency.jod")}
                        / {__translate("wo.details.per_day")}
                      </span>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 cardContiener">
                    <div className="cardContiener__cartTitel fontMedium">
                      <div className="cardContiener__cartTitel--glossyEffect"></div>
                      {__translate("wo.details.discharge_price")}
                    </div>

                    <div className="cardContiener__cardBody fontLarge">
                      <span>
                        {data.discharge_delay} {__translate("public.currency.jod")}
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              }
            </div>

            <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 details__contiener">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 details__description">
                <div
                  className={
                    data && data.trucks && data.trucks.length > 0
                      ? "col-xs-7 col-sm-7 col-md-7 col-lg-7 p-0"
                      : "col-xs-9 col-sm-9 col-md-9 col-lg-9 p-0"
                  }
                  style={{ position: "relative" }}
                >
                  <div className="fontLarge description__titel">
                    {__translate("wo.list.view_details")}
                  </div>

                  <Divider />
                  <p className="details__description--paragraph">
                    {" "}
                    {__translate(data.description)}
                  </p>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex">
                    <Tags tagItems={data.tags} />
                    {/* <i class="fa-solid fa-location-dot mx-2"></i> */}
                    {/* <p className="">عمان / سحاب / {data.location_free_text} </p> */}
                  </div>
                  {data && data.trucks && data.trucks.length > 1 && (
                    <Alert
                      sx={{ position: "absolute", bottom: "5%", width: "85%" }}
                      severity="warning"
                    >
                      {__translate("wo.details.multi_truck.warning")}
                    </Alert>
                  )}
                </div>

                {/* <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 d-flex justify-content-center row cardContiener"> */}
                {/* <div className="cardContiener__isWatching fontMedium">
                    <div className="cardContiener__cartTitel--glossyEffect"></div>
                    <i class="fa-solid fa-eye mediumIcon"></i>
                    <hr className="isWatching__divider" />
                    <span className="fontMedium">
                      5 {__translate("wo.details.is_watching")}{" "}
                    </span>
                  </div> */}

                {/* <button type="button" class="btn btn-success">Success</button> */}

                {/* {data && data.trucks ? ( */}
                <div className="details__blate" style={{ width: "16%" }}>
                  <div className="fontMedium description__titel  description__titel--blade">
                    {__translate("wo.details.location")}
                    <i className="fa-solid fa-location-dot mx-2"></i>
                  </div>

                  <Divider sx={{ mb: "1rem" }} />
                  <div
                    className="fontMedium"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <div>{__translate("wo.location." + data.location_id)}</div>
                    <i className="fa-solid fa-arrow-down-long"></i>
                    <div>
                      {__translate("wo.sub_location." + data.location_sub_id)}
                    </div>
                  </div>
                </div>
                {data && data.trucks && data.trucks.length > 0 ? (
                  <div className="details__blate">
                    <div className="fontMedium description__titel  description__titel--blade">
                      {__translate("wo.details.truck_selection")}
                    </div>

                    <Divider sx={{ mb: "1rem" }} />
                    {data.trucks.map((ele, index) => {
                      return (
                        <div className=" d-flex justify-content-center mb-3 ">
                          <div
                            className="p-0 blate__contiener blate__contiener--active"
                            onClick={() => {
                              setSelectedTn(ele.q_id);
                              setSelectedTnNumber(ele.tn);
                              handleClickOpen();
                            }}
                          >
                            <div className="contry ">
                              {ele.q_id == selectedTn ? (
                                <i className="fa-solid fa-circle-check largeIcon"></i>
                              ) : (
                                <>
                                  <div className="contry__ar">الأردن</div>
                                  <div className="contry__en">jordan</div>
                                </>
                              )}
                            </div>
                            <div className="blate__number blateNumber">
                              {ele.tn_dashed}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="fontMedium description__titel  description__titel--blade">
                    {__translate("wo.details.no_trucks")}
                  </div>
                )}
                {/* ) : null} */}
                {/* <div
                    className="cardContiener__isWatching cardContiener__isWatching--success  fontMedium"
                    onClick={() => {
                      handleClickOpen();
                      // reserve();
                    }}
                  >
                    <div className="cardContiener__cartTitel--glossyEffect"></div>
                    <span className="fontMedium">
                      {__translate("wo.details.reserve")}
                      <i className="fa-regular fa-square-check  mx-2"></i>
                    </span>
                  </div> */}
                {/* </div> */}
              </div>
            </div>
          </React.Fragment>
        </div>
      ) : (
        detailsIsLoading && <WebSckeleton />
      )}
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {data && data.trucks && selectedTnNumber ? (
          <React.Fragment>
            <DialogTitle
              sx={
                localStorage.getItem("rtl")
                  ? { textAlign: "right" }
                  : { textAlign: "left" }
              }
            >
              {` ${__translate(
                "wo.details.confirmation_modal.reservation_confirmation_text"
              )}${selectedTnNumber} `}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <FormGroup>
                  <FormControlLabel
                    required
                    className="ReactSelect"
                    sx={
                      localStorage.getItem("rtl")
                        ? { textAlign: "right" }
                        : { textAlign: "left" }
                    }
                    control={<Checkbox />}
                    label={__translate(
                      "wo.details.confirmation_modal.reservation_confirmation_chbox"
                    )}
                    onChange={(event) => {
                      setConfirmationChbox(event.target.checked);
                    }}
                  />
                </FormGroup>

                <Select
                  options={tcCompanies}
                  value={selectedTc}
                  onChange={(event) => {
                    setSelectedTc(event.value);
                  }}
                  label={__translate("my_cargos.create_manual_lt.hazardous")}
                  size="small"
                  anchor={"right"}
                  placeholder={__translate(
                    "wo.details.confirmation_modal.tc_companies_placeholder"
                  )}
                  className="ReactSelect"
                />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ m: 1, position: "relative" }}>
                    <Button
                      variant="contained"
                      sx={{
                        ...buttonSx,
                        width: "20vh",
                        height: "5vh",
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                      disabled={loading || !confirmationChbox || !selectedTc}
                      onClick={handleButtonClick}
                    >
                      {success ? (
                        <i
                          class="fa-solid fa-check-double"
                          style={{
                            fontSize: "1rem",
                          }}
                        ></i>
                      ) : fail ? (
                        <i
                          class="fa-solid fa-xmark"
                          style={{
                            fontSize: "1rem",
                          }}
                        ></i>
                      ) : (
                        <i
                          class="fa-solid fa-check"
                          style={{
                            fontSize: "1rem",
                          }}
                        ></i>
                      )}
                      {confirmBtnText}
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: green[500],
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </DialogContentText>
              <Divider />
              <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  color="error"
                  disabled={loading}
                  onClick={handleClose}
                >
                  {__translate("wo.details.confirmation_modal.close")}
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <DialogTitle>
              {__translate("wo.details.truck_selection.warning")}
            </DialogTitle>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="error"
                disabled={loading}
                onClick={handleClose}
              >
                {__translate("wo.details.confirmation_modal.close")}
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      </Dialog>

      {/* <div
        className={` ${
          window.innerWidth < 1560 && window.innerWidth > 1420
            ? " col-md-12 col-sm-12"
            : window.innerWidth < 1420
            ? "col-md-10 col-sm-10"
            : "col-md-11 col-sm-11"
        } ml-auto mr-auto mt-5`}
        style={{
          backgroundColor: "#e8e8e4",
          borderRadius: "15px",
        }}
      >
        <div className="col-md-11 col-sm-11  mb-2 text-right h4 pt-3">
          حاويات مشابهة
        </div>
        <Carousel
          animation={"slide"}
          interval={4000}
          navButtonsAlwaysVisible={true}
          duration={200}
        >
          {data.map((item, i) => {
            return (
              <div className="col-md-12 col-sm-12 mr-auto ml-auto d-flex">
                <div className="MinaGenFont d-flex">
                  {item.map((ele, index) => (
                    <div
                      className={`d-flex justify-content-center row mb-3  ${
                        index == 2
                          ? ""
                          : window.innerWidth < 1700 && window.innerWidth > 1420
                          ? "ml-4"
                          : "ml-5"
                      }`}
                    >
                      <div className="col-md-12  ">
                        <div
                          className="row p-2 bg-white border rounded"
                          style={{
                            flexDirection: "row-reverse",
                            boxShadow: "3px 2px 5px 0px rgba(171,170,170,0.75)",
                            height: "100%",
                          }}
                        >
                          <div
                            className={`col-md-12 mt-1 ${
                              window.innerWidth < 1700 &&
                              window.innerWidth > 1420
                                ? "p-0"
                                : ""
                            }`}
                            style={{
                              display: "flex",
                              flexFlow: "column",
                              justifyContent: "start",
                              alignItems: "flex-start",
                            }}
                          >
                            <div
                              className="spec-1"
                              style={{
                                display: "flex",
                                maxHeight: "12vh",
                                overflow: "hidden",
                              }}
                            >
                              <Tags tagItems={ele.productFuturs}></Tags>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                textAlign: "right",
                                justifyContent: "start",
                                marginTop: "2rem",
                                height: "7vh",
                                maxWidth: "25rem",
                              }}
                            >
                              <span
                                style={{ height: "100%", overflow: "hidden" }}
                              >
                                {ele.productDiscription.substring(0, 200)}
                                <a href="#">... المزيد</a>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-12 mt-4 d-flex justify-content-center">
                            <button
                              className="btn btn-primary"
                              type="button"
                              style={{
                                fontSize: "2.5vh",
                                fontWeight: "700",
                                backgroundColor: "#337cca",
                              }}
                              onClick={() => {
                                navigate("/details");
                              }}
                            >
                              {__translate("wo.details.details")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </Carousel>
      </div> */}
    </React.Fragment>
  );
}
