import { useForm, Controller } from "react-hook-form";
import React, { useEffect, useState, useContext } from "react";
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import axios from "../../config/axios/axios.js";
import TextField from "@mui/material/TextField";
import { Alert, Snackbar, Stack } from "@mui/material";

import Button from "@mui/material/Button";
import { t as __translate } from "i18next";
import { muiCoustomStyles } from "../../assets/mui_style";
import classNames from "classnames";
import { Check, CheckBox, Label } from "@mui/icons-material";
import Select from "react-select";
import { UserContext } from "../../useContext.js";
export default function Form(props) {
  const { register, handleSubmit, control, reset } = useForm();
  const [snackBar, setSnackBar] = useState(false);
  const [shippingLines, setShippingLines] = useState([]);
  const context = useContext(UserContext);
  const classes = muiCoustomStyles();
  const onSubmit = (data) => {
    let params = {
      method: "createManualLt",
      ...data,
    };
    axios
      .get("/container", { params: params })
      .then((res) => {
        props.setOpen(false);
      })
      .finally(() => {
        context.setRefreshMyCargoList(true);
        setSnackBar(true);
        reset({
          cid: "",
          bl: "",
          iso: "",
          weight: "",
          shipping_line: 0,
        });
        props.setOpen(false);
      });
  };

  useEffect(() => {
    let sh_params = {
      method: "searchShippingLines",
    };
    axios.get("/container", { params: sh_params }).then((res) => {
      setShippingLines(res.data);
    });
  }, []);

  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <TextField
          name="cid"
          label={__translate("my_cargos.create_manual_lt.cid")}
          id="outlined-size-small"
          sx={{ margin: "2% ", width: "75%" }}
          size="small"
          anchor={"right"}
          className={classNames(classes.mui_label, classes.mui_fieldset)}
          {...register("cid", {
            required: true,
            pattern: /^[A-Z]{3,5}[0-9]{6,8}/,
            maxLength: 12,
          })}
        />

        <TextField
          name="bl"
          label={__translate("my_cargos.create_manual_lt.bl")}
          id="outlined-size-small"
          sx={{ margin: "2% ", width: "75%" }}
          size="small"
          anchor={"right"}
          className={classNames(classes.mui_label, classes.mui_fieldset)}
          {...register("bl", { required: true })}
        />

        <TextField
          name="iso"
          label={__translate("my_cargos.create_manual_lt.iso")}
          id="outlined-size-small"
          sx={{ margin: "2% ", width: "75%" }}
          size="small"
          anchor={"right"}
          className={classNames(classes.mui_label, classes.mui_fieldset)}
          {...register("iso", { required: true })}
        />

        <TextField
          // className="col-xs-9 col-sm-9 col-md-9 col-lg-9 p-0 ReactSelect"
          name="weight"
          label={__translate("my_cargos.create_manual_lt.weight")}
          id="outlined-size-small"
          sx={{ margin: "2% ", width: "75%" }}
          size="small"
          anchor={"right"}
          className={classNames(classes.mui_label, classes.mui_fieldset)}
          {...register("weight", { required: true, pattern: /^[0-9]{2,8}/ })}
        />

        <FormControlLabel
          control={<Checkbox {...register("hazardous")} />}
          sx={{ margin: "2% ", width: "75%" }}
          className={classNames(classes.mui_label, classes.mui_fieldset)}
          label={__translate("my_cargos.create_manual_lt.hazardous")}
          size="small"
          anchor={"right"}
        />
        <FormControlLabel
          control={<Checkbox {...register("special_equip")} />}
          sx={{ margin: "2% ", width: "75%" }}
          className={classNames(classes.mui_label, classes.mui_fieldset)}
          label={__translate("my_cargos.create_manual_lt.special_equip")}
          size="small"
          anchor={"right"}
        />

        <div
          className="d-flex flex-column"
          style={{ margin: "2%", width: "75%" }}
        >
          <Controller
            control={control}
            placeholder={__translate(
              "my_cargos.create_manual_lt.shipping_line"
            )}
            name="shipping_line"
            rules={{ required: true }}
            render={({ field: { onChange, value, ref } }) => (
              <Select
                options={shippingLines}
                value={shippingLines.find((c) => c.value === value)}
                onChange={(val) => onChange(val.value)}
                label={__translate("my_cargos.create_manual_lt.hazardous")}
                size="small"
                anchor={"right"}
              />
            )}
          />

          <Button
            className="primary__btn mt-4"
            variant="contained"
            type="submit"
          >
            {__translate("my_cargos.create_manual_lt.add")}
          </Button>
        </div>
      </form>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={snackBar}
          autoHideDuration={6000}
          onClose={() => {
            setSnackBar(false);
          }}
        >
          <Alert
            severity="success"
            onClose={() => {
              setSnackBar(false);
            }}
            sx={{ width: "100%" }}
          >
            {__translate("my_cargos.create_manual_lt.snackbar_success")}
          </Alert>
        </Snackbar>
      </Stack>
    </React.Fragment>
  );
}
