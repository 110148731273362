import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Slider from "@mui/material/Slider";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import "./style.css";
import { AppBar, Toolbar, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { t as __translate } from "i18next";
import { muiCoustomStyles } from "../../../../assets/mui_style";
import { useContext } from "react";
import { UserContext } from "../../../../useContext";
let counter = 1;
export default function TemporaryDrawer(props) {
  const context = useContext(UserContext);
  const classes = muiCoustomStyles();
  const [advanceSearchPreperation, setAdvanceSearchPreperation] = useState({});

  useEffect(() => {
    console.log(advanceSearchPreperation);
    setBottom({ bottom, [props.anchor]: props.drawerToggler });
    let obj = {};
    Object.keys(obj).forEach((ele) => {
      if (ele == "weight_min_point" || ele == "weight_max_point") {
        return;
      }
      obj[ele] = Object.keys(obj[ele]);
    });
    context.handelAdvanceSearchRequset(obj);
  }, [advanceSearchPreperation, props.drawerToggler]);
  const searchUserCargo = () => {
    props.handelSearchBtn(counter++);
    context.handelAdvanceSearchRequset(advanceSearchPreperation);
  };
  const handelChange = (event) => {
    setAdvanceSearchPreperation({
      ...advanceSearchPreperation,
      [event.target.name]: event.target.value,
    });
  };
  const [bottom, setBottom] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setBottom({ bottom, [anchor]: open });
    props.toggleDrawer(open);
  };

  const list = (anchor) => (
    <Box sx={{ width: "auto" }} role="presentation">
      <List>
        <ListItem>
          <ListItemIcon style={{ maxWidth: "100%" }}>
            <div className="advanceSearch">
              <fieldset className="advanceSearch__fieldset">
                <legend className="advanceSearch__legend">
                  {__translate("wo.advance_search.search")}
                </legend>
                <TextField
                  name="declaration_number"
                  label={__translate("my_cargos.advance_search.declaration")}
                  id="outlined-size-small"
                  sx={{ margin: "2% ", width: "95%" }}
                  className={(classes.mui_label, classes.mui_fieldset)}
                  size="small"
                  onChange={(event) => {
                    handelChange(event);
                  }}
                  value={advanceSearchPreperation.declaration_number}
                />
                <TextField
                  name="bl"
                  label={__translate("my_cargos.advance_search.bol")}
                  id="outlined-size-small"
                  sx={{ margin: "2% ", width: "95%" }}
                  size="small"
                  className={classes.mui_label}
                  onChange={(event) => {
                    handelChange(event);
                  }}
                  value={advanceSearchPreperation.bl}
                />
                <TextField
                  name="cid"
                  label={__translate("my_cargos.advance_search.cid")}
                  id="outlined-size-small"
                  sx={{ margin: "2% ", width: "95%" }}
                  size="small"
                  anchor={"right"}
                  className={classes.mui_label}
                  onChange={(event) => {
                    handelChange(event);
                  }}
                  value={advanceSearchPreperation.cid}
                />
              </fieldset>

              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 advanceSearch__searchBtn ">
                <button
                  className="btn primary__btn col-xs-12 col-sm-12 col-md-12 col-lg-12 advanceSearch__searchBtn--fontSize"
                  onClick={() => {
                    searchUserCargo();
                    toggleDrawer(anchor, false);
                  }}
                >
                  <span>{__translate("wo.advance_search.search")}</span>
                  <i className="fa-solid fa-filter mx-2"></i>
                </button>
              </div>
            </div>
          </ListItemIcon>
          <ListItemText />
        </ListItem>
      </List>
      <Divider />
    </Box>
  );

  return (
    <div>
      <div>
        {["bottom"].map((anchor) => (
          <React.Fragment key={anchor}>
            {/* <Button
              variant="contained"
              endIcon={<FilterAltIcon />}
              onClick={toggleDrawer(anchor, true)}
            >
              بحث
            </Button> */}
            <Drawer
              anchor={anchor}
              open={bottom[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              <AppBar
                position="absolute"
                sx={{
                  padding: "0",
                  margin: "0",
                }}
              >
                <Toolbar
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: "#369cbe",
                  }}
                >
                  <ClearOutlinedIcon
                    onClick={toggleDrawer(anchor, false)}
                    style={{ cursor: "pointer" }}
                  />
                  <Typography variant="h6" noWrap component="div">
                    {__translate("wo.advance_search")}
                  </Typography>
                  <SearchOutlinedIcon
                    onClick={toggleDrawer(anchor, false)}
                    style={{ cursor: "pointer" }}
                  />
                </Toolbar>
              </AppBar>
              {list(anchor)}
              {/* <Button
                className="userCargos__searchBtn"
                variant="contained"
                endIcon={<FilterAltIcon />}
                onClick={() => {
                  searchUserCargo();
                }}
              >
                {__translate("my_cargos.advance_search.search")}
              </Button> */}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
