import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import NavBar from "./components/navBar/navbar";
import Footer from "./components/footer/footer";
import WaybillOrder from "./pages/waybill_order/list/container";
import Details from "./pages/waybill_order/details/container";
import UserProfile from "./pages/user_profile/contiener";
import ClearingAgentList from "./pages/clearingAgent/clearingAgentList/container";
import ClearingAgentDetails from "./pages/clearingAgent/clearingAgentDetails/container";
import i18n from "i18next";
import Backend from "i18next-fs-backend";
import axios from "./config/axios/axios";
import { myGetToken } from "./config/firebase";
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "./useContext";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import CookieConsent from "react-cookie-consent";
import PageNotFound from "./components/pageNotFound/pageNotFound";
import DownloadPwa from "./components/downloadPwa/downloadPwa";

function Router() {
  const context = useContext(UserContext);
  const [deviceType, setdeviceType] = useState("");
  const [dictionaryIsLoding, setDictionaryIsLoding] = useState(true);
  const [dict, setDict] = useState({});
  const [isTokenFound, setTokenFound] = useState(false);
  useEffect(() => {
    myGetToken(setTokenFound);
    if (window.innerWidth < 1000) {
      setdeviceType("mobile");
      import("../src/assets/css/mobile_css/master_mobile.css");
      if (localStorage.getItem("dir") === "ltr") {
        import("../src/assets/css/mobile_css/ltr_mobile.css");
      } else if (localStorage.getItem("dir") === "rtl") {
        import("../src/assets/css/mobile_css/rtl_mobile.css");
      }
    } else {
      setdeviceType("web");
      import("../src/assets/css/web_css/master_web.css");
      if (localStorage.getItem("dir") === "ltr") {
        import("../src/assets/css/web_css/ltr_web.css");
      } else if (localStorage.getItem("dir") === "rtl") {
        import("../src/assets/css/web_css/rtl_web.css");
      }
    }
    console.log(isTokenFound, "is token found my man");
    getDictionary();
    context.initLocalStorage();
    document
      .getElementById("dir_controll")
      .setAttribute("dir", localStorage.getItem("dir"));

    // // switch profile
    // if (localStorage.getItem("selected_company_id")) {
    //   axios
    //     .get("https://api.minagate.com/login", {
    //       params: {
    //         method: "switchProfile",
    //         company_id: localStorage.getItem("selected_company_id")
    //       },
    //     })
    //     .then((res) => { })
    // }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getUserProfile();
    }, 500);
  }, [localStorage.getItem("isAuthenticated")]);
  const getUserProfile = (idToken, refresh_token) => {
    return new Promise(function (resolve, reject) {
      axios
        .get("/user", {
          params: {
            method: "getUserProfile",
            idtoken: idToken,
            refresh_token: refresh_token,
            // uuid: store.getState().wsManager.UUID
          },
        })
        .then((response) => {
          localStorage.setItem("userRole", response.data.USER_ROLES);
        });
    });
  };

  const theme = createTheme({
    typography: {
      fontFamily: ["Tajawal", "New-Gen"].join(","),
    },
  });

  const getDictionary = () => {
    let params = { method: "getContainerDictionary" };
    axios
      .post("gateway", params)
      .then((res) => {
        setDict(res.data);
        setDictionaryIsLoding(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  let lang = localStorage.getItem("lang");

  i18n
    .use(Backend) // passes i18n down to react-i18next
    .init({
      // the translations
      // (tip move them in a JSON file and import them,
      // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
      resources: dict,
      lng: lang, // if you're using a language detector, do not define the lng option
      fallbackLng: "en",
      interpolation: {
        escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      },
    });

  // ------------------------------------------------------------------------------------------- //
  // -------------------------- login user and get its profile ----------------------------------//
  // ------------------------------------------------------------------------------------------- //

  return (
    <>
      {dictionaryIsLoding ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
          <div
            style={{
              fontSize: "18px",
              color: "#ccd",
              fontWeight: "900",
              marginTop: "0.5rem",
            }}
          >
            v 1.22
          </div>
        </Box>
      ) : (
        <BrowserRouter>
          <CookieConsent
            location="bottom"
            buttonText="Accept Cookie"
            cookieName="PHPSESSID"
            style={{
              background: "#2f90b0",
              // flexDirection: "row-reverse",
            }}
            buttonStyle={{ color: "#4e503b", fontSize: "1.5rem" }}
            expires={150}
          >
            This website uses cookies to enhance the user experience.{" "}
          </CookieConsent>
          {deviceType == "mobile" && <DownloadPwa />}

          <ThemeProvider theme={theme}>
            <NavBar deviceType={deviceType} />
            <div className="MinaGenFont mainBody fontSmall">
              <Routes>
                <Route
                  path="/"
                  element={<WaybillOrder deviceType={deviceType} />}
                />

                <Route
                  path={"/details/:id"}
                  element={<Details deviceType={deviceType} />}
                />
                <Route
                  path="/user_profile"
                  element={<UserProfile deviceType={deviceType} />}
                />
                <Route
                  path="/my_cargos"
                  element={<ClearingAgentList deviceType={deviceType} />}
                />

                <Route
                  path="/my_cargos/details/:id/:flag_available"
                  element={<ClearingAgentDetails deviceType={deviceType} />}
                />
                <Route
                  path="/my_cargos/details/:id"
                  element={<ClearingAgentDetails deviceType={deviceType} />}
                />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
              {/* <Footer /> */}
            </div>
          </ThemeProvider>
        </BrowserRouter>
      )}
    </>
  );
}

export default Router;
