import React, { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Rating from "@mui/material/Rating";
import DriveRImg from "../../../../assets/arabia.png";
import ChartBody from "../../../../components/chart/chart";
import { OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "../../../../config/axios/axios";
import MobileSckeleton from "../../../../components/sckeltonLoadingIndecator/mobileSckeleton";
import Autocomplete from "@mui/material/Autocomplete";
import { t as __translate } from "i18next";
import BreadCrumbs from "../../../../components/breadCrumbs/breadCrumbs";
import VerticalInstractions from "../../../../components/instractions/vertical_instractions";
import { muiCoustomStyles } from "../../../../assets/mui_style";
import { UserContext } from "../../../../useContext";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AppBar, Toolbar } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { green, red } from "@mui/material/colors";
import { Alert, Divider, Snackbar, Stack } from "@mui/material";
import Slide from "@mui/material/Slide";
import ArchiveIcon from "@mui/icons-material/Archive";
import BackBtn from "../../../../components/backBtn/backBtn";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Container(props) {
  const context = useContext(UserContext);
  const classes = muiCoustomStyles();
  const navigate = useNavigate();
  const [selectedCities, setSelectedCities] = useState(null);
  const [selectedLoction, setSelectedLocation] = useState(null);
  const [location_free_text, setLocation_free_text] = useState(null);
  const [base_price, setBase_price] = useState("");
  const [discharge_delay, setDischarge_delay] = useState("");
  const [inspection_delay, setInspection_delay] = useState("");
  const [statistics, setStatistics] = useState(null);
  const [driver_info, setDrivier_info] = useState(null);
  const [details, setDetails] = useState(null);
  const [withReturnCheckbox, setWithReturnCheckbox] = useState(null);
  const [withInspectionCheckbox, setWithInspectionCheckbox] = useState(null);
  const [printPriceInBoneCheckbox, setPrintPriceInBoneCheckbox] =
    useState(null);
  const [showStatisticsAndDriver, setShowStatisticsAndDriver] = useState(true);
  const [loading, setLoading] = useState(false);
  const [create_wo_error_msg, setCreate_wo_error_msg] = useState("");
  const [open, setOpen] = useState(false);
  const [confirmBtnText, setConfirmBtnText] = useState(
    __translate("public.confirmation_modal.request_confirmation")
  );
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [required, setRequired] = useState(false);

  // Locations
  const [locations, setLocations] = useState([]);
  const [subLocations, setSubLocations] = useState([]);
  const [locationId, setLocationId] = useState(null);
  const [locationObj, setLocationObj] = useState(null);
  const [subLocationObj, setSubLocationObj] = useState(null);
  const [locationFreeText, setLocationFreeText] = useState(null);
  const [openTaqreeshModal, setOpenTaqreeshModal] = useState(false);















  // --------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------
  const [driver_mobile_in_discharge_location, setDriverMobileInDischargeLocation] = useState("");
  const [discharge_time_details, setDischargeTimeDetails] = useState("");
  const [export_containers_details, setExportContainersDetails] = useState("");
  const [late_fine_every_day, setLateFineEvryDay] = useState("");
  // --------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------













  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
    ...(fail && {
      bgcolor: red[500],
      "&:hover": {
        bgcolor: red[700],
      },
    }),
  };

  const handleCloseTaqreeshModal = () => {
    setOpenTaqreeshModal(false);
  };
  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      let params = {
        method: "createCargoForCa",
        id: props.details.id,
        tender_id: 21,
        discharge_delay: discharge_delay,
        base_price: base_price,
        inspection_delay: inspection_delay,
        // notes:  ,
        location_id: locationId, // location id
        location_sub_id: subLocationObj.id, // locatino sub_id
        location_free_text: locationFreeText,
        show_price: printPriceInBoneCheckbox,
        empty_return: withReturnCheckbox,
        with_inspection: withInspectionCheckbox,
















           // -----------------------------------------------------------------------------------------------------
        // -----------------------------------------------------------------------------------------------------
        // -----------------------------------------------------------------------------------------------------

        driver_mobile_in_discharge_location,
        discharge_time_details,
        export_containers_details,
        late_fine_every_day,

            // -----------------------------------------------------------------------------------------------------
        // -----------------------------------------------------------------------------------------------------
        // -----------------------------------------------------------------------------------------------------













      };

      axios
        .get("/cargo", { params: params })
        .then((res) => {
          setRequired(false);
          setFail(false);
          setSuccess(true);
          setConfirmBtnText(
            __translate("public.confirmation_modal.request_confirmed", {
              cid: details.cid,
            })
          );
          setDetails({ ...details, flag_available: false });
          setLoading(false);
          setShowStatisticsAndDriver(false);
          context.handelGlobalAleart(true);
          setTimeout(() => {
            navigate("/my_cargos");
          }, 1000);
        })
        .catch((error) => {
          let errorMsg = "";
          let errorBindParam = [];

          try {
            let errorObj = JSON.parse(error.response.data.MESSAGE);
            errorMsg = errorObj.message;
            errorBindParam = errorObj.params;
          } catch (err) {
            errorMsg = error.response.data.MESSAGE;
            errorBindParam = [];
          }

          setFail(true);
          setSuccess(false);
          setLoading(false);
          setCreate_wo_error_msg(__translate(errorMsg, errorBindParam));
          setConfirmBtnText(
            __translate("public.confirmation_modal.confirmation_failed")
          );
        });
    }
  };

  const handleClose = () => {
    setCreate_wo_error_msg(null);
    setRequired(false);
    setOpen(false);
    setFail(false);
    setSuccess(false);
    setLoading(false);
    setConfirmBtnText(
      __translate("public.confirmation_modal.request_confirmation")
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
    if (
      base_price &&
      discharge_delay &&
      inspection_delay &&
      locationObj.id &&
      subLocationObj.id











           // -----------------------------------------------------------------------------------
      // -----------------------------------------------------------------------------------
      // -----------------------------------------------------------------------------------
      && driver_mobile_in_discharge_location &&
      discharge_time_details &&
      export_containers_details &&
      late_fine_every_day
      // -----------------------------------------------------------------------------------
      // -----------------------------------------------------------------------------------
      // -----------------------------------------------------------------------------------






    ) {
      setRequired(false);

      return;
    } else {
      setRequired(true);
    }
  };
  useEffect(() => {
    console.log("details", details);
    // get the location and city from options to bedefualt value in case wabill order
    if (props.details) {
      fillLocations();
      setLocationId(locationId ? locationId : props.details.location_id);
      let props_sub_location_ob = {};
      props_sub_location_ob.id =
        typeof props.details.location_sub_id == "undefined"
          ? ""
          : props.details.location_sub_id;
      props_sub_location_ob.name =
        typeof props.details.location_sub_name == "undefined"
          ? ""
          :  __translate("wo.sub_location." + props.details.location_sub_id);
      setSubLocationObj(props_sub_location_ob);

      let props_location_ob = {};
      props_location_ob.id =
        typeof props.details.location_id == "undefined"
          ? ""
          : props.details.location_id;
      props_location_ob.name =
        typeof props.details.location_name == "undefined"
          ? ""
          : __translate("wo.location." + props.details.location_id);
      setLocationObj(props_location_ob);

      setLocationFreeText(
        locationFreeText
          ? locationFreeText
          : typeof props.details.location_free_text == "undefined"
          ? ""
          : props.details.location_free_text
      );















       // --------------------------------------------------------------------------------------------------
      // --------------------------------------------------------------------------------------------------
      // --------------------------------------------------------------------------------------------------
      setDriverMobileInDischargeLocation(
        driver_mobile_in_discharge_location
        ? driver_mobile_in_discharge_location
        : typeof props.details.driver_mobile_in_discharge_location == "undefined"
          ? ""
          : props.details.driver_mobile_in_discharge_location
      )
      setDischargeTimeDetails(
        discharge_time_details
        ? discharge_time_details
        : typeof props.details.discharge_time_details == "undefined"
          ? ""
          : props.details.discharge_time_details
      )
      setExportContainersDetails(
        export_containers_details
        ? export_containers_details
        : typeof props.details.export_containers_details == "undefined"
          ? ""
          : props.details.export_containers_details
      )
      setLateFineEvryDay(
        late_fine_every_day
        ? late_fine_every_day
        : typeof props.details.late_fine_every_day == "undefined"
          ? ""
          : props.details.late_fine_every_day
      )
      // --------------------------------------------------------------------------------------------------
      // --------------------------------------------------------------------------------------------------
      // --------------------------------------------------------------------------------------------------

















      setWithReturnCheckbox(
        withReturnCheckbox ? withReturnCheckbox : props.details.empty_return
      );
      setWithInspectionCheckbox(
        withInspectionCheckbox
          ? withInspectionCheckbox
          : props.details.with_inspection
      );
      setPrintPriceInBoneCheckbox(
        printPriceInBoneCheckbox
          ? printPriceInBoneCheckbox
          : props.details.show_price
      );
      setBase_price(base_price ? base_price : props.details.base_price);
      setDischarge_delay(
        discharge_delay ? discharge_delay : props.details.discharge_delay
      );
      setInspection_delay(
        inspection_delay ? inspection_delay : props.details.inspection_delay
      );
      setStatistics(props.details ? props.details.statistics : null);
      setDrivier_info(props.details ? props.details.driver_info : null);
      setDetails(props.details ? props.details : null);
    }
  }, [props]);
  const handleCities = (event, value) => {
    setSelectedCities(value.id);
    context.handelSelectedCity(value.id);
  };
  const handleLocation = (event, value) => {
    setSelectedLocation(value.id);
  };

  const fillLocations = () => {
    // Mo7afazat
    axios
      .get("/location", {
        params: {
          method: "getContainerLocation",
          filter: '{"parent_id":91000000}',
        },
      })
      .then((res) => {
        setLocations(res.data.data);
      })
      .catch((error) => {
        Alert.error(error);
      });
  };

  const handelLocationClick = (location_id, location_name) => {
    setLocationId(location_id);
    setLocationObj({ id: location_id, name: location_name });
    fillSubLocation(location_id);
  };

  const fillSubLocation = (location_id) => {
    axios
      .get("/location", {
        params: {
          method: "getContainerSubLocation",
          parent_id: `${location_id}`,
        },
      })
      .then((res) => {
        setSubLocations(res.data.data);
        setSubLocationObj({
          id: res.data.data[0].id,
          name: res.data.data[0].name,
        });
      })
      .catch((error) => {
        // navigate("/my_cargos")
        // Alert.error(error);
      });
  };

  return (
    <React.Fragment>
      <BackBtn />
      {/* <BreadCrumbs
        crumpsDetails={[
          {
            href: "/",
            titel: __translate("public.bread_crumbs.home"),
          },
          {
            titel: __translate("public.bread_crumbs.my_cargos"),
            href: "/my_cargos",
          },
          {
            titel: __translate("public.bread_crumbs.cargos_details"),
            color: "black",
          },
        ]}
      /> */}
      <div className=" col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
        {locations && details && !loading ? (
          <>
            {/* <div className='col-xs-4 col-sm-4 col-md-4 col-lg-4 clearingAgentDetails__category ' > */}
            <Card className="clearingAgent__Cards">
              <CardContent sx={{ borderBottom: "1px solid #d1d1d1" }}>
                <fieldset
                  className="advanceSearch__fieldset"
                  style={{ minHeight: "25vh" }}
                >
                  <legend className="advanceSearch__legend">
                    {__translate("my_cargos.land_trip_details.titel")}
                  </legend>
                  <div className="clearingAgentDetails__detailsCategory__text">{`${__translate(
                    "my_cargos.land_trip_details.cid"
                  )}: ${details.cid ? details.cid : ""}`}</div>
                  <div className="clearingAgentDetails__detailsCategory__text">
                    {" "}
                    {`${__translate(
                      "my_cargos.land_trip_details.declaration"
                    )}: ${
                      details.declaration_number
                        ? details.declaration_number
                        : ""
                    }`}
                  </div>
                  <div className="clearingAgentDetails__detailsCategory__text">{`${__translate(
                    "my_cargos.land_trip_details.bol"
                  )}: ${details.bl ? details.bl : ""}`}</div>

                  <div className="clearingAgentDetails__detailsCategory__text">{`${__translate(
                    "my_cargos.land_trip_details.weight"
                  )}: ${details.weight ? details.weight : ""}`}</div>

                  <div className="clearingAgentDetails__detailsCategory__text">{`${__translate(
                    "my_cargos.land_trip_card.create_date"
                  )}  : ${
                    details.create_date
                      ? details.create_date.split(" ")[0]
                      : "--"
                  }`}</div>
                  <FormGroup>
                    <FormControlLabel
                      onChange={(e) => {
                        setWithReturnCheckbox(e.target.checked);
                      }}
                      checked={withReturnCheckbox}
                      disabled={!details.flag_available}
                      sx={{ margin: "0" }}
                      control={<Checkbox />}
                      label={__translate(
                        "my_cargos.land_trip_details.empty_return"
                      )}
                    />
                    <FormControlLabel
                      onChange={(e) => {
                        setWithInspectionCheckbox(e.target.checked);
                      }}
                      checked={withInspectionCheckbox}
                      disabled={!details.flag_available}
                      sx={{ margin: "0" }}
                      control={<Checkbox />}
                      label={__translate(
                        "my_cargos.land_trip_details.with_inspection"
                      )}
                    />

                    <FormControlLabel
                      onChange={(e) => {
                        setPrintPriceInBoneCheckbox(e.target.checked);
                      }}
                      checked={printPriceInBoneCheckbox}
                      disabled={!details.flag_available}
                      sx={{ margin: "0" }}
                      control={<Checkbox />}
                      label={__translate(
                        "my_cargos.land_trip_details.print_price"
                      )}
                    />
                  </FormGroup>
                </fieldset>
                <fieldset
                  className="advanceSearch__fieldset"
                  style={{ minHeight: "25vh" }}
                >
                  <legend className="advanceSearch__legend">
                    {__translate("my_cargos.land_trip_price.title")}
                  </legend>
                  
                  
                  
                  <div id="price_div">
                    <TextField
                      disabled={!details.flag_available}
                      InputLabelProps={{
                        style: {
                          width: "122%",
                        },
                      }}
                      className={classes.mui_label}
                      label={__translate("my_cargos.land_trip_price.basic")}
                      id="outlined-size-small"
                      sx={{ margin: "2%", width: "95%" }}
                      size="small"
                      onChange={(event) => {
                        setBase_price(event.target.value);
                      }}
                      value={base_price}
                    />
                    <TextField
                      disabled={!details.flag_available}
                      InputLabelProps={{
                        style: {
                          width: "122%",
                        },
                      }}
                      className={classes.mui_label}
                      label={__translate(
                        "my_cargos.land_trip_price.inspection"
                      )}
                      id="outlined-size-small"
                      sx={{ margin: "2% ", width: "95%" }}
                      size="small"
                      onChange={(event) => {
                        setInspection_delay(event.target.value);
                      }}
                      value={inspection_delay}
                    />
                    <TextField
                      disabled={!details.flag_available}
                      InputLabelProps={{
                        style: {
                          width: "122%",
                        },
                      }}
                      // error={discharge_delay ? false : true}
                      className={classes.mui_label}
                      label={__translate("my_cargos.land_trip_price.discharge")}
                      id="outlined-size-small"
                      sx={{ margin: "2% ", width: "95%" }}
                      size="small"
                      onChange={(event) => {
                        setDischarge_delay(event.target.value);
                      }}
                      value={discharge_delay}
                    />


































                                          {/* -------------------------------------------------------------------------------- */}
                      {/* -------------------------------------------------------------------------------- */}
                      {/* -------------------------------------------------------------------------------- */}

                      <TextField
                        disabled={!details.flag_available}
                        InputLabelProps={{
                          style: {
                            width: "125%",
                          },
                        }}
                        // error={
                        //   discharge_delay && !details.flag_available
                        //     ? false
                        //     : true
                        // }
                        label={__translate(
                          "my_cargos.land_trip_price.lateFineEveryDay"
                        )}
                        id="outlined-size-small"
                        sx={{ margin: "2% ", width: "95%" }}
                        size="small"
                        onChange={(event) => {
                          if (isNaN(Number(event.target.value))) {
                            setLateFineEvryDay(late_fine_every_day)
                          }
                          else {
                            setLateFineEvryDay(event.target.value)
                          }
                        }}
                        value={late_fine_every_day}
                      />
                      {/* -------------------------------------------------------------------------------- */}
                      {/* -------------------------------------------------------------------------------- */}
                      {/* -------------------------------------------------------------------------------- */}
































                  </div>
                </fieldset>

                <fieldset
                  className="advanceSearch__fieldset"
                  style={{ minHeight: "25vh" }}
                >
                  <legend className="advanceSearch__legend">
                    {" "}
                    {__translate("my_cargos.land_trip_discharge.title")}
                  </legend>
                  <div>
                    <FormControl
                      sx={{ margin: "2%", width: "95%" }}
                      size="small"
                    >
                      <Autocomplete
                        size="small"
                        id="country-select-demo"
                        InputLabelProps={{
                          style: {
                            width: "122%",
                            textAlign: "center",
                          },
                        }}
                        options={locations}
                        autoHighlight
                        disabled={!details.flag_available}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // error={selectedCities ? false : true}
                            label={__translate(
                              "my_cargos.land_trip_discharge.discharge_location"
                            )}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                            className={classes.mui_label}
                          />
                        )}
                        onChange={(event, value) =>
                          handelLocationClick(value.id, value.name)
                        }
                        value={locationObj}
                      />
                    </FormControl>

                    <FormControl
                      sx={{ margin: "2%", width: "95%" }}
                      size="small"
                    >
                      <Autocomplete
                        size="small"
                        id="country-select-demo"
                        options={subLocations}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // error={selectedCities ? false : true}
                            label={__translate(
                              "my_cargos.land_trip_discharge.discharge_sub_location"
                            )}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                            className={classes.mui_label}
                          />
                        )}
                        onChange={(event, value) => setSubLocationObj(value)}
                        value={subLocationObj}
                        disabled={!details.flag_available}
                      />
                    </FormControl>

                    <TextField
                      disabled={!details.flag_available}
                      InputLabelProps={{
                        style: {
                          width: "122%",
                        },
                      }}
                      className={classes.mui_label}
                      label={__translate(
                        "my_cargos.land_trip_discharge.discharge_notes"
                      )}
                      id="outlined-size-small"
                      sx={{ margin: "2%", width: "95%" }}
                      size="small"
                      onChange={(event) => {
                        setLocation_free_text(event.target.value);
                      }}
                      value={location_free_text}
                    />





















                    {/* --------------------------------------------------------------------------------- */}
                    {/* --------------------------------------------------------------------------------- */}
                    {/* --------------------------------------------------------------------------------- */}

                    <TextField
                      disabled={!details.flag_available}
                      // InputLabelProps={{
                      //   style: {
                      //     width: "93%",
                      //   },
                      // }}
                      label={__translate(
                        "my_cargos.land_trip_discharge.person_phone"
                      )}
                      id="outlined-size-small"
                      sx={{ margin: "2%", width: "95%" }}
                      size="small"
                      onChange={(event) => {
                        setDriverMobileInDischargeLocation(event.target.value);
                      }}
                      value={driver_mobile_in_discharge_location}
                      className={classes.mui_label}
                    />
                    <TextField
                      disabled={!details.flag_available}
                      // InputLabelProps={{
                      //   style: {
                      //     width: "93%",
                      //   },
                      // }}
                      label={__translate(
                        "my_cargos.land_trip_discharge.dischargeTimeDetails"
                      )}
                      id="outlined-size-small"
                      sx={{ margin: "2%", width: "95%" }}
                      size="small"
                      onChange={(event) => {
                       console.log(event.target.value,"event.target.value")
                        setDischargeTimeDetails(event.target.value);
                      }}
                      value={discharge_time_details}
                      className={classes.mui_label}
                    />
                    <TextField
                      disabled={!details.flag_available}
                      // InputLabelProps={{
                      //   style: {
                      //     width: "93%",
                      //   },
                      // }}
                      label={__translate(
                        "my_cargos.land_trip_discharge.exportContainerDetails"
                      )}
                      id="outlined-size-small"
                      sx={{ margin: "2%", width: "95%" }}
                      size="small"
                      onChange={(event) => {
                        setExportContainersDetails(event.target.value);
                      }}
                      value={export_containers_details}
                      className={classes.mui_label}
                    />
                    {/* --------------------------------------------------------------------------------- */}
                    {/* --------------------------------------------------------------------------------- */}
                    {/* --------------------------------------------------------------------------------- */}






















                  </div>
                </fieldset>
                {details.flag_available ? (
                  <div className="text-center mb-1 mt-4">
                    <Button
                      className="primary__btn my-4"
                      variant="contained"
                      onClick={handleClickOpen}
                    >
                      {__translate(
                        "my_cargos.land_trip_details.confirm_requerst"
                      )}
                    </Button>
                    {/* <Button sx={{ marsgin: "0 0.5rem" }} variant="outlined">تعديل</Button> */}
                  </div>
                ) : showStatisticsAndDriver ? (
                  <>
                    <fieldset
                      className="advanceSearch__fieldset"
                      style={{ minHeight: "25vh" }}
                    >
                      <legend className="advanceSearch__legend">
                        {__translate("my_cargos.land_trip_stats.title")}
                      </legend>

                      {/*  الاحصائيات */}

                      <div id="statistics" className=" fontLarge flex-column">
                        {props.details.stage || props.details.stage == 0 ? (
                          <VerticalInstractions
                            steps={[
                              __translate("public.instractions.accept_cargo"),
                              __translate(
                                "public.instractions.receive_declaration"
                              ),
                            ]}
                            icons={{
                              1: <i class="fa-solid fa-check-to-slot"></i>,
                              2: <i class="fa-solid fa-file-invoice"></i>,
                            }}
                            stage={0}
                          />
                        ) : (
                          <ChartBody
                            chartType="ColumnChart"
                            data={statistics}
                            options={{
                              isStacked: true,
                              height: "96%",
                              legend: { position: "top", maxLines: 3 },
                              vAxis: { minValue: 0 },
                              colors: ["#db4437"],
                            }}
                          />
                        )}
                      </div>
                    </fieldset>

                    <fieldset
                      className="advanceSearch__fieldset"
                      style={{ minHeight: "25vh" }}
                    >
                      <legend className="advanceSearch__legend">
                        {__translate("my_cargos.land_trip_reserve.title")}
                      </legend>
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12  ">
                        <div className="multipelDetailedCard__body fontSmall ">
                          {driver_info ? (
                            <>
                              <div class=" col-12 pt-3 fontSmall">
                                {`${__translate(
                                  "my_cargos.land_trip_driver.name"
                                )}  : ${
                                  driver_info.name
                                    ? driver_info.name
                                    : __translate(
                                        "my_cargos.land_trip_driver.not_availabel"
                                      )
                                }`}
                              </div>
                              <div class=" col-12 pt-3 fontSmall">
                                {`${__translate(
                                  "my_cargos.land_trip_driver.phone"
                                )}  : ${
                                  driver_info.phone
                                    ? driver_info.phone
                                    : __translate(
                                        "my_cargos.land_trip_driver.not_availabel"
                                      )
                                }`}
                              </div>
                              <div class=" col-12 pt-3 fontSmall">
                                {`${__translate(
                                  "my_cargos.land_trip_driver.reserve_date"
                                )}  : ${
                                  driver_info.reserve_date
                                    ? driver_info.reserve_date
                                    : __translate(
                                        "my_cargos.land_trip_driver.not_availabel"
                                      )
                                }`}
                              </div>
                            </>
                          ) : (
                            <div class="  col-12  text-center pt-3">
                              {__translate(
                                "my_cargos.land_trip_driver.not_found"
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </fieldset>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        className="btn primary__btn mt-4"
                        variant="contained"
                        onClick={() => {
                          setOpenTaqreeshModal(true);
                        }}
                      >
                        {__translate("tagreesh_modal.info")}
                      </Button>
                    </div>
                  </>
                ) : null}
              </CardContent>
            </Card>
            {/* </div> */}
          </>
        ) : (
          <MobileSckeleton />
        )}
      </div>
      <Dialog
        open={open}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {!required ? (
          <React.Fragment>
            <DialogTitle
              sx={
                localStorage.getItem("dir") === "rtl"
                  ? { textAlign: "right" }
                  : { textAlign: "left" }
              }
            >
              {create_wo_error_msg ? null : (
                <React.Fragment>
                  {__translate(
                    "public.confirmation_modal.waybill_order",
                    details ? { cid: details.cid } : ""
                  )}
                </React.Fragment>
              )}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ m: 1, position: "relative" }}>
                    {create_wo_error_msg ? null : (
                      <Button
                        variant="contained"
                        sx={{
                          ...buttonSx,
                          width: "20vh",
                          height: "5vh",
                          display: "flex",
                          justifyContent: "space-evenly",
                        }}
                        disabled={loading}
                        onClick={handleButtonClick}
                      >
                        {success ? (
                          <i
                            class="fa-solid fa-check-double"
                            style={{
                              fontSize: "1rem",
                            }}
                          ></i>
                        ) : fail ? (
                          <i
                            class="fa-solid fa-xmark"
                            style={{
                              fontSize: "1rem",
                            }}
                          ></i>
                        ) : (
                          <i
                            class="fa-solid fa-check"
                            style={{
                              fontSize: "1rem",
                            }}
                          ></i>
                        )}
                        {confirmBtnText}
                      </Button>
                    )}
                    {loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: green[500],
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                    {create_wo_error_msg && (
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="error">{create_wo_error_msg}</Alert>
                      </Stack>
                    )}
                  </Box>
                </Box>
              </DialogContentText>

              <Divider />
              <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#dc3545" }}
                  disabled={loading}
                  onClick={handleClose}
                >
                  {__translate("public.confirmation_modal.close")}
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <DialogTitle>
              {" "}
              {__translate("public.confirmation_modal.required_info")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                ></Box>
              </DialogContentText>

              <Divider />
              <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "#dc3545" }}
                  disabled={loading}
                  onClick={handleClose}
                >
                  {__translate("public.confirmation_modal.close")}
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )}
      </Dialog>
      <Dialog
        fullScreen
        open={openTaqreeshModal}
        onClose={handleCloseTaqreeshModal}
        TransitionComponent={Transition}
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
          sx={{ backgroundColor: "#369cbe" }}
        >
          <ClearOutlinedIcon
            onClick={handleCloseTaqreeshModal}
            style={{
              color: "white",
              cursor: "pointer",
              position: "absolute",
              left: localStorage.getItem("dir") === "ltr" ? "2%" : "0",
              right: localStorage.getItem("dir") === "rtl" ? "2%" : "0",
            }}
          />
          <Typography
            variant="h6"
            sx={{ color: "white" }}
            noWrap
            component="div"
          >
            {__translate("tagreesh_modal.titel")}
          </Typography>
        </Toolbar>

        <TableContainer component={Paper}>
          <Table
            sx={{
              maxWidth: "94%",
              margin: "auto",
              marginTop: "1rem",
              border: "1px solid  #ccc",
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Titel</TableCell>
                <TableCell align="center">Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell scope="row">Base Price</TableCell>

                <TableCell align="center">{base_price}</TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell scope="row">Inspection delay</TableCell>

                <TableCell align="center">{inspection_delay * 3}</TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell scope="row">Discharge delay</TableCell>

                <TableCell align="center">{discharge_delay}</TableCell>
              </TableRow>

              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell scope="row">
                  {__translate("delivery_report.loading_time")}
                </TableCell>

                <TableCell align="center">19/2/2023</TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell scope="row">
                  {__translate("delivery_report.arriving_time")}
                </TableCell>

                <TableCell align="center">20/2/2023</TableCell>
              </TableRow>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell scope="row">
                  {__translate("delivery_report.discharge_time")}
                </TableCell>

                <TableCell align="center">20/2/2023</TableCell>
              </TableRow>

              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell scope="row">Total</TableCell>

                <TableCell align="center">
                  {parseInt(base_price) +
                    parseInt(inspection_delay) +
                    parseInt(discharge_delay)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Dialog>
    </React.Fragment>
  );
}
