import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
// import ImageLoader from 'react-load-image';
import Img from 'react-image'
import Confirm from '../confirm/confirm';
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap'
// import { ClipLoader } from 'react-spinners';
import $ from "jquery"


export default class LightboxGallary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
      originalFile: ''
    };
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.originalFile != this.props.originalFile && nextProps.originalFile.trim() != '') {
      this.props = nextProps
      this.setState({ originalFile: nextProps.originalFile })
    }
  }
  showOrginalFile = (showType) => {

    if (this.props.originalFile.length == 0) {
      return
    }

    this.setState({ originalFile: this.props.originalFile }, () => {
      if (showType == 'showFile') {
        $('#dialog').html(
          '<embed src="' + this.state.originalFile + '?random=' + ((new Date()).getTime() + Math.floor(Math.random() * 1000000)) + '"' +
          'style="height:inherit;min-height:700px;width:inherit; min-width:950px"></embed>'
        );

        $("#dialog").dialog({
          minHeight: 700, minWidth: 950,
          open: {

          }
        })

      }


    })

  }


  render() {
    const { photoIndex, isOpen } = this.state;
    let rand = Math.random();
    const imageThumbs = this.props.imageSource.map((currElement, index) => {

      return (

        <div key={rand} style={{ width: this.props.width, height: this.props.isTruckingCompanyPhoto ? 'max-content' : '240px' }} >
          {
            !this.props.isTruckingCompanyPhoto &&
            <div style={{ backgroundColor: '#E7ECF8', height: '40px' }} >
              {this.props.showCloseIcon != false ? (
                <Confirm
                  onConfirm={this.props.resetComponent}
                  body="هل انت متأكد من حذف الصورة؟"
                  confirmText="نعم متأكد"
                  cancelText="لا"
                  style={{ position: 'absolute', top: 5, left: 5, zIndex: 1, margin: '5px', cursor: 'pointer' }}
                  title="">

                  <div>

                    <OverlayTrigger placement="top"
                      overlay={<Popover id="popover-activated-on-hover-popover">حذف الصورة</Popover>}>
                      <span>
                        <i className="fa fa-times" style={{ color: 'red', fontSize: '25px' }}></i>
                      </span>
                    </OverlayTrigger>
                  </div>
                </Confirm>) : null}
              {
                window.innerWidth > 1000 ?
                  <OverlayTrigger placement="top"
                    overlay={<Popover id="popover-activated-on-hover-popover">مشاهدة الأصل</Popover>}>
                    <span onClick={() => this.showOrginalFile('showFile')}
                      style={{ position: 'absolute', left: 30, top: 5, zIndex: 1, margin: '5px', cursor: 'pointer' }}>
                      <i className="fa fa-external-link" style={{ color: '#484443', fontSize: '23px', paddingTop: '3px' }}></i>
                    </span>

                  </OverlayTrigger>
                  : null


              }



              <div id="dialog" style={{ display: 'none', overflowY: 'hidden', overflowX: 'hidden', width: 'auto', height: 'auto' }}>

              </div>

              <span onClick={() => window.open(this.state.originalFile)}
                style={{ position: 'absolute', right: 5, top: 5, zIndex: 1, margin: '5px', cursor: 'pointer' }}>
                <label> {this.props.label} </label>
              </span>

            </div>

          }
          {
            this.props.isTruckingCompanyPhoto &&
            <div  >
              {this.props.showCloseIcon != false ? (
                <Confirm
                  onConfirm={this.props.resetComponent}
                  body="هل انت متأكد من حذف الصورة؟"
                  confirmText="نعم متأكد"
                  cancelText="لا"
                  style={{ position: 'absolute', zIndex: 1, margin: '5px', cursor: 'pointer' }}
                  title="">

                  <div>

                    <OverlayTrigger placement="top"
                      overlay={<Popover id="popover-activated-on-hover-popover">حذف الصورة</Popover>}>
                      <span>
                        <i className="fa fa-times" style={{ color: '#3995d2', fontSize: '25px' }}></i>
                      </span>
                    </OverlayTrigger>
                  </div>
                </Confirm>) : null}

              <div id="dialog" style={{ display: 'none', overflowY: 'hidden', overflowX: 'hidden', width: 'auto', height: 'auto' }}>
              </div>

            </div>

          }

          <div style={{
            width: this.props.width, height: this.props.height, textAlign: 'center',
            border: '1px solid lightgrey', borderRadius: '5px', align: 'center'
          }} >
            <Img src={currElement} key={rand}
              style={this.props.isTruckingCompanyPhoto ? {
                display: 'block', maxHeight: 1500, width: this.props.width, height: this.props.height, maxWidth: 1500,
                padding: '2px', cursor: 'pointer', margin: 'auto', zIndex: 1
              } : {
                display: 'block', maxHeight: '200px', height: this.props.height,
                padding: '2px', cursor: 'pointer', margin: 'auto', zIndex: 1, maxWidth: "100%"
              }}
              onClick={() => { this.showOrginalFile('showImageOnly'); this.setState({ isOpen: true, photoIndex: index }) }}
              loader={<img src="../../../assets/images/spinner.gif" />}
            />
          </div>
        </div>
      )
    })

    return (
      <div>

        {imageThumbs}

        {isOpen && this.props.originalFile === this.state.originalFile && (
          <Lightbox
            mainSrc={this.props.imageSource[photoIndex]}
            nextSrc={this.props.imageSource[(photoIndex + 1) % this.props.imageSource.length]}
            prevSrc={this.props.imageSource[(photoIndex + this.props.imageSource.length - 1) % this.props.imageSource.length]}
            originalFile={this.state.originalFile}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + this.props.imageSource.length - 1) % this.props.imageSource.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % this.props.imageSource.length,
              })
            }
          />
        )}
      </div>
    );
  }
}