import React from "react";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  // display: "flex",
  // flexDirection: localStorage.getItem("dir") == "rtl" ? "row-reverse" : "row",
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      width: "2%",
      right: "18%",
      position: "relative",
      backgroundImage:
        "linear-gradient( 95deg,#2f90b0 0%,#35c0ed 50%,#9aeba6 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      width: "2%",
      right: "18%",
      position: "relative",
      backgroundImage:
        "linear-gradient( 95deg,#2f90b0 0%,#35c0ed 50%,#9aeba6 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    width: "2%",
    right: "18%",
    position: "relative",
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 95deg,#9aeba6 0%,#35c0ed 50%,#2f90b0 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 95deg,#9aeba6 0%,#35c0ed 50%,#2f90b0 100%)",
  }),
}));

function Vertical_instractions(props) {
  const [activeStep, setActiveStep] = React.useState(props.stage);

  function ColorlibStepIcon(localprops) {
    const { active, completed, className } = localprops;

    const icons = props.icons;

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(localprops.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <div className="d-flex justify-content-center">
      <Box sx={{ maxWidth: 250, minWidth: 180 }}>
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          connector={<ColorlibConnector />}
        >
          {props.steps.map((step, index) => (
            <Step key={step}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>{step}</StepLabel>
              <StepContent></StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  );
}

export default Vertical_instractions;
