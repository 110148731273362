import React, { useContext, useEffect, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { t as __translate } from "i18next";
import { UserContext } from "../../../../useContext";
import { muiCoustomStyles } from "../../../../assets/mui_style";

function Advance_search() {
  const context = useContext(UserContext);
  const classes = muiCoustomStyles();
  const [value, setValue] = useState([0, 50]);
  const [firstValue, setFirstValue] = useState(value[0]);
  const [secondValue, setSecondValue] = useState(value[1]);
  const [advanceSearchPreperation, setAdvanceSearchPreperation] = useState({
    typeGroup: { general: true },
    lengthGroup: { 20: true },
  });
  useEffect(() => {
    let obj = {};
    if (firstValue == 0 && secondValue == 50) {
      obj = { ...advanceSearchPreperation };
    } else {
      obj = {
        weight_min_point: firstValue.toString(),
        weight_max_point: secondValue.toString(),
        ...advanceSearchPreperation,
      };
    }
    Object.keys(obj).forEach((ele) => {
      if (ele == "weight_min_point" || ele == "weight_max_point") {
        return;
      }
      obj[ele] = Object.keys(obj[ele]);
    });
    context.handelAdvanceSearchRequset(obj);
  }, [advanceSearchPreperation, firstValue, secondValue]);

  const handleChange = (event, flag) => {
    if (flag == "first") {
      if (
        event.target.value < 0 ||
        isNaN(Number(event.target.value)) ||
        event.target.value > 50
      ) {
        setFirstValue(firstValue);
        return;
      } else {
        setFirstValue(event.target.value);
      }
      setValue([event.target.value, value[1]]);
      return;
    }
    if (flag == "second") {
      if (
        event.target.value < 0 ||
        isNaN(Number(event.target.value)) ||
        event.target.value > 50
      ) {
        setSecondValue(secondValue);
        return;
      } else {
        setSecondValue(event.target.value);
      }
      setValue([value[0], event.target.value]);
      return;
    }
    setValue(event.target.value);
    setFirstValue(event.target.value[0]);
    setSecondValue(event.target.value[1]);
  };

  const handelOnChange = (event, groupName) => {
    setAdvanceSearchPreperation({
      ...advanceSearchPreperation,
      [groupName]: {
        ...advanceSearchPreperation[groupName],
        [event.target.name]: event.target.checked,
      },
    });
    if (!event.target.checked) {
      let obj = { ...advanceSearchPreperation };
      delete obj[groupName][event.target.name];
      if (Object.keys(obj[groupName]).length == 0) {
        delete obj[groupName];
      }
      setAdvanceSearchPreperation(obj);
    }
  };

  return (
    <div className="advanceSearch">
      <fieldset className="advanceSearch__fieldset">
        <legend className="advanceSearch__legend">
          {__translate("wo.advance_search.by_type")}
        </legend>
        <FormGroup>
          <FormControlLabel
            name="general"
            style={{ margin: "0", padding: "0" }}
            checked={
              advanceSearchPreperation.typeGroup &&
              advanceSearchPreperation.typeGroup.general
            }
            control={<Checkbox className={classes.mui_checkBox} />}
            label={__translate("wo.advance_search.by_type.general")}
            onChange={(event) => {
              handelOnChange(event, "typeGroup");
            }}
          />
          <FormControlLabel
            name="tank"
            style={{ margin: "0", padding: "0" }}
            control={<Checkbox className={classes.mui_checkBox} />}
            label={__translate("wo.advance_search.by_type.tank")}
            onChange={(event) => {
              handelOnChange(event, "typeGroup");
            }}
          />
          <FormControlLabel
            name="refrigerator"
            style={{ margin: "0", padding: "0" }}
            control={<Checkbox className={classes.mui_checkBox} />}
            label={__translate("wo.advance_search.by_type.refrigerator")}
            onChange={(event) => {
              handelOnChange(event, "typeGroup");
            }}
          />
          <FormControlLabel
            name="open_top"
            style={{ margin: "0", padding: "0" }}
            control={<Checkbox className={classes.mui_checkBox} />}
            label={__translate("wo.advance_search.by_type.open_top")}
            onChange={(event) => {
              handelOnChange(event, "typeGroup");
            }}
          />
          <FormControlLabel
            name="flat"
            style={{ margin: "0", padding: "0" }}
            control={<Checkbox className={classes.mui_checkBox} />}
            label={__translate("wo.advance_search.by_type.flat")}
            onChange={(event) => {
              handelOnChange(event, "typeGroup");
            }}
          />
        </FormGroup>
      </fieldset>
      <fieldset className="advanceSearch__fieldset">
        <legend className="advanceSearch__legend">
          {" "}
          {__translate("wo.advance_search.by_length")}
        </legend>
        <FormGroup>
          <FormControlLabel
            name="20"
            style={{ margin: "0", padding: "0" }}
            control={<Checkbox className={classes.mui_checkBox} />}
            checked={
              advanceSearchPreperation.lengthGroup &&
              advanceSearchPreperation.lengthGroup["20"]
            }
            label={__translate("wo.advance_search.by_length.20_feet")}
            onChange={(event) => {
              handelOnChange(event, "lengthGroup");
            }}
          />
          <FormControlLabel
            name="40"
            style={{ margin: "0", padding: "0" }}
            control={<Checkbox className={classes.mui_checkBox} />}
            label={__translate("wo.advance_search.by_length.40_feet")}
            onChange={(event) => {
              handelOnChange(event, "lengthGroup");
            }}
          />
        </FormGroup>
      </fieldset>
      <fieldset className="advanceSearch__fieldset">
        <legend className="advanceSearch__legend">
          {" "}
          {__translate("wo.advance_search.empty_return")}
        </legend>
        <FormGroup>
          <FormControlLabel
            name="true"
            style={{ margin: "0", padding: "0" }}
            control={<Checkbox className={classes.mui_checkBox} />}
            label={__translate("wo.advance_search.with_empty_return")}
            onChange={(event) => {
              handelOnChange(event, "returningGroup");
            }}
          />
          <FormControlLabel
            name="false"
            style={{ margin: "0", padding: "0" }}
            control={<Checkbox className={classes.mui_checkBox} />}
            label={__translate("wo.advance_search.without_empty_return")}
            onChange={(event) => {
              handelOnChange(event, "returningGroup");
            }}
          />
        </FormGroup>
      </fieldset>
      <fieldset className="advanceSearch__fieldset">
        <legend className="advanceSearch__legend">
          {__translate("wo.advance_search.inspection")}
        </legend>
        <FormGroup>
          <FormControlLabel
            name="true"
            style={{ margin: "0", padding: "0" }}
            control={<Checkbox className={classes.mui_checkBox} />}
            label={__translate("wo.advance_search.with_inspection")}
            onChange={(event) => {
              handelOnChange(event, "inspectionGroup");
            }}
          />
          <FormControlLabel
            name="false"
            style={{ margin: "0", padding: "0" }}
            control={<Checkbox className={classes.mui_checkBox} />}
            label={__translate("wo.advance_search.without_inspection")}
            onChange={(event) => {
              handelOnChange(event, "inspectionGroup");
            }}
          />
        </FormGroup>
      </fieldset>

      <fieldset className="advanceSearch__fieldset d-flex justify-content-center">
        <legend className="advanceSearch__legend">
          {__translate("wo.advance_search.by_weight")}
        </legend>

        <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 d-flex flex-column justify-content-center py-3">
          <Box>
            <Slider
              getAriaLabel={() => "Temperature range"}
              value={value}
              onChange={handleChange}
              valueLabelDisplay="auto"
              min={0}
              max={50}
              sx={{ color: "#2f90b0;" }}
            />
          </Box>
          <div
            className={
              localStorage.getItem("rtl")
                ? "d-flex justify-content-around"
                : "d-flex justify-content-around flex-row-reverse"
            }
          >
            <div className=" d-flex align-items-center  justify-content-around">
              <label htmlFor="to" className="m-0">
                {__translate("wo.advance_search.by_weight_to")}
              </label>
              <input
                name="to"
                className="col-xs-6 col-sm-6 col-md-6 col-lg-6 form-control"
                value={secondValue}
                type="number"
                onChange={(event) => {
                  handleChange(event, "second");
                }}
              />
            </div>
            <div className="d-flex align-items-center justify-content-around">
              <label htmlFor="from" className="m-0">
                {__translate("wo.advance_search.by_weight_from")}
              </label>
              <input
                name="from"
                className="col-xs-6 col-sm-6 col-md-6 col-lg-6 form-control"
                type="number"
                value={firstValue}
                onChange={(event) => {
                  handleChange(event, "first");
                }}
              />
            </div>
          </div>
        </div>
      </fieldset>
    </div>
  );
}

export default Advance_search;
