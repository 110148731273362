

import firebase from "firebase/compat/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAcLmVWv_auGgi8SEP9AL9bUn8I8Syc7DA",
    authDomain: "waybill-system.firebaseapp.com",
    projectId: "waybill-system",
    storageBucket: "mlni-dd081.appspot.com",
    messagingSenderId: "51892924934",
    appId: "1:51892924934:web:8285c3a82f1976e2078987",
    storageBucket: "waybill-system.appspot.com",
    measurementId: "G-K324FYG31M",
};
const firebaseApp = firebase.initializeApp(firebaseConfig);

const messaging = getMessaging(firebaseApp);
const auth = firebase.auth();
const storage = getStorage(firebaseApp);
// const storageRef = ref(storage, "some-child");

const uploadImg = async (filename, img) => {
    // let url = null
    let r = ref(storage, filename);
    await uploadBytes(r, img[0])

    return await getDownloadURL(r)
}
export { firebaseApp, auth, messaging, uploadImg, firebase, ref };

export const myGetToken = (setTokenFound) => {
    return getToken(messaging, { vapidKey: 'BOhBSzWgtrKDgKoIUWKPqStKpO1fv0t8PAjQJKLQaIOQKkhXbpOsDg_ckIW7rIZRcj7MndlNwklEFxyoGAb4m38' }).then((currentToken) => {
        console.log(currentToken, "setTokenFoundv@2.2")
        if (currentToken) {
            setTokenFound(currentToken);            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
            localStorage.setItem('web_notif', currentToken)
            return currentToken
        } else {
            setTokenFound(false);
            // shows on the UI that permission is required 
            localStorage.setItem('web_notif', null)

        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log('foreground', payload)
            resolve(payload);
        });
    });
