import { makeStyles } from "@mui/styles";
// function Mui_styler() {
export const muiCoustomStyles = makeStyles((theme) => ({
  mui_label: {
    "& label":
      localStorage.getItem("dir") === "rtl"
        ? {
            width: "100%",
            textAlign: "right",
            transformOrigin: "top right",
            right: "1.9rem",
            "&.Mui-focused": {
              transformOrigin: "top right",
            },
          }
        : {
            width: "90%",
            textAlign: "left",
            transformOrigin: " top left",
            // left: "1.9rem",
            "&.Mui-focused": {
              transformOrigin: "top  left",
            },
          },
    "& fieldset":
      localStorage.getItem("dir") === "rtl"
        ? {
            textAlign: "right",
          }
        : {
            textAlign: "left",
          },
  },
  mui_fieldset: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#2f90b0",
      },
    },
  },
  mui_checkBox: {
    "&.Mui-checked": {
      color: "#2f90b0",
    },
  },
}));
