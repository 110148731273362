import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { t as __translate } from "i18next";
import { muiCoustomStyles } from "../../../../assets/mui_style";
import { useContext } from "react";
import { UserContext } from "../../../../useContext";
// import { ClassNames } from "@emotion/react";
import classNames from "classnames";

let counter = 1;
function Advance_search(props) {
  const context = useContext(UserContext);
  const classes = muiCoustomStyles();
  const [advanceSearchPreperation, setAdvanceSearchPreperation] = useState({});
  const searchUserCargo = () => {
    props.handelSearchBtn(counter++);
    context.handelAdvanceSearchRequset(advanceSearchPreperation);
  };
  const handelChange = (event) => {
    setAdvanceSearchPreperation({
      ...advanceSearchPreperation,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <div className="advanceSearch cardList__advanceSearch--userCargos">
      <fieldset className="advanceSearch__fieldset">
        <legend className="advanceSearch__legend">
          {__translate("my_cargos.advance_search.title")}
        </legend>
        <TextField
          name="declaration_number"
          label={__translate("my_cargos.advance_search.declaration")}
          id="outlined-size-small"
          sx={{
            width: "90%",
            margin: "7%",
          }}
          className={classNames(classes.mui_label, classes.mui_fieldset)}
          size="small"
          onChange={(event) => {
            handelChange(event);
          }}
        />
        <TextField
          name="bl"
          label={__translate("my_cargos.advance_search.bol")}
          id="outlined-size-small"
          sx={{ margin: "7% ", width: "90%" }}
          size="small"
          className={classNames(classes.mui_label, classes.mui_fieldset)}
          onChange={(event) => {
            handelChange(event);
          }}
        />
        <TextField
          name="cid"
          label={__translate("my_cargos.advance_search.cid")}
          id="outlined-size-small"
          sx={{ margin: "7% ", width: "90%", textAlign: "right" }}
          size="small"
          anchor={"right"}
          className={classNames(classes.mui_label, classes.mui_fieldset)}
          onChange={(event) => {
            handelChange(event);
          }}
        />
      </fieldset>
      <Button
        className="primary__btn mt-4"
        variant="contained"
        endIcon={<FilterAltIcon />}
        onClick={() => {
          searchUserCargo();
        }}
      >
        {__translate("my_cargos.advance_search.search")}
      </Button>
    </div>
  );
}

export default Advance_search;
