import React, { useContext, useEffect, useState } from "react";
import WebCard from "./card";
import WebadvanceSearch from "./advance_search";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";

function WebCardList(props) {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex ">
        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
          <WebadvanceSearch />
        </div>
        <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 cardList__container">
          <WebCard isLoading={props.isLoading} data={props.data} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default WebCardList;
