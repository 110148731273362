import React from "react";
import { Breadcrumbs, Link, Stack } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
function BreadCrumbs(props) {
  return (
    <div className="MinaGenFont breadcrumbs__contierner w-75 mr-auto ml-auto">
      <Stack spacing={2}>
        <Breadcrumbs
          separator={
            localStorage.getItem("dir") === "ltr" ? (
              <NavigateNextIcon fontSize="small" />
            ) : (
              <ChevronLeftIcon fontSize="small" />
            )
          }
          aria-label="breadcrumb"
        >
          {props.crumpsDetails.map((ele, index) => {
            return (
              <Link
                underline="hover"
                key={index} //must to send the page in the right order
                color={ele.color ? ele.color : "gray"} //must be  1.for selected page use "black" 2.or unselected pages use "ButtonShadow"
                href={ele.href ? ele.href : "#"} //assign to needed route
              >
                {ele.titel ? ele.titel : "need to assign"}
              </Link>
            );
          })}
        </Breadcrumbs>
      </Stack>
    </div>
  );
}

export default BreadCrumbs;
