import Page404 from "../../assets/404Page.jpeg";
import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import { t as __translate } from "i18next";
function PageNotFound() {
  let navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img style={{ width: "100%", height: "35vh" }} src={Page404} alt="" />

      <button
        className="btn primary__btn my-4 mx-auto"
        onClick={() => {
          navigate("/");
        }}
      >
        {__translate("public.back_to_home_page")}
      </button>
    </div>
  );
}

export default PageNotFound;
