import React, { useContext, useEffect, useState } from "react";
import WebCardList from "./web/cardList";
import MobileCardList from "./mobile/cardList";
import axios from "../../../config/axios/axios";
import { UserContext } from "../../../useContext";
import { onMessageListener } from "../../../config/firebase";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { t as __translate } from "i18next";

import ClearingAgentList from "../../../pages/clearingAgent/clearingAgentList/container";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Container(props) {
  const [show, setShow] = useState(false);
  const [refrsh, setRefrsh] = useState(false);
  const context = useContext(UserContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    getContainerList();
    if (refrsh == true) {
      setRefrsh(false);
    }
  }, [context.advanceSearchResult, refrsh]);

  const getContainerList = () => {
    setIsLoading(true);
    let data = {};
    data.method = "searchContainerWaybillOrder";
    data.filter = context.advanceSearchResult;
    // data.limit = 7;
    axios
      .post("/publicAPI", data)
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {});
  };

  onMessageListener()
    .then((payload) => {
      if (payload.data.type == "refresh") {
        setShow(true); // use this for display the alert
        // window.location.reload();
      }
      // console.log("payload", payload);
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <div>
      {props.deviceType == "web" ? (
        <WebCardList isLoading={isLoading} data={data} />
      ) : (
        // <div>xsdgfsxgd</div>
        <Box sx={{ width: "100%", padding: "0" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              padding: "0",
              marginBottom: "1rem",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label={__translate("public.menu.home")}
                {...a11yProps(
                  localStorage.getItem("menuTemplet") == "B" ? 1 : 0
                )}
              />
              <Tab
                label={
                  localStorage.getItem("menuTemplet") == "B"
                    ? __translate("public.menu.requested_orders")
                    : __translate("public.menu.my_cargo")
                }
                {...a11yProps(
                  localStorage.getItem("menuTemplet") == "B" ? 0 : 1
                )}
              />
            </Tabs>
          </Box>
          <TabPanel
            value={value}
            index={localStorage.getItem("menuTemplet") == "B" ? 1 : 0}
          >
            <div className="cardList">
              <MobileCardList isLoading={isLoading} data={data} />
            </div>
          </TabPanel>
          <TabPanel
            value={value}
            index={localStorage.getItem("menuTemplet") == "B" ? 0 : 1}
          >
            {/* <div className="cardList"> */}
            <ClearingAgentList deviceType="mobile" />
            {/* </div> */}
          </TabPanel>
        </Box>
      )}

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={show}
          autoHideDuration={20000}
          onClose={() => {
            setShow(false);
          }}
        >
          <Alert
            severity="info"
            onClose={() => {
              setShow(false);
            }}
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            action={
              <Button
                variant="contained"
                sx={{ marginRight: "1rem " }}
                className="primary__btn"
                onClick={() => {
                  setRefrsh(true);
                  setShow(false);
                }}
              >
                {__translate("wo.details.refresh")}
              </Button>
            }
          >
            {__translate("wo.details.new_cargos")}
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
}

export default Container;
