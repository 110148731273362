import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.png";
import Login from "../../pages/login/login";
import { t } from "i18next";
import { UserContext } from "../../useContext";
function NavBar() {
  const navigate = useNavigate();
  const [openLoginModal, setOpenLoginModal] = useState(false);

  const context = useContext(UserContext);

  useEffect(() => {}, [context.render]);
  const closeModal = (flage) => {
    setOpenLoginModal(flage);
  };
  const changeDir = (lang) => {
    context.changeLang(lang);
  };
  return (
    <div>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light "
        style={{ zIndex: "1" }}
      >
        <img
          src={
            localStorage.getItem("userPhoto") != ""
              ? localStorage.getItem("userPhoto")
              : "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
          }
          style={{ objectFit: "cover" }}
          width="30"
          height="30"
          className="rounded-circle"
          alt=""
        />

        <div>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <img
            src={Logo}
            className="navbar-brand"
            width={"120px"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/");
            }}
            alt="MinaGate Logo"
          />
        </div>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <span className="nav-item dropdown" style={{ right: "85%" }}></span>
          <ul className="navbar-nav p-0 m-0">
            {/* <li className="nav-item active ">
              <span
                className="nav-link btn btn-light mb-2"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                style={{
                  cursor: "pointer",
                  border: "1px solid #ccc",
                  background: "#f1f1f1",
                }}
                onClick={() => {
                  navigate("/");
                }}
              >
                {t("public.bread_crumbs.home")}
              </span>
            </li>
            <li className="nav-item ">
              <span
                className="nav-link btn btn-light mb-2"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                style={{
                  cursor: "pointer",
                  border: "1px solid #ccc",
                  background: "#f1f1f1",
                }}
                onClick={() => {
                  navigate("/my_cargos");
                }}
              >
                {t("public.menu.my_cargo")}
              </span>
            </li> */}
            {/* 
            <li className="nav-item ">
              <span
                className="nav-link btn btn-light mb-2"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                style={{
                  cursor: "pointer",
                  border: "1px solid #ccc",
                  background: "#f1f1f1",
                }}
                onClick={() => {
                  navigate("/user_profile");
                }}
              >
                {t("public.menu.my_profile")}
              </span>
            </li>

            <li className="nav-item ">
              <span
                className="nav-link btn btn-light mb-2"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                style={{
                  cursor: "pointer",
                  border: "1px solid #ccc",
                  background: "#f1f1f1",
                }}
                onClick={() => {
                  navigate("/");
                }}
              >
                {t("public.menu.settings")}
              </span>
            </li>

            <li className="nav-item ">
              <span
                className="nav-link btn btn-light mb-2"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                style={{
                  cursor: "pointer",
                  border: "1px solid #ccc",
                  background: "#f1f1f1",
                }}
                onClick={() => {
                  navigate("/");
                }}
              >
                {t("public.menu.help")}
              </span>
            </li> */}

            <li className="nav-item">
              <select
                class="form-control p-1"
                onChange={(event) => {
                  changeDir(event.target.value);
                }}
              >
                <option
                  selected={
                    localStorage.getItem("lang") === "ar" ? true : false
                  }
                  value={"ar"}
                >
                  {t("public.menu.ar")}
                  &nbsp;&nbsp; 🇯🇴
                </option>
                <option
                  selected={
                    localStorage.getItem("lang") === "en" ? true : false
                  }
                  value={"en"}
                >
                  {t("public.menu.en")}
                  &nbsp;&nbsp; 🇺🇸
                </option>
              </select>
            </li>

            <div className="dropdown-divider"></div>
            <li className="nav-item ">
              {localStorage.getItem("isAuthenticated") === "true" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {localStorage.getItem("isAuthenticated") == "true" && (
                    <span className=" " style={{}}>
                      <div
                        className="nav-link  userProfileOptions"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="mx-2 fontX-small">
                          {/* <span className="mx-1">{t("public.menu.welcome")}</span> */}
                          {localStorage.getItem("userName") &&
                            localStorage.getItem("userName")}
                        </span>
                        <img
                          src={
                            localStorage.getItem("userPhoto") != "null"
                              ? localStorage.getItem("userPhoto")
                              : "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
                          }
                          style={{ objectFit: "cover" }}
                          width="25"
                          height="25"
                          className="rounded-circle"
                          alt=""
                        />
                      </div>
                    </span>
                  )}
                  <button className="btn btn-danger " onClick={()=>{context.signout()}}>
                    {" "}
                    {t("public.logout")}
                  </button>
                </div>
              ) : (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button
                    className="btn primary__btn"
                    onClick={() => {
                      setOpenLoginModal(true);
                    }}
                  >
                    {t("public.login")}
                  </button>
                </div>
              )}
            </li>
          </ul>
          {openLoginModal && (
            <Login closeModal={closeModal} publicPage={true} />
          )}
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
