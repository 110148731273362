import React, { useEffect, useContext, useState } from "react";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import TaskIcon from "@mui/icons-material/Task";
import AdvanceSearch from "./advance_search";
import DeliveryReport from "./deliveryReport";
import { makeStyles } from "@mui/styles";
import TuneIcon from "@mui/icons-material/Tune";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { t as __translate } from "i18next";
import { UserContext } from "../../../../useContext";
import { firebase, onMessageListener } from "../../../../config/firebase"

const useStyles = makeStyles((theme) => ({
  staticTooltipLabel: {
    textAlign: localStorage.getItem("dir") === "rtl" ? "right" : "left",
  },
}));
function DropBack() {
  const context = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [handelAdvanceSearch, setHandelAdvanceSearch] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [drawerToggler, setDrawerToggler] = useState(false);
  const classes = useStyles();
  const openAdvanceSearch = () => {
    setHandelAdvanceSearch(true);
    toggleDrawer(true);
    setOpen(false);
  };
  const openDeliveryReport = () => {
    context.setHandelDeliveryReport(true);
    toggleDrawer(true);
    setOpen(false);
  };
  const toggleDrawer = (toggler) => {
    setDrawerToggler(toggler);
  };


  onMessageListener()
    .then((payload) => {
      if (payload.notification.body == "delivery_report") {
        openDeliveryReport()
        console.log("ayload.notification.body", payload.notification.body);
      } else {
        console.log("outside if", payload.notification.body);
      }
      console.log("payload", payload);
    }).catch((err) => console.log("failed: ", err));

  return (
    <React.Fragment>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        sx={
          localStorage.getItem("dir") === "rtl"
            ? { position: "fixed", bottom: 16, left: 16 }
            : { position: "fixed", bottom: 16, right: 16 }
        }
        FabProps={{
          sx: {
            bgcolor: "#2f90b0",
            "&:hover": {
              bgcolor: "#369cbe",
            },
          },
        }}
        icon={<TuneIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        className={classes.speedDial}
      >
        <SpeedDialAction
          direction="right"
          tooltipTitle={__translate("my_cargos.advance_search.title")}
          icon={<FilterAltIcon />}
          tooltipOpen
          onClick={() => {
            openAdvanceSearch();
          }}
          classes={classes}
          TooltipClasses={classes}
          tooltipPlacement={
            localStorage.getItem("dir") === "rtl" ? "right" : "left"
          }
        />
        <SpeedDialAction
          direction="right"
          tooltipTitle={__translate("wo.advance_search.delivary_rebort")}
          icon={<TaskIcon />}
          tooltipOpen
          onClick={() => {
            openDeliveryReport();
          }}
          classes={classes}
          TooltipClasses={classes}
          tooltipPlacement={
            localStorage.getItem("dir") === "rtl" ? "right" : "left"
          }
        />
      </SpeedDial>
      {handelAdvanceSearch && (
        <AdvanceSearch
          anchor={"bottom"}
          drawerToggler={drawerToggler}
          toggleDrawer={toggleDrawer}
        />
      )}
      {context.handelDeliveryReport && (
        <DeliveryReport open={context.handelDeliveryReport} />
      )}
    </React.Fragment>
  );
}

export default DropBack;
