import React, { useContext, useEffect, useState } from "react";
import WebDetails from "./web/container";
import MobileDetails from "./mobile/container";
import axios from "../../../config/axios/axios";
import Alert from "react-s-alert";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../useContext";
import PageNotFound from "../../../components/pageNotFound/pageNotFound";

function Container(props) {
  const params = useParams();
  const navigate = useNavigate();
  const [details, setDetails] = useState(null);
  const [locations, setLocations] = useState(null);
  const [cities, setCities] = useState(null);
  const [noResult, setNoResult] = useState(false);
  const context = useContext(UserContext);
  useEffect(() => {
    if (context.checkLogIn()) {
      getWaybillOrderDetails();
    }
  }, []);
  const getWaybillOrderDetails = () => {
    // details
    axios
      .get(`/${params.flag_available ? "cargo" : "waybill_order"}`, {
        params: {
          method: `${
            params.flag_available
              ? "getLandTripPlattform"
              : "getContainerPlatformWaybillOrder"
          }`,
          waybill_order_id: params.flag_available ? null : params.id,
          id: params.flag_available ? params.id : null,
        },
      })
      .then((res) => {
        setDetails(res.data);
        setNoResult(false);
      })
      .catch((error) => {
        setNoResult(true);
        // navigate("/")
        // Alert.error(error);
      });
  };
  return (
    <div className=" mt-3 mb-3">
      {noResult ? (
        <PageNotFound />
      ) : (
        <React.Fragment>
          {context.renderLogin(
            "الرجاء تسجيل الدخول لتتمكن من مشاهدة تفاصيل الحمل"
          )}

          {props.deviceType == "web" ? (
            <WebDetails
              locations={locations}
              cities={cities}
              details={details}
            />
          ) : (
            <MobileDetails
              locations={locations}
              cities={cities}
              details={details}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default Container;
