import React, { useState, useEffect, useRef, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import { t as __translate } from "i18next";
import { UserContext } from "../../../../useContext";
import DropZone from "../../../../components/DropZone/Dropzone";
import { Button } from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { AppBar, Toolbar, Typography } from "@mui/material";
import axios from "../../../../config/axios/axios";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function DeliveryReport(props) {
  const context = useContext(UserContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [requierdImages, setRequiredImages] = useState({});
  const [notes, setNotes] = useState("");
  const [cargoImgUrl, setCargoImgUrl] = useState(null);
  const [cargoImgThimnaile, setCargoImgThimnaile] = useState(null);
  const [signatureImgUrl, setSignatureUrl] = useState(null);
  const [signatureImgThimnaile, setSignatureImgThimnaile] = useState(null);
  const [deleivaryReportData, setdeleivaryReportData] = useState(null);
  useEffect(() => {
    axios
      .get("/container", {
        params: {
          method: "checkActiveWaybillOrder",
        },
      })
      .then((res) => {
        setdeleivaryReportData(res.data);
      });
  }, []);

  const handleClose = () => {
    context.setHandelDeliveryReport(false);
    setCurrentPage(1);
    setRequiredImages({});
    setNotes("");
  };
  useEffect(() => {
console.log(context.handelDeliveryReport,"context.handelDeliveryReport")

  }, [context.handelDeliveryReport]);
  
  useEffect(() => {
    console.log(props.open,"props.openprops.open")
    
      }, [props.open]);
      

  const nextPage = () => {
    if (requierdImages[`page_${currentPage + 1}`]) {
      setCurrentPage(currentPage + 2);
    } else {
      setCurrentPage(currentPage + 1);
    }
  };
  const previousPage = () => {
    if (requierdImages[`page_${currentPage - 1}`]) {
      setCurrentPage(currentPage - 2);
    } else {
      setCurrentPage(currentPage - 1);
    }
  };
  const sendReport = () => {
    handleClose();
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={context.handelDeliveryReport}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          sx={{ backgroundColor: "#369cbe" }}
        >
          <Typography
            variant="h6"
            sx={{ color: "white" }}
            noWrap
            component="div"
          >
            {__translate("delivery_report.title")}
          </Typography>
        </Toolbar>
        {currentPage == 1 && (
          <>
            <Divider />
            <div
              className=" clearingAgentCard__titel "
              style={{ padding: "0 10%", margin: "10% 0", fontSize: "1rem" }}
            >
              {__translate("delivery_report.confiration")}{" "}
              {deleivaryReportData && deleivaryReportData.cid}
            </div>

            <div className=" clearingAgentCard__titel "  style={{ padding: "0 10%", margin: "3% 0", fontSize: "1rem" }}>
              {__translate("delivery_report.loading_time")} : 19/2/2023
            </div>
            <div className=" clearingAgentCard__titel "  style={{ padding: "0 10%", margin: "3% 0", fontSize: "1rem" }}>
              {__translate("delivery_report.arriving_time")} : 20/2/2023
            </div>
            <div className=" clearingAgentCard__titel "  style={{ padding: "0 10%", margin: "3% 0", fontSize: "1rem" }}>
              {__translate("delivery_report.discharge_time")} : 20/2/2023
            </div>

            <div
              style={{
                marginTop: "15%",
                display: "flex",
                // flexDirection: "column",
                justifyContent: "space-evenly",
                height: "10%",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  className="btn gray__btn mt-4"
                  sx={{ color: "#930202" }}
                  variant="contained"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  {__translate("delivery_report.no")}
                </Button>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  className="btn primary__btn mt-4"
                  variant="contained"
                  onClick={() => {
                    nextPage();
                  }}
                >
                  {__translate("delivery_report.yes")}
                </Button>
              </div>
            </div>
          </>
        )}
        {currentPage == 2 && (
          <>
            <Divider />
            <div
              style={{
                padding: "0 10%",
                margin: "10% 0",
                fontSize: "1.25rem",
                textAlign: "center",
              }}
            >
              {__translate("delivery_report.cargo_photo")}
            </div>
            <div
              style={{
                marginTop: "5%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                height: "50%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{ margin: "auto", width: "95%", marginTop: "1rem" }}
                >
                  <DropZone
                    height="200px"
                    width="100%"
                    label={__translate("delivery_report.upload_img")}
                    acceptedFiles="image/*"
                    originalFile={cargoImgUrl}
                    thumnail={cargoImgThimnaile}
                    imageChangehandler={(fileType, url) => {
                      if (fileType == "originalURL") {
                        setCargoImgUrl(url);
                      } else if (fileType == "thumnailURL") {
                        setCargoImgThimnaile(url);
                      }
                    }}
                  />
                </div>
              </div>

              <div
                className="mt-4"
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="btn gray__btn mt-4"
                    sx={{ color: "#930202" }}
                    variant="contained"
                    onClick={() => {
                      previousPage();
                    }}
                  >
                    {__translate("delivery_report.back")}
                  </Button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="btn primary__btn mt-4"
                    variant="contained"
                    onClick={() => {
                      nextPage();
                    }}
                    disabled={!cargoImgUrl}
                  >
                    {__translate("delivery_report.next")}
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}

        {currentPage == 3 && (
          <>
            <Divider />
            <div
              style={{
                padding: "0 10%",
                margin: "10% 0",
                fontSize: "1.25rem",
                textAlign: "center",
              }}
            >
              {__translate("delivery_report.owner_signature")}
            </div>
            <div
              style={{
                marginTop: "5%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                height: "50%",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{ margin: "auto", width: "95%", marginTop: "1rem" }}
                >
                  <DropZone
                    height="200px"
                    width="100%"
                    label={__translate("delivery_report.upload_img")}
                    acceptedFiles="image/*"
                    originalFile={signatureImgUrl}
                    thumnail={signatureImgThimnaile}
                    imageChangehandler={(fileType, url) => {
                      if (fileType == "originalURL") {
                        setSignatureUrl(url);
                      } else if (fileType == "thumnailURL") {
                        setSignatureImgThimnaile(url);
                      }
                    }}
                  />
                </div>
              </div>
              <div
                className="mt-4"
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="btn gray__btn mt-4"
                    sx={{ color: "#930202" }}
                    variant="contained"
                    onClick={() => {
                      previousPage();
                    }}
                  >
                    {__translate("delivery_report.back")}
                  </Button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    className="btn primary__btn mt-4"
                    variant="contained"
                    onClick={() => {
                      nextPage();
                    }}
                    disabled={!signatureImgUrl}
                  >
                    {__translate("delivery_report.next")}
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
        {currentPage == 4 && (
          <>
            <Divider />
            <div
              style={{
                padding: "0 10%",
                margin: "10% 0",
                fontSize: "1.25rem",
                textAlign: "center",
              }}
            >
              {__translate("delivery_report.notes")}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: "50%",
              }}
            >
              <textarea
                value={notes}
                style={{ width: "75%", resize: "none" }}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
              />
            </div>
            <div
              className="mt-4"
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  className="btn gray__btn mt-4"
                  sx={{ color: "#930202" }}
                  variant="contained"
                  onClick={() => {
                    previousPage();
                  }}
                >
                  {__translate("delivery_report.back")}
                </Button>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  className="btn primary__btn mt-4"
                  variant="contained"
                  onClick={sendReport}
                >
                  {__translate("delivery_report.send_report")}
                </Button>
              </div>
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
}
