import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './Router';
import Context from './useContext'


//  init the firebase
// var firebase = require('firebase');



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Context>
    <React.StrictMode>
      <Router />
    </React.StrictMode>
  </Context>
);

