import React, { useEffect, useState } from "react";
import { ReactComponent as Trailer } from "../../assets/Trailer.svg";
import { ReactComponent as Tank } from "../../assets/FuelTruck.svg";
import { ReactComponent as Container } from "../../assets/Truck.svg";
import { ReactComponent as OpenTop } from "../../assets/openTop.svg";
import { ReactComponent as CheckList } from "../../assets/checklist.svg";
import { ReactComponent as Weight } from "../../assets/Weight.svg";
import { ReactComponent as ContainerSize } from "../../assets/container.svg";
import { t as __translate } from "i18next";
function Tags(props) {
  const [tagItems, setTagItems] = useState(props.tagItems);
  return (
    <span className=" d-flex " style={{ flexWrap: "wrap" }}>
      <>
        {tagItems.map((ele) => {
          if (ele.key == "reefer") {
            return (
              <span className="d-flex tag tag__contiener--refrigerator">
                <span className="fa-solid fa-snowflake  mx-1 tag__label--refrigerator"></span>
                <span className="tag__label tag__label--refrigerator">
                  {__translate("wo.tags.refirigerator")}
                </span>
              </span>
            );
          }
          if (ele.key == "tank") {
            return (
              <span className="d-flex tag tag__contiener--tankTag">
                <span className="tag__label--tankTag tag__label--withSvg">
                  <Tank />
                </span>
                <span className="tag__label tag__label--tankTag">
                  {__translate("wo.tags." + ele.label)}
                </span>
              </span>
            );
          }
          if (ele.key == "flat") {
            return (
              <span className=" d-flex tag tag__contiener--trailer">
                <span className="tag__label--withSvg  tag__label--trailer">
                  <Trailer />
                </span>
                <span className="tag__label tag__label--trailer">
                  {__translate("wo.tags." + ele.label)}
                </span>
              </span>
            );
          }

          if (ele.key == "general") {
            return (
              <span className="d-flex tag tag__contiener--container">
                <span className="tag__label--withSvg  tag__label--container">
                  <Container />
                </span>
                <span className="tag__label tag__label--container">
                  {__translate("wo.tags." + ele.label)}
                </span>
              </span>
            );
          }
          if (ele.key == "open_top") {
            return (
              <span className="d-flex tag tag__contiener--openTop">
                <span className="tag__label--withSvg  tag__label--openTop">
                  <OpenTop />
                </span>
                <span className="tag__label tag__label--openTop">
                  {__translate("wo.tags." + ele.label)}
                </span>
              </span>
            );
          }

          if (ele.key == "with_inspection") {
            return (
              <span className="d-flex tag tag__contiener--withInspiction">
                <span className="tag__label--withSvg tag__label--withInspiction">
                  <CheckList />
                </span>
                <span className="tag__label tag__label--withInspiction">
                  {__translate("wo.tags." + ele.label)}
                </span>
              </span>
            );
          }

          if (ele.key === "20") {
            return (
              <span className="d-flex tag tag__contiener--size">
                <span className="tag__label--with20FSvg tag__label--size">
                  <ContainerSize />
                </span>
                <span className="tag__label tag__label--size">
                  {__translate("wo.tags." + ele.label + "_feet")}
                </span>
              </span>
            );
          }

          if (ele.key === "40") {
            return (
              <span className="d-flex tag tag__contiener--size">
                <span className="tag__label--with40FSvg tag__label--size">
                  <ContainerSize />
                </span>
                <span className="tag__label tag__label--size">
                  {__translate("wo.tags." + ele.label + "_feet")}
                </span>
              </span>
            );
          }

          if (ele.key === "45") {
            return (
              <span className="d-flex tag tag__contiener--size">
                <span className="tag__label--with40FSvg tag__label--size">
                  <ContainerSize />
                </span>
                <span className="tag__label tag__label--size">
                  {__translate("wo.tags." + ele.label + "_feet")}
                </span>
              </span>
            );
          }

          if (ele.key == "empty_return") {
            return (
              <span className="d-flex tag tag__contiener--withReturning">
                <span className="fa-solid fa-right-left mx-1 tag__label--withReturning"></span>
                <span className="tag__label tag__label--withReturning">
                  {__translate("wo.tags." + ele.label)}
                </span>
              </span>
            );
          }

          if (ele.key == "weight") {
            return (
              <span className="d-flex tag tag__contiener--weight">
                {/* <span className="tag__label--withSvg  ">
                  <Weight />
                </span> */}
                <span className="tag__label tag__label--weihght ">
                  {ele.label} {__translate("wo.tags.ton")}
                </span>
              </span>
            );
          }
        })}
      </>
    </span>
  );
}

export default Tags;
