import { Alert, Snackbar } from '@mui/material';
import axios from 'axios';
import React, { useContext } from 'react';
import { UserContext } from '../../useContext';

import { config } from "../config"



import { getAuth, signOut } from "firebase/auth";
// import firebase from "firebase/compat/app";
import { firebaseApp } from "../firebase"


const auth = getAuth();

const instance = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'SameSite': 'None',


  },
  mode: 'no-cors',
  baseURL: config.backEndURL,
  timeout: 600000000,
  responseType: 'json',
  validateStatus: function (status) {
    return status < 500;
  },
  withCredentials: true

});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  // response.cookie('token', localStorage.getItem("integration_token"), { sameSite: "None" })

  return response;
}, function (error) {

  if (error.response.data.CODE === "No Session!") {


    // logout from google 
    signOut(auth)
      .then(() => {
        var data = {};
        data.method = "logoutUser";
        data.clearWebNotification = true;
        axios
          .post("/login", data)
          .then(function (response) {
            firebaseApp.auth().signOut();
          })
          .catch((err) => { });
      })


    // logout from fron end
    localStorage.removeItem("userName")
    localStorage.removeItem("userPhoto")
    localStorage.removeItem("selected_company_id")
    localStorage.setItem("isAuthenticated", false)
    localStorage.setItem("integration_token", "")
    window.location.href = config.fronEndURL
  }


  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});


instance.interceptors.request.use(function (config) {

  if (config && config.data && localStorage.getItem("integration_token")) {

    config.data["integration_token"] = localStorage.getItem("integration_token")
    config.data["selected_company_id"] = localStorage.getItem("selected_company_id")
  }
  return config;

}, function (error) {
  return Promise.reject(error);
});



export default instance;