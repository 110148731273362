import React, { useEffect, useState, useContext } from "react";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { onAuthStateChanged } from "firebase/auth";
import axios from "../../config/axios/axios";
import {
  BrowserRouter as Router,
  json,
  Link,
  useNavigate,
} from "react-router-dom";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import HomeIcon from "@mui/icons-material/Home";
import Typography from "@mui/material/Typography";
import {
  firebaseApp,
  auth,
  onMessageListener,
  myGetToken,
} from "../../config/firebase";
import { UserContext } from "../../useContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: " 1rem" }}
      {...other}
      className="MinaGenFont fontSmall"
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          // sx={{
          //   position: "absolute",
          //   right: 8,
          //   top: 8,
          //   color: (theme) => theme.palette.grey[500],
          // }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
let login_inprogress = false;
function Login(props) {
  const context = useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const [token, setToken] = useState(null);
  // const [login_inprogress, setLogin_inprogress] = useState(false);
  const navigate = useNavigate();

  // myGetToken(setToken);
  onMessageListener()
    .then((payload) => {
      if (payload.data.type == "refresh") {
        // window.location.reload();
      }
      console.log("this is in login", payload);
      console.log("this is my token", token);
    })
    .catch((err) => console.log("failed: ", err));

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    if (props.publicPage) {
      props.closeModal(false);
    }
  };

  const handleCloseInPublicPage = () => {
    if (props.publicPage) {
      props.closeModal(false);
    }
  };

  useEffect(() => {
    console.log("here set token");
    let tt = myGetToken(setToken);
    console.log(tt, "tt");
    onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken(true).then(function (idToken) {
          if (login_inprogress == false) {
            login_inprogress = true;
            setTimeout(() => {
              login_inprogress = false;
            }, 10000);
            getUserProfile(idToken, null);
          }
        });
      }
      const ui =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(firebase.auth());
      handleClickOpen();
      ui.start(".firebase-auth-container", {
        signInFlow: "popup",
        signInOptions: [
          {
            provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
            recaptchaParameters: {
              type: "image", // 'audio'
              size: "invisible", // 'invisible' or 'compact'
              badge: "bottomright", //' bottomright' or 'inline' applies to invisible.
            },
            defaultCountry: "JO",
            defaultNationalNumber: "",
            loginHint: "",
          },
        ],
        signInSuccessUrl: "/details",
        privacyPolicyUrl: "<your-url>",
      });
    });
  });

  const getUserProfile = (idToken, refresh_token) => {
    return new Promise(function (resolve, reject) {
      axios
        .get("/user", {
          params: {
            method: "getUserProfile",
            idtoken: idToken,
            refresh_token: refresh_token,
            // uuid: store.getState().wsManager.UUID
          },
        })
        .then((response) => {
          // alert("getUserProfile");

          let selected_company_id =
            response.data.company_profiles &&
            response.data.company_profiles[1] &&
            response.data.company_profiles[1].company_id;

          console.log("selected_company_id", selected_company_id);
          if (selected_company_id) {
            localStorage.setItem(
              "userName",
              response.data.company_profiles[1].name.substring(0, 45)
            );
            localStorage.setItem(
              "userPhoto",
              response.data.company_profiles[1]
                ? response.data.company_profiles[1].avatar
                : null
            );
            localStorage.setItem("selected_company_id", selected_company_id);

            axios
              .get("/login", {
                params: {
                  method: "switchProfile",
                  company_id: selected_company_id,
                },
              })
              .then((res) => {
                localStorage.setItem("menuTemplet", "B");
                axios
                  .get("/login", {
                    params: {
                      method: "switchProfile",
                      company_id: selected_company_id,
                    },
                  })
                  .then((res) => {
                    context.setSwitchProfile(true);
                    setTimeout(() => {
                      context.setSystemReady(true);
                    }, 200);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            localStorage.setItem(
              "userName",
              JSON.parse(response.data.USER_BEAN).name.split(" ")[0]
            );
            localStorage.setItem(
              "userPhoto",
              JSON.parse(response.data.USER_BEAN).photos.length > 0
                ? JSON.parse(response.data.USER_BEAN).photos[0].url
                : null
            );
          }

          if (window.matchMedia("(display-mode: standalone)").matches) {
            console.log("---------------This is running as PWA.");
            axios
              .get("/login", {
                params: {
                  method: "keep_me_logged",
                },
              })
              .then((res) => {
                console.log("---------------This is running as PWA.", res.data);
                localStorage.setItem("integration_token", res.data);
              });
          }
          console.log(token, "tokenv@2.2");

          if (localStorage.getItem("web_notif")) {
            axios
              .get("/login", {
                params: {
                  method: "saveWebNotificationToken",
                  notification_token: localStorage.getItem("web_notif"),
                },
              })
              .then((res) => {
                // alert("saveWebNotificationToken");
                console.log(
                  "This is my notification token and i send it to backend :<) ",
                  res
                );
                // setLogin_inprogress(false);
              });
          } else {
            
          }

          localStorage.setItem("isAuthenticated", true);
          context.setRender(true);
          handleClose();
          if (!props.publicPage) {
            props.onSuccess();
          }
          resolve("DONE");
          // window.location.reload();
        })
        .catch((err) => {
          console.log("err", err);
          firebase.auth().signOut();
          localStorage.setItem("isAuthenticated", false);
          reject(err);
        });
    });
  };
  return (
    <>
      <div>
        <BootstrapDialog
          onClose={handleCloseInPublicPage}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            sx={{ padding: "1rem", textAlign: "right" }}
          >
            <span className="fontSmall" style={{}}>
              {props.titel}
            </span>
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <div className={"firebase-auth-container"}></div>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Stack direction="row" spacing={1}>
              <Chip
                icon={<HomeIcon />}
                label="العودة للرئيسية"
                onClick={() => {
                  handleCloseInPublicPage();
                  navigate("/");
                }}
                variant="outlined"
                sx={{ padding: "0 1rem" }}
              />
            </Stack>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
}

export default Login;
