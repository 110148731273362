import React, { useEffect } from "react";
import Tags from "../../../../components/tags/tags";
import { useNavigate } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import MobileSckelton from "../../../../components/sckeltonLoadingIndecator/mobileSckeleton";
import { t as __translate } from "i18next";
import { Button } from "@mui/material";

let arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
function ProductCard(props) {
  const navigate = useNavigate();
  useEffect(() => {}, [props.data]);
  return (
    <>
      {props.isLoading &&
        arr.map((ele) => {
          return <MobileSckelton />;
        })}
      {props.data.length > 0
        ? props.data.map((ele) => (
            <LazyLoad>
              <div
                className=" card"
                onClick={() => {
                  ele.flag_available
                    ? navigate(
                        `/my_cargos/details/${ele.id}/${ele.flag_available}`
                      )
                    : navigate(`/my_cargos/details/${ele.id}`);
                }}
              >
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex align-items-center p-0">
                  <h4 className="clearingAgentCard__titel w-75">{ele.cid}</h4>
                  <div className="w-45 p-0">
                    {ele.flag_available ? (
                      <span className="d-flex tag tag__contiener--landTripContainer">
                        <span className="tag__label--tankTag d-flex">
                          <i class="fa-solid fa-box mx-2"></i>
                          {__translate("my_cargos.land_trip_card.container")}
                        </span>
                        <span className="tag__label--tankTag">{ele.label}</span>
                      </span>
                    ) : (
                      <span className="d-flex tag tag__contiener--waybill_order">
                        <span className=" d-flex">
                          <i class="fa-solid fa-signs-post mx-2"></i>
                          {__translate(
                            "my_cargos.land_trip_card.waybill_order"
                          )}
                        </span>
                        <span className="">{ele.label}</span>
                      </span>
                    )}
                  </div>
                </div>

                <div className="card__tags">
                  <Tags tagItems={ele.tags}></Tags>
                </div>
                <div className="col-md-12  col-lg-12  col-sm-12  col-xs-12 clearingAgentCard__subTitle">
                  <h6>
                    {" "}
                    {__translate("my_cargos.land_trip_card.create_date")}:{" "}
                    {ele.create_date ? ele.create_date.split(" ")[0] : "--"}
                  </h6>
                </div>
                <div className=" clearingAgentCard__subTitle flex-column">
                  <h6>
                    {__translate("my_cargos.land_trip_card.bol")}: {ele.bl}
                  </h6>
                  <h6 className="mb-3">
                    {__translate("my_cargos.land_trip_card.declaration")}:{" "}
                    {ele.declaration_number}
                  </h6>
                </div>

                {ele.description && (
                  <div className="card__details">
                    <span>
                      {ele.description
                        ? ele.description.substring(0, 100)
                        : null}
                      {ele.description ? (
                        <a
                          href={
                            ele.flag_available
                              ? `/my_cargos/details/${ele.id}/${ele.flag_available}`
                              : `/my_cargos/details/${ele.id}`
                          }
                        >
                          ...{" "}
                          {__translate("my_cargos.land_trip_card.view_more")}
                        </a>
                      ) : null}
                    </span>
                  </div>
                )}
              </div>
            </LazyLoad>
          ))
        : props.data.length == 0 && (
            <div className="noCargosFound__contiener">
              <div className="d-flex justify-content-center  flex-column col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                  {__translate("my_cargos.land_trip_my_cargos.no_cargo_found")}
                </div>
                <br />
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                  <Button
                    className="primary__btn"
                    variant="contained"
                    // endIcon={<FilterAltIcon />}
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    {__translate("my_cargos.land_trip_my_cargos.press_here")}
                  </Button>
                </div>
              </div>
            </div>
          )}
    </>
  );
}

export default ProductCard;
