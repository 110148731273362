import React from "react";
import WebNavBar from "./WebNavBar";
import MobileNavBar from "./MobileNavBar";
function Navbar(props) {
  return (
    <div>{props.deviceType == "web" ? <WebNavBar /> : <MobileNavBar />}</div>
  );
}

export default Navbar;
