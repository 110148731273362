import React from 'react';
import PropTypes from 'prop-types';
// import Button from 'react-bootstrap/lib/Button';
// import Modal from 'react-bootstrap/lib/Modal';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Divider } from '@mui/material';
import { Box } from '@mui/system';
class Confirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpened: props.visible
        };
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onConfirm = this.onConfirm.bind(this);
    }



    onButtonClick() {
        // Since the modal is inside the button click events will propagate up.
        if (!this.state.isOpened) {
            this.setState({
                isOpened: true
            });
        }
    }

    onClose(event) {
        if (event) {
            event.stopPropagation();
        }
        this.setState({
            isOpened: false
        });

        if (typeof this.props.onClose === 'function') {
            this.props.onClose();
        }
    }

    onConfirm(event) {
        event.stopPropagation();
        this.setState({
            isOpened: false
        });
        this.props.onConfirm();
    }

    render() {
        var cancelButton = this.props.showCancelButton ? (

            <Button bsStyle="default" onClick={this.onClose}>
                {this.props.cancelText}
            </Button>
        ) : null;
        var modal = (
            <Dialog
                open={this.state.isOpened}
                // TransitionComponent={Transition}
                keepMounted
                onClose={this.onClose}
                aria-describedby="alert-dialog-slide-description"
            >

                <React.Fragment>
                    <DialogTitle
                        sx={
                            localStorage.getItem("dir") === "rtl"
                                ? { textAlign: "right" }
                                : { textAlign: "left" }
                        }
                    >
                        {this.props.title}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Box sx={{ m: 1, position: "relative" }}>
                                    {this.props.body}
                                </Box>
                            </Box>
                        </DialogContentText>

                        <Divider />
                        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>

                            <Button onClick={this.onConfirm}>
                                {this.props.confirmText}
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </React.Fragment>

            </Dialog>












            // <Modal show={this.state.isOpened} onHide={this.onClose} >
            //     <Modal.Header>
            //         <Modal.Title>{this.props.title}</Modal.Title>
            //     </Modal.Header>
            //     <Modal.Body>{this.props.body}</Modal.Body>
            //     <Modal.Footer>

            //         <Button bsStyle={this.props.confirmBSStyle} onClick={this.onConfirm}>
            //             {this.props.confirmText}
            //         </Button>
            //         <label style={{ paddingLeft: 20 }}> </label>
            //         {cancelButton}
            //     </Modal.Footer>
            // </Modal>
        );
        var content;
        if (this.props.children) {
            var btn = React.Children.only(this.props.children);
            content = React.cloneElement(
                btn,
                {
                    onClick: this.onButtonClick,
                    style: this.props.style
                },
                btn.props.children,
                modal
            );
        } else {
            content = (
                <Button onClick={this.onButtonClick} style={this.props.style}>
                    {this.props.buttonText}
                    {modal}
                </Button>
            );
        }
        return content;
    }
}

Confirm.propTypes = {
    body: PropTypes.node.isRequired,
    buttonText: PropTypes.node,
    cancelText: PropTypes.node,
    confirmBSStyle: PropTypes.string,
    confirmText: PropTypes.node,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    showCancelButton: PropTypes.bool.isRequired,
    title: PropTypes.node.isRequired,
    visible: PropTypes.bool
};

Confirm.defaultProps = {
    cancelText: 'Cancel',
    confirmText: 'Confirm',
    confirmBSStyle: 'danger',
    showCancelButton: true
};

export { Confirm };
export default Confirm;