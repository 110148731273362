import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Tags from "../../../../components/tags/tags";
import axios from "../../../../config/axios/axios";
import { Alert, Divider, Stack } from "@mui/material";
import WebSckeleton from "../../../../components/sckeltonLoadingIndecator/webDetailsSckeleton";
import { UserContext } from "../../../../useContext";
import Carousel from "react-material-ui-carousel";
import { t as __translate } from "i18next";
import BreadCrumbs from "../../../../components/breadCrumbs/breadCrumbs";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { green, red } from "@mui/material/colors";
import BackBtn from "../../../../components/backBtn/backBtn";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "react-select";
export default function DetailsCardMobile(props) {
  // let data1 = [
  //   [
  //     {
  //       productTitel: "ECMU9408053",
  //       productDiscription: "العاب مختلفة حسب الفاتورة المرفقة.",
  //       productRating: 3.6,
  //       noOfVoting: "100",

  //       productFuturs: [
  //         { label: "سطحة", icon: "fa-solid fa-snowflake", key: "trailer" },
  //         { label: "20قدم", icon: "fa-solid fa-box", key: "20F" },
  //         {
  //           label: "مع معاينة",
  //           icon: "fa-solid fa-ruler",
  //           key: "with_inspiction",
  //         },
  //         {
  //           label: "مع عودة ",
  //           icon: "fa-solid fa-map-pin",
  //           key: "with_returning",
  //         },
  //       ],
  //     },
  //   ],
  //   [
  //     {
  //       productTitel: "ECMU9408053",
  //       productDiscription: "العاب مختلفة حسب الفاتورة المرفقة.",
  //       productRating: 3.6,
  //       noOfVoting: "100",

  //       productFuturs: [
  //         { label: "سطحة", icon: "fa-solid fa-snowflake", key: "trailer" },
  //         { label: "20قدم", icon: "fa-solid fa-box", key: "20F" },
  //         {
  //           label: "مع معاينة",
  //           icon: "fa-solid fa-ruler",
  //           key: "with_inspiction",
  //         },
  //         {
  //           label: "مع عودة ",
  //           icon: "fa-solid fa-map-pin",
  //           key: "with_returning",
  //         },
  //       ],
  //     },
  //   ],
  //   [
  //     {
  //       productTitel: "ECMU9408053",
  //       productDiscription: "العاب مختلفة حسب الفاتورة المرفقة.",
  //       productRating: 3.6,
  //       noOfVoting: "100",

  //       productFuturs: [
  //         { label: "سطحة", icon: "fa-solid fa-snowflake", key: "trailer" },
  //         { label: "20قدم", icon: "fa-solid fa-box", key: "20F" },
  //         {
  //           label: "مع معاينة",
  //           icon: "fa-solid fa-ruler",
  //           key: "with_inspiction",
  //         },
  //         {
  //           label: "مع عودة ",
  //           icon: "fa-solid fa-map-pin",
  //           key: "with_returning",
  //         },
  //       ],
  //     },
  //   ],
  //   [
  //     {
  //       productTitel: "ECMU9408053",
  //       productDiscription: "العاب مختلفة حسب الفاتورة المرفقة.",
  //       productRating: 3.6,
  //       noOfVoting: "100",

  //       productFuturs: [
  //         { label: "سطحة", icon: "fa-solid fa-snowflake", key: "trailer" },
  //         { label: "20قدم", icon: "fa-solid fa-box", key: "20F" },
  //         {
  //           label: "مع معاينة",
  //           icon: "fa-solid fa-ruler",
  //           key: "with_inspiction",
  //         },
  //         {
  //           label: "مع عودة ",
  //           icon: "fa-solid fa-map-pin",
  //           key: "with_returning",
  //         },
  //       ],
  //     },
  //   ],
  //   [
  //     {
  //       productTitel: "ECMU9408053",
  //       productDiscription: "العاب مختلفة حسب الفاتورة المرفقة.",
  //       productRating: 3.6,
  //       noOfVoting: "100",

  //       productFuturs: [
  //         { label: "سطحة", icon: "fa-solid fa-snowflake", key: "trailer" },
  //         { label: "20قدم", icon: "fa-solid fa-box", key: "20F" },
  //         {
  //           label: "مع معاينة",
  //           icon: "fa-solid fa-ruler",
  //           key: "with_inspiction",
  //         },
  //         {
  //           label: "مع عودة ",
  //           icon: "fa-solid fa-map-pin",
  //           key: "with_returning",
  //         },
  //       ],
  //     },
  //   ],
  //   [
  //     {
  //       productTitel: "ECMU9408053",
  //       productDiscription: "العاب مختلفة حسب الفاتورة المرفقة.",
  //       productRating: 3.6,
  //       noOfVoting: "100",

  //       productFuturs: [
  //         { label: "سطحة", icon: "fa-solid fa-snowflake", key: "trailer" },
  //         { label: "20قدم", icon: "fa-solid fa-box", key: "20F" },
  //         {
  //           label: "مع معاينة",
  //           icon: "fa-solid fa-ruler",
  //           key: "with_inspiction",
  //         },
  //         {
  //           label: "مع عودة ",
  //           icon: "fa-solid fa-map-pin",
  //           key: "with_returning",
  //         },
  //       ],
  //     },
  //   ],
  // ];

  // var items = [
  //   {
  //     name: "Random Name #1",
  //     description: "Probably the most random thing you have ever seen!",
  //   },
  //   {
  //     name: "Random Name #2",
  //     description: "Hello World!",
  //   },
  //   {
  //     name: "Random Name #2",
  //     description: "Hello World!",
  //   },
  // ];

  const params = useParams();
  const context = useContext(UserContext);
  const [data, setData] = useState(null);
  // const [data1, setdata1] = useState([]);
  const [detailsIsLoading, setDetailsIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmBtnText, setConfirmBtnText] = useState(
    __translate("wo.details.confirmation_modal.reservation_confirmation")
  );
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [selectedTn, setSelectedTn] = useState(null);
  const [selectedTnNumber, setSelectedTnNumber] = useState(null);
  const [reserveErorrMsg, setReserveErorrMsg] = useState(null);

  const [confirmationChbox, setConfirmationChbox] = useState(false);
  const [tcCompanies, setTcCompanies] = useState([]);
  const [selectedTc, setSelectedTc] = useState(null);

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
    ...(fail && {
      bgcolor: red[500],
      "&:hover": {
        bgcolor: red[700],
      },
    }),
  };

  useEffect(() => {
    setDetailsIsLoading(true);
    searchTcCompanies();
    if (context.checkLogIn()) {
      getWaybillDetails();
    }
  }, []);
  const searchTcCompanies = () => {
    axios
      .get("/tender_company", {
        params: {
          method: "searchTenderCompanyForRafeef",
        },
      })
      .then((response) => {
        let data = response.data.data.map((element) => {
          return {
            label: element.name,
            value: element.id,
          };
        });
        setTcCompanies(data);
      });
  };

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      axios
        .get("/waybill_order", {
          params: {
            method: "pendWaybillOrder",
            waybill_order_id: params.id,
            queue_id: selectedTn,
            tc_id: selectedTc,
          },
        })
        .then((response) => {
          setFail(false);
          setSuccess(true);
          setLoading(false);
          setConfirmBtnText(
            __translate("wo.details.confirmation_modal.reservation_confirmed")
          );
          setTimeout(() => {
            navigate("/my_cargos");
          }, 1000);
        })
        .catch((err) => {
          setReserveErorrMsg(err.response.data.MESSAGE);
          setFail(true);
          setSuccess(false);
          setLoading(false);
          setConfirmBtnText(
            __translate("wo.details.confirmation_modal.reservation_failed")
          );
          console.log("error", err);
        });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setReserveErorrMsg(null);
    setOpen(false);
    setFail(false);
    setSuccess(false);
    setLoading(false);
    setConfirmBtnText(
      __translate("wo.details.confirmation_modal.reservation_confirmation")
    );
    setConfirmationChbox(false);

    setSelectedTc(null);
  };

  // const reserve = () => {
  //   axios
  //     .get("/waybill_order", {
  //       params: {
  //         method: "reserveNow",
  //         waybill_order_id: params.id,
  //       },
  //     })
  //     .then((response) => {})
  //     .catch((err) => {
  //       console.log("error", err);
  //     });
  // };

  const getWaybillDetails = () => {
    axios
      .get("/waybill_order", {
        params: {
          method: "getContainerPlatformWaybillOrder",
          waybill_order_id: params.id,
        },
      })
      .then((response) => {
        setData(response.data);
        setDetailsIsLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const navigate = useNavigate();

  return (
    <React.Fragment>
      {context.renderLogin(
        __translate("public.login_message.watch_the_cargo"),
        getWaybillDetails
      )}
      <BackBtn />
      {/* <BreadCrumbs
        crumpsDetails={[
          {
            href: "/",
            titel: __translate("public.bread_crumbs.home"),
          },
          {
            titel: __translate("public.bread_crumbs.wo_details"),
            color: "black",
          },
        ]}
      /> */}

      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center align-items-center  details">
        {data ? (
          <React.Fragment>
            <div className=" details__contiener">
              <div className="fontLarge description__titel">
                {__translate("wo.details.details")}
                {/* <div className="cardContiener__isWatching fontMedium">
                  <div className="cardContiener__cartTitel--glossyEffect"></div>
                  <i class="fa-solid fa-eye mediumIcon "></i>

                  <span className="fontsamll ">
                    50000 {__translate("wo.details.is_watching")}{" "}
                  </span>
                </div> */}
              </div>
              <Divider />
              <div className="detailsContiener__tags">
                <Tags tagItems={data.tags} />
              </div>

              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 details__description">
                <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 p-0">
                  <p className="details__description--paragraph">
                    {data.description}
                  </p>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex">
                    <i class="fa-solid fa-location-dot mx-2"></i>
                    <p className="">
                      {__translate("wo.location." + data.location_id)} /{" "}
                      {__translate("wo.sub_location." + data.location_sub_id)}
                      {data.location_free_text && (
                        <span></span>
                      )}
                    </p>
                  </div>
                </div>


                {data.price_message ?

                  <React.Fragment>
                    <div className="description__titel fontMedium">{__translate("my_cargos.land_trip_price.title")}</div>
                    <div className="fontLarge description__titel">{data.price_message}</div>
                  </React.Fragment>
                  :

                  <React.Fragment>
                    <div className="details__cardContiener ">
                      <div className=" cardContiener w-100">
                        <div className="cardContiener__cartTitel fontsmall">
                          <div className="cardContiener__cartTitel--glossyEffect"></div>
                          {__translate("wo.details.basic_price")}
                        </div>
                        <div className="cardContiener__cardBody fontsmall">
                          <span>
                            {data.base_price} {__translate("public.currency.jod")}
                          </span>
                        </div>
                      </div>

                      <div className="cardContiener w-100 my-3">
                        <div className="cardContiener__cartTitel fontsmall">
                          <div className="cardContiener__cartTitel--glossyEffect"></div>
                          {__translate("wo.details.inspection_price")}
                        </div>

                        <div className="cardContiener__cardBody fontsmall">
                          <span>
                            {data.inspection_delay}{" "}
                            {__translate("public.currency.jod")} /{" "}
                            {__translate("wo.details.per_day")}
                          </span>
                        </div>
                      </div>
                      <div className="cardContiener w-100">
                        <div className="cardContiener__cartTitel fontsmall">
                          <div className="cardContiener__cartTitel--glossyEffect"></div>
                          {__translate("wo.details.discharge_price")}
                        </div>

                        <div className="cardContiener__cardBody fontsmall">
                          <span>
                            {data.discharge_delay}{" "}
                            {__translate("public.currency.jod")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                }



                <div className=" d-flex justify-content-center row cardContiener__reservation">
                  {/* <div
                    className="cardContiener__isWatching cardContiener__isWatching--success  fontMedium"
                    onClick={() => {
                      handleClickOpen();
                    }}
                  >
                    <div className="cardContiener__cartTitel--glossyEffect"></div>
                    <span className="fontMedium">
                      {__translate("wo.details.reserve")}
                      <i class="fa-regular fa-square-check  mx-2"></i>
                    </span>
                  </div> */}
                  {data && data.trucks && data.trucks.length > 0 ? (
                    <div className="details__blate">
                      <div className="fontMedium description__titel  description__titel--blade">
                        {__translate("wo.details.truck_selection")}
                      </div>

                      <Divider sx={{ mb: "1rem" }} />
                      {data.trucks.map((ele, index) => {
                        return (
                          <div className=" d-flex justify-content-center mb-3 ">
                            <div
                              className="p-0 blate__contiener blate__contiener--active"
                              onClick={() => {
                                setSelectedTn(ele.q_id);
                                setSelectedTnNumber(ele.tn);
                                handleClickOpen();
                              }}
                            >
                              <div className="contry fontX-small">
                                {ele.q_id == selectedTn ? (
                                  <i className="fa-solid fa-circle-check largeIcon"></i>
                                ) : (
                                  <>
                                    <div className="contry__ar">الأردن</div>
                                    <div className="contry__en">jordan</div>
                                  </>
                                )}
                              </div>
                              <div className="blate__number blateNumber">
                                {ele.tn_dashed}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="fontMedium description__titel  description__titel--blade">
                      {__translate("wo.details.no_trucks")}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <Dialog
              open={open}
              // TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              {reserveErorrMsg ? (
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="error">{reserveErorrMsg}</Alert>
                      </Stack>
                    </Box>
                  </DialogContentText>
                  <Divider />
                  <DialogActions
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      variant="contained"
                      sx={{ backgroundColor: "#dc3545" }}
                      disabled={loading}
                      onClick={handleClose}
                    >
                      {__translate("wo.details.confirmation_modal.close")}
                    </Button>
                  </DialogActions>
                </DialogContent>
              ) : (
                <React.Fragment>
                  <DialogTitle
                    sx={{
                      textAlign:
                        localStorage.getItem("dir") == "rtl" ? "right" : "left",
                    }}
                  >
                    {__translate("wo.details.confirmation_modal.reserve")}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      <FormGroup>
                        <FormControlLabel
                          required
                          className="ReactSelect"
                          sx={
                            localStorage.getItem("rtl")
                              ? { textAlign: "right" }
                              : { textAlign: "left" }
                          }
                          control={<Checkbox />}
                          label={__translate(
                            "wo.details.confirmation_modal.reservation_confirmation_chbox"
                          )}
                          onChange={(event) => {
                            setConfirmationChbox(event.target.checked);
                          }}
                        />
                      </FormGroup>
                      <Select
                        options={tcCompanies}
                        value={selectedTc}
                        onChange={(event) => {
                          setSelectedTc(event.value);
                        }}
                        label={__translate(
                          "my_cargos.create_manual_lt.hazardous"
                        )}
                        size="small"
                        anchor={"right"}
                        placeholder={__translate(
                          "wo.details.confirmation_modal.tc_companies_placeholder"
                        )}
                        className="ReactSelect"
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box sx={{ m: 1, position: "relative" }}>
                          <Button
                            variant="contained"
                            sx={{
                              ...buttonSx,
                              width: "20vh",
                              height: "5vh",
                              display: "flex",
                              justifyContent: "space-evenly",
                            }}
                            disabled={
                              loading || !confirmationChbox || !selectedTc
                            }
                            onClick={handleButtonClick}
                          >
                            {success ? (
                              <i
                                class="fa-solid fa-check-double"
                                style={{
                                  fontSize: "1rem",
                                }}
                              ></i>
                            ) : fail ? (
                              <i
                                class="fa-solid fa-xmark"
                                style={{
                                  fontSize: "1rem",
                                }}
                              ></i>
                            ) : (
                              <i
                                class="fa-solid fa-check"
                                style={{
                                  fontSize: "1rem",
                                }}
                              ></i>
                            )}
                            {confirmBtnText}
                          </Button>
                          {loading && (
                            <CircularProgress
                              size={24}
                              sx={{
                                color: green[500],
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    </DialogContentText>
                    <Divider />
                    <DialogActions
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: "#dc3545" }}
                        disabled={loading}
                        onClick={handleClose}
                      >
                        {__translate("wo.details.confirmation_modal.close")}
                      </Button>
                    </DialogActions>
                  </DialogContent>
                </React.Fragment>
              )}
            </Dialog>
          </React.Fragment>
        ) : (
          <WebSckeleton />
        )}
      </div>
      {/* <div
        className="details"
        // style={{
        //   backgroundColor: "#e8e8e4",
        //   borderRadius: "15px",
        // }}
      >
        <div className="col-md-11 col-sm-11  mb-2 text-right h4 pt-3">
          حاويات مشابهة
        </div>
        <Carousel
          animation={"slide"}
          interval={4000}
          navButtonsAlwaysVisible={false}
          duration={200}
        >
          {data.map((item, i) => {
            return (
              <div className="col-md-12 col-sm-12  d-flex">
                <div className="MinaGenFont d-flex">
                  {item.map((ele, index) => (
                    <div>
                      <div className="col-md-12  ">
                        <div
                          className="row p-2 bg-white border rounded"
                          style={{
                            flexDirection: "row-reverse",
                            boxShadow: "3px 2px 5px 0px rgba(171,170,170,0.75)",
                            height: "100%",
                          }}
                        >
                          <div>
                            <div
                            // className="spec-1"
                            // style={{
                            //   display: "flex",
                            //   maxHeight: "12vh",
                            //   overflow: "hidden",
                            // }}
                            >
                              <Tags tagItems={ele.productFuturs}></Tags>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                textAlign: "right",
                                justifyContent: "start",
                                marginTop: "2rem",
                                height: "7vh",
                                maxWidth: "25rem",
                              }}
                            >
                              <span
                                style={{ height: "100%", overflow: "hidden" }}
                              >
                                {ele.productDiscription.substring(0, 200)}
                                <a href="#">... المزيد</a>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-12 mt-4 d-flex justify-content-center">
                            <button
                              className="btn btn-primary"
                              type="button"
                              style={{
                                fontSize: "2.5vh",
                                fontWeight: "700",
                                backgroundColor: "#337cca",
                              }}
                              onClick={() => {
                                navigate("/details");
                              }}
                            >
                              {__translate("wo.details.details")}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </Carousel>
      </div> */}
    </React.Fragment>
  );
}
