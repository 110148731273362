import { createContext, useContext, useEffect, useState } from "react";
import Login from "./pages/login/login";
import { getAuth, signOut } from "firebase/auth";
import firebase from "firebase/compat/app";
import axios from "./config/axios/axios";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import { config } from "./config/config";

export const UserContext = createContext()
function GlobalState(props) {
  // const navigate = useNavigate();

  const [advanceSearchResult, setAdvanceSearchResult] = useState({
    // typeGroup: { general: true },
    // lengthGroup: { 20: true }
  })
  const [refreshMyCargoList, setRefreshMyCargoList] = useState(false)
  const [render, setRender] = useState(false)
  const [switchProfile, setSwitchProfile] = useState(false)
  const [globalAleartTriger, setGlobalAleartTriger] = useState(false)
  const [getRafeefMenuItem, setGetRafeefMenuItem] = useState(null)
  const [systemReady, setSystemReady] = useState(true)
  const [handelDeliveryReport, setHandelDeliveryReport] = useState(false);
  const auth = getAuth();
  const handelAdvanceSearchRequset = (obj) => {
    setAdvanceSearchResult(obj)
  }


  const handelGlobalAleart = (trigger) => {
    setGlobalAleartTriger(trigger)
  }

  const checkLogIn = () => {
    if (localStorage.getItem("isAuthenticated") === "false") {
      setRender(false)
      return false
    } else {
      setRender(true)
      return true
    }
  };
  const renderLogin = (titel, onSuccess) => {
    if (checkLogIn() == false) {
      return (
        <Login titel={titel} onSuccess={onSuccess} />

      )
    } else return null
  }

  const initLocalStorage = () => {

    var dir
    var lang
    var userName
    var userPhoto
    var userRole
    var isAuthenticated
    var integration_token
    var menuTemplet

    if (localStorage.getItem("menuTemplet")) {
      menuTemplet = localStorage.getItem("menuTemplet")
    } else {
      menuTemplet = localStorage.setItem("menuTemplet", "A")
    }

    if (localStorage.getItem("dir")) {
      dir = localStorage.getItem("dir")
    } else {
      dir = "rtl"
    }

    if (localStorage.getItem("lang")) {
      lang = localStorage.getItem("lang")
    } else {
      lang = "ar"
    }

    if (localStorage.getItem("isAuthenticated")) {
      isAuthenticated = localStorage.getItem("isAuthenticated")
    } else {
      isAuthenticated = false
    }

    if (localStorage.getItem("userName")) {
      userName = localStorage.getItem("userName")
    } else {
      userName = ""
    }
    if (localStorage.getItem("userPhoto")) {
      userPhoto = localStorage.getItem("userPhoto")
    } else {
      userPhoto = ""
    }
    if (localStorage.getItem("integration_token")) {
      integration_token = localStorage.getItem("integration_token")
    } else {
      integration_token = ""
    }
    if (localStorage.getItem("userRole")) {
      userRole = localStorage.getItem("userRole")
    } else {
      userRole = ""
    }

    localStorage.setItem("menuTemplet", menuTemplet)
    localStorage.setItem("dir", dir)
    localStorage.setItem("lang", lang)
    localStorage.setItem("userName", userName)
    localStorage.setItem("userPhoto", userPhoto)
    localStorage.setItem("isAuthenticated", isAuthenticated)
    localStorage.setItem("integration_token", integration_token)
    localStorage.setItem("userRole", userRole)

  }


  const changeLang = (lang) => {
    if (lang == "ar") {
      localStorage.setItem("dir", "rtl")
      localStorage.setItem("lang", lang)
    }
    else if (lang == "en") {
      localStorage.setItem("dir", "ltr")
      localStorage.setItem("lang", lang)
    }
    window.location.reload();
  }


  const resetLocalStorage = () => {
    localStorage.removeItem("userName")
    localStorage.removeItem("userPhoto")
    localStorage.setItem("isAuthenticated", false)
    localStorage.setItem("integration_token", "")
    localStorage.setItem("menuTemplet", "A")
    localStorage.removeItem("selected_company_id")
    localStorage.removeItem("userRole")
  }


  const signout = () => {

    signOut(auth)
      .then(() => {
        localStorage.setItem("isAuthenticated", false);
        var data = {};
        data.method = "logoutUser";
        data.clearWebNotification = true;
        axios
          .post("/login", data)
          .then(function (response) {
            // navigate("/");
            firebase.auth().signOut();
            resetLocalStorage();
            setSwitchProfile(false);
            window.location.href = config.fronEndURL
            // window.location.reload();
          })
          .catch((err) => { });
      })
      .catch((error) => {
        // An error happened.
      });

  };


  const state = {
    checkLogIn: checkLogIn,
    renderLogin: renderLogin,
    initLocalStorage: initLocalStorage,
    changeLang: changeLang,
    resetLocalStorage: resetLocalStorage,
    handelAdvanceSearchRequset: handelAdvanceSearchRequset,
    advanceSearchResult: advanceSearchResult,
    render: render,
    setRender: setRender,
    setSwitchProfile: setSwitchProfile,
    switchProfile: switchProfile,
    handelGlobalAleart: handelGlobalAleart,
    globalAleartTriger: globalAleartTriger,
    setGetRafeefMenuItem,
    getRafeefMenuItem,
    setSystemReady,
    systemReady,
    signout,
    handelDeliveryReport,
    setHandelDeliveryReport,
    refreshMyCargoList,
    setRefreshMyCargoList

  }
  return (
    <UserContext.Provider value={state}>
      {props.children}
    </UserContext.Provider>
  );
}
export default GlobalState;