import React, { useContext, useEffect } from "react";
import Tags from "../../../../components/tags/tags";
import { useNavigate } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import MobileSckelton from "../../../../components/sckeltonLoadingIndecator/mobileSckeleton";
import { t as __translate } from "i18next";
import NoResultImg from "./no-result-data.png";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { UserContext } from "../../../../useContext";
let arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
function ProductCard(props) {
  const navigate = useNavigate();
  const context = useContext(UserContext);
  useEffect(() => {}, [props.data]);

  return (
    <div>
      {localStorage.getItem("isAuthenticated") === "false" && (
        <div className="addCargo__contiener">
          <div>{__translate("wo.having_cargo")}</div>
          <div>
            <button
              className="btn secondary__btn"
              onClick={() => {
                navigate("/my_cargos");
              }}
            >
              {__translate("wo.add_now")}
            </button>
          </div>
        </div>
      )}
      {!props.isLoading ? (
        props.data.length > 0 ? (
          props.data.map((ele) => (
            <LazyLoad>
              <div
                className=" card"
                onClick={() => {
                  navigate(`/details/${ele.id}`);
                }}
              >
                <div className="card__tags">
                  <Tags tagItems={ele.tags}></Tags>
                </div>

                {/* <div className="card__details">
                  <span>
                    {ele.details && ele.details.substring(0, 100)}
                    <a href="#">... {__translate("wo.list.view_more")}</a>
                  </span>
                </div> */}
                <div className="clearingAgent card__details">
                  {ele.description ? (
                    <span>
                      {ele.description.substring(0, 100)}
                      <span>...</span>
                      <span
                        className="card__showMore"
                        onClick={() => {
                          ele.flag_available
                            ? navigate(
                                `/my_cargos/details/${ele.id}/${ele.flag_available}`
                              )
                            : navigate(`/my_cargos/details/${ele.id}`);
                        }}
                      >
                        <i className="fa-solid fa-arrow-left smallIcon showMore__smallIcon--arrow ">
                          {" "}
                        </i>
                        <span className="ml-2">
                          {__translate("my_cargos.land_trip_card.view_more")}
                        </span>
                      </span>
                    </span>
                  ) : (
                    <span>{__translate("wo.card.noDetails")}</span>
                  )}

                  <div>
                    <i className="fa-solid fa-location-dot"></i>{" "}
                    {__translate("wo.location." + ele.location.location_id)}{" "}
                    {/* {ele.sub_location.sub_location_name ? "/" : null}
                    {__translate(
                      "wo.sub_location." + ele.sub_location.sub_location_name
                    )}
                    {"   "} */}
                    <span className="m-2">
                      <CalendarMonthIcon sx={{ fontSize: "1rem" }} />{" "}
                      {ele.create_date.split(" ")[0]}
                    </span>
                  </div>
                </div>

                {/* <div className="standerdTag d-flex flex-column mx-3">
                  <CalendarMonthIcon sx={{ fontSize: "1rem" }} />{" "}
                  {ele.create_date.split(" ")[0]}
                </div> */}
              </div>
            </LazyLoad>
          ))
        ) : (
          <div
            className="col-xs-12 col-sm-12 col-md-12 cpl-lg-12 d-flex justify-content-center  "
            style={{
              height: "90vh",
              maxWidth: "70%",
              display: "flex",
              flexDirection: "column",
              margin: "auto",
              // marginTop: "25%",
            }}
          >
            {Object.keys(context.advanceSearchResult).length ? (
              <React.Fragment>
                <img src={NoResultImg}></img>
                <div className=" my-3">
                  <p style={{ fontSize: "18px", textAlign: "center" }}>
                    {__translate("wo.search.result_found")}
                  </p>
                </div>
              </React.Fragment>
            ) : (
              <div className=" my-3">
                <p style={{ fontSize: "18px", textAlign: "center" }}>
                  {__translate("wo.card.noDetails")}
                </p>
              </div>
            )}
          </div>
        )
      ) : (
        arr.map(() => {
          return <MobileSckelton />;
        })
      )}
    </div>
  );
}

export default ProductCard;
