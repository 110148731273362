import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
function webDetailsSckeleton() {
  return (
    <div>
      <Card style={{ padding: "2rem", marginBottom: "2vh" }}>
        <Stack spacing={1}>
          {/* <div style={{ display: "flex" }}> */}
          <div style={{ width: "100%", height: "30vh" }}>
            <Skeleton
              variant="rectangular"
              style={{ width: "35%" }}
              height={40}
            />
            <div style={{ display: "flex" }}>
              <Skeleton
                variant="rounded"
                style={{ width: "12%", margin: "1rem" }}
                height={30}
              />
              <Skeleton
                variant="rounded"
                style={{ width: "12%", margin: "1rem" }}
                height={30}
              />
              <Skeleton
                variant="rounded"
                style={{ width: "12%", margin: "1rem" }}
                height={30}
              />
              <Skeleton
                variant="rounded"
                style={{ width: "12%", margin: "1rem" }}
                height={30}
              />
            </div>
            <Skeleton
              variant="rectangular"
              style={{ width: "90%" }}
              height={60}
            />
          </div>
          <div style={{ width: "3%" }}>
            <Skeleton
              variant="rectangular"
              style={{ height: "95%", width: "0.5rem" }}
            />
          </div>
          <div style={{ width: "22%", display: "flex", alignItems: "center" }}>
            <Skeleton
              variant="rectangular"
              style={{ height: "75%", width: "90%" }}
            />
          </div>
          {/* </div> */}
        </Stack>
      </Card>
    </div>
  );
}

export default webDetailsSckeleton;
