import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Slider from "@mui/material/Slider";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import "./mobileStyles.css";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { t as __translate } from "i18next";
import { UserContext } from "../../../../useContext";

export default function TemporaryDrawer(props) {
  const context = useContext(UserContext);
  const [value, setValue] = useState([0, 50]);
  const [firstValue, setFirstValue] = useState(value[0]);
  const [secondValue, setSecondValue] = useState(value[1]);
  const [bottom, setBottom] = useState(false);
  const [advanceSearchPreperation, setAdvanceSearchPreperation] = useState({});

  useEffect(() => {
    console.log(advanceSearchPreperation);
    setBottom({ bottom, [props.anchor]: props.drawerToggler });
    let obj = {};
    if (firstValue == 0 && secondValue == 50) {
      obj = { ...advanceSearchPreperation };
    } else {
      obj = {
        weight_min_point: firstValue.toString(),
        weight_max_point: secondValue.toString(),
        ...advanceSearchPreperation,
      };
    }
    Object.keys(obj).forEach((ele) => {
      if (ele == "weight_min_point" || ele == "weight_max_point") {
        return;
      }
      obj[ele] = Object.keys(obj[ele]);
    });
    context.handelAdvanceSearchRequset(obj);
  }, [advanceSearchPreperation, firstValue, secondValue, props.drawerToggler]);

  const handleChange = (event, flag) => {
    if (flag == "first") {
      if (
        event.target.value < 0 ||
        isNaN(Number(event.target.value)) ||
        event.target.value > 50
      ) {
        setFirstValue(firstValue);
        return;
      } else {
        setFirstValue(event.target.value);
      }
      setValue([event.target.value, value[1]]);
      return;
    }
    if (flag == "second") {
      if (
        event.target.value < 0 ||
        isNaN(Number(event.target.value)) ||
        event.target.value > 50
      ) {
        setSecondValue(secondValue);
        return;
      } else {
        setSecondValue(event.target.value);
      }
      setValue([value[0], event.target.value]);
      return;
    }
    setValue(event.target.value);
    setFirstValue(event.target.value[0]);
    setSecondValue(event.target.value[1]);
  };

  const handelOnChange = (event, groupName) => {
    setAdvanceSearchPreperation({
      ...advanceSearchPreperation,
      [groupName]: {
        ...advanceSearchPreperation[groupName],
        [event.target.name]: event.target.checked,
      },
    });
    if (!event.target.checked) {
      let obj = { ...advanceSearchPreperation };
      delete obj[groupName][event.target.name];
      if (Object.keys(obj[groupName]).length == 0) {
        delete obj[groupName];
      }
      setAdvanceSearchPreperation(obj);
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setBottom({ bottom, [anchor]: open });
    props.toggleDrawer(open);
  };
  const list = (anchor) => (
    <Box sx={{ width: "auto" }} role="presentation">
      <List>
        <ListItem>
          <ListItemIcon style={{ maxWidth: "100%" }}>
            <div className="advanceSearch">
              <fieldset className="advanceSearch__fieldset">
                <legend className="advanceSearch__legend">
                  {" "}
                  {__translate("wo.advance_search.by_type")}
                </legend>
                <FormGroup>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex p-0">
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-between p-0">
                      <FormControlLabel
                        name="general"
                        style={{ margin: "0", padding: "0" }}
                        control={
                          <Checkbox
                            checked={
                              advanceSearchPreperation.typeGroup &&
                              advanceSearchPreperation.typeGroup.general
                                ? true
                                : false
                            }
                          />
                        }
                        label={__translate("wo.advance_search.by_type.general")}
                        onChange={(event) => {
                          handelOnChange(event, "typeGroup");
                        }}
                      />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-between p-0">
                      <FormControlLabel
                        name="tank"
                        style={{ margin: "0", padding: "0" }}
                        control={
                          <Checkbox
                            checked={
                              advanceSearchPreperation.typeGroup &&
                              advanceSearchPreperation.typeGroup.tank
                                ? true
                                : false
                            }
                          />
                        }
                        label={__translate("wo.advance_search.by_type.tank")}
                        onChange={(event) => {
                          handelOnChange(event, "typeGroup");
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex p-0">
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-between p-0">
                      <FormControlLabel
                        name="refrigerator"
                        style={{ margin: "0", padding: "0" }}
                        control={
                          <Checkbox
                            checked={
                              advanceSearchPreperation.typeGroup &&
                              advanceSearchPreperation.typeGroup.refrigerator
                                ? true
                                : false
                            }
                          />
                        }
                        label={__translate(
                          "wo.advance_search.by_type.refrigerator"
                        )}
                        onChange={(event) => {
                          handelOnChange(event, "typeGroup");
                        }}
                      />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-between p-0">
                      <FormControlLabel
                        name="open_top"
                        style={{ margin: "0", padding: "0" }}
                        control={
                          <Checkbox
                            checked={
                              advanceSearchPreperation.typeGroup &&
                              advanceSearchPreperation.typeGroup.open_top
                                ? true
                                : false
                            }
                          />
                        }
                        label={__translate(
                          "wo.advance_search.by_type.open_top"
                        )}
                        onChange={(event) => {
                          handelOnChange(event, "typeGroup");
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex p-0">
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-between p-0">
                      <FormControlLabel
                        name="flat"
                        style={{ margin: "0", padding: "0" }}
                        control={
                          <Checkbox
                            checked={
                              advanceSearchPreperation.typeGroup &&
                              advanceSearchPreperation.typeGroup.flat
                                ? true
                                : false
                            }
                          />
                        }
                        label={__translate("wo.advance_search.by_type.flat")}
                        onChange={(event) => {
                          handelOnChange(event, "typeGroup");
                        }}
                      />
                    </div>
                  </div>
                </FormGroup>
              </fieldset>
              <fieldset className="advanceSearch__fieldset">
                <legend className="advanceSearch__legend">
                  {__translate("wo.advance_search.by_length")}{" "}
                </legend>

                <FormGroup>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex p-0">
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-between p-0">
                      <FormControlLabel
                        name="20"
                        style={{ margin: "0", padding: "0" }}
                        control={
                          <Checkbox
                            checked={
                              advanceSearchPreperation.lengthGroup &&
                              advanceSearchPreperation.lengthGroup[20]
                                ? true
                                : false
                            }
                          />
                        }
                        label={__translate(
                          "wo.advance_search.by_length.20_feet"
                        )}
                        onChange={(event) => {
                          handelOnChange(event, "lengthGroup");
                        }}
                      />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-between p-0">
                      <FormControlLabel
                        name="40"
                        style={{ margin: "0", padding: "0" }}
                        control={
                          <Checkbox
                            checked={
                              advanceSearchPreperation.lengthGroup &&
                              advanceSearchPreperation.lengthGroup[40]
                                ? true
                                : false
                            }
                          />
                        }
                        label={__translate(
                          "wo.advance_search.by_length.40_feet"
                        )}
                        onChange={(event) => {
                          handelOnChange(event, "lengthGroup");
                        }}
                      />
                    </div>
                  </div>
                </FormGroup>
              </fieldset>
              <fieldset className="advanceSearch__fieldset">
                <legend className="advanceSearch__legend">
                  {" "}
                  {__translate("wo.advance_search.empty_return")}
                </legend>
                <FormGroup>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex p-0">
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-between p-0">
                      <FormControlLabel
                        name="true"
                        style={{ margin: "0", padding: "0" }}
                        control={
                          <Checkbox
                            checked={
                              advanceSearchPreperation.returningGroup &&
                              advanceSearchPreperation.returningGroup.true
                                ? true
                                : false
                            }
                          />
                        }
                        label={__translate(
                          "wo.advance_search.with_empty_return"
                        )}
                        onChange={(event) => {
                          handelOnChange(event, "returningGroup");
                        }}
                      />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-between p-0">
                      <FormControlLabel
                        name="false"
                        style={{ margin: "0", padding: "0" }}
                        control={
                          <Checkbox
                            checked={
                              advanceSearchPreperation.returningGroup &&
                              advanceSearchPreperation.returningGroup.false
                                ? true
                                : false
                            }
                          />
                        }
                        label={__translate(
                          "wo.advance_search.without_empty_return"
                        )}
                        onChange={(event) => {
                          handelOnChange(event, "returningGroup");
                        }}
                      />
                    </div>
                  </div>
                </FormGroup>
              </fieldset>
              <fieldset className="advanceSearch__fieldset">
                <legend className="advanceSearch__legend">
                  {__translate("wo.advance_search.inspection")}
                </legend>
                <FormGroup>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex p-0">
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-between p-0">
                      <FormControlLabel
                        name="true"
                        style={{ margin: "0", padding: "0" }}
                        control={
                          <Checkbox
                            checked={
                              advanceSearchPreperation.inspectionGroup &&
                              advanceSearchPreperation.inspectionGroup.true
                                ? true
                                : false
                            }
                          />
                        }
                        label={__translate("wo.advance_search.with_inspection")}
                        onChange={(event) => {
                          handelOnChange(event, "inspectionGroup");
                        }}
                      />
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-between p-0">
                      <FormControlLabel
                        name="fasle"
                        style={{ margin: "0", padding: "0" }}
                        control={
                          <Checkbox
                            checked={
                              advanceSearchPreperation.inspectionGroup &&
                              advanceSearchPreperation.inspectionGroup.false
                                ? true
                                : false
                            }
                          />
                        }
                        label={__translate(
                          "wo.advance_search.without_inspection"
                        )}
                        onChange={(event) => {
                          handelOnChange(event, "inspectionGroup");
                        }}
                      />
                    </div>
                  </div>
                </FormGroup>
              </fieldset>

              <fieldset className="advanceSearch__fieldset ">
                <legend className="advanceSearch__legend">
                  {__translate("wo.advance_search.by_weight")}
                </legend>

                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 advanceSearch__weight">
                  <Box>
                    <Slider
                      getAriaLabel={() => "Temperature range"}
                      value={value}
                      onChange={handleChange}
                      valueLabelDisplay="auto"
                      min={0}
                      max={50}
                      sx={{ color: "#2f90b0;" }}
                    />
                  </Box>
                  <div
                    className="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <input
                      className="col-xs-3 col-sm-3 col-md-3 col-lg-3 form-control advanceSearch__weight--fields"
                      value={secondValue}
                      onChange={(event) => {
                        handleChange(event, "second");
                      }}
                    />
                    <input
                      className="col-xs-3 col-sm-3 col-md-3 col-lg-3 form-control advanceSearch__weight--fields"
                      value={firstValue}
                      onChange={(event) => {
                        handleChange(event, "first");
                      }}
                    />
                  </div>
                </div>
              </fieldset>
              {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 advanceSearch__searchBtn ">
                <button
                  className="btn btn-primary col-xs-12 col-sm-12 col-md-12 col-lg-12 advanceSearch__searchBtn--fontSize"
                  onClick={toggleDrawer(anchor, false)}
                >
                  <span>بحث</span>
                  <i className="fa-solid fa-filter mx-2"></i>
                </button>
              </div> */}
            </div>
          </ListItemIcon>
          <ListItemText />
        </ListItem>
      </List>
      <Divider />
    </Box>
  );

  return (
    <div>
      <div>
        {["bottom"].map((anchor) => (
          <React.Fragment key={anchor}>
            {/* <Button
              variant="contained"
              endIcon={<FilterAltIcon />}
              onClick={toggleDrawer(anchor, true)}
            >
              بحث
            </Button> */}
            <Drawer
              anchor={anchor}
              open={bottom[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              <AppBar
                position="absolute"
                sx={{
                  padding: "0",
                  margin: "0",
                }}
              >
                <Toolbar
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  sx={{ backgroundColor: "#369cbe" }}
                >
                  <ClearOutlinedIcon
                    onClick={toggleDrawer(anchor, false)}
                    style={{ cursor: "pointer" }}
                  />
                  <Typography variant="h6" noWrap component="div">
                    {__translate("wo.advance_search")}
                  </Typography>
                  <SearchOutlinedIcon
                    onClick={toggleDrawer(anchor, false)}
                    style={{ cursor: "pointer" }}
                  />
                </Toolbar>
              </AppBar>
              {list(anchor)}
            </Drawer>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
