import React from "react";
import AdvanceSearch from "./advance_search";
import MobileCard from "./card";
import BackDrop from "./DropBack";
function CardList(props) {
  return (
    <div className="cardlist__container">
      <MobileCard isLoading={props.isLoading} data={props.data} />
      <BackDrop />
    </div>
  );
}

export default CardList;
