import React, { useState } from "react";
import Search from "./search";
import MobileCard from "./card";
import { t as __translate } from "i18next";
import BreadCrumbs from "../../../../components/breadCrumbs/breadCrumbs";
import DropBack from "./DropBack";
function CardList(props) {
  return (
    <React.Fragment>
      {/* <BreadCrumbs
        crumpsDetails={[
          {
            href: "/",
            titel: __translate("public.bread_crumbs.home"),
          },
          {
            href: "/",
            titel: __translate("public.bread_crumbs.my_cargos"),
          },
        ]}
      /> */}
      <div className="cardlist__container">
        <div>
          <DropBack handelSearchBtn={props.handelSearchBtn} />
        </div>
        <MobileCard data={props.data} isLoading={props.isLoading} />
        {/* <button
          className="btn primary__btn m-auto d-block"
          onClick={() => {
            props.loadMore();
          }}
        >
          إضغط لتحميل المزيد
        </button> */}
      </div>
    </React.Fragment>
  );
}

export default CardList;
