import React from "react";
import { useNavigate } from "react-router-dom";

function BackBtn() {
  let navigate = useNavigate();
  return (
    <>
      <span
        style={{ borderRadius: "50%", fontSize: "1.25rem" }}
        className="mx-4"
        onClick={() => navigate(-1)}
      >
        <i class="fa-solid fa-arrow-left"></i>
      </span>
    </>
  );
}

export default BackBtn;
