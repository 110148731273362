import React, { useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import Details from "./userPorfileItems/details";
import History from "./userPorfileItems/history";
import Rating from "./userPorfileItems/rating";
import InfoIcon from "@mui/icons-material/Info";
import ArchiveIcon from "@mui/icons-material/Archive";

// function MobileUserProfile() {
// const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

// return Array.from(new Array(50)).map(
//   () => messageExamples[getRandomInt(messageExamples.length)]
// );
// }

export default function FixedBottomNavigation() {
  const [value, setValue] = React.useState(0);
  const [renderDetails, setRenderDetails] = useState(true);
  const [renderHistory, setRenderHistory] = useState(false);
  const [renderRating, setRenderRating] = useState(false);
  // const ref = React.useRef(null);

  const renderTargetPage = (selectedPageIndex) => {
    setRenderDetails(false);
    setRenderHistory(false);
    setRenderRating(false);
    if (selectedPageIndex === 0) {
      setRenderDetails(true);
    }
    if (selectedPageIndex === 1) {
      setRenderHistory(true);
    }
    if (selectedPageIndex === 2) {
      setRenderRating(true);
    }
  };

  return (
    <Box sx={{ pb: 7 }}>
      <CssBaseline />
      {renderDetails && <Details />}
      {renderHistory && <History />}
      {renderRating && <Rating />}
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, selectedPageIndex) => {
            setValue(selectedPageIndex);
            renderTargetPage(selectedPageIndex);
          }}
        >
          <BottomNavigationAction label="التفاصيل" icon={<InfoIcon />} />
          <BottomNavigationAction label="سجلي" icon={<ArchiveIcon />} />
          <BottomNavigationAction label="تقيماتي" icon={<StarHalfIcon />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
