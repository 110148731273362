import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";

function MobileSckeleton() {
  return (
    <div>
      <Card style={{ padding: "1rem", marginBottom: "2vh" }}>
        <Stack spacing={1}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "100%" }}>
              <Skeleton
                variant="rectangular"
                style={{ width: "35%" }}
                height={40}
              />
              <div style={{ display: "flex" }}>
                <Skeleton
                  variant="rounded"
                  style={{ width: "15%", margin: "1rem" }}
                  height={30}
                />
                <Skeleton
                  variant="rounded"
                  style={{ width: "15%", margin: "1rem" }}
                  height={30}
                />
                <Skeleton
                  variant="rounded"
                  style={{ width: "15%", margin: "1rem" }}
                  height={30}
                />
                <Skeleton
                  variant="rounded"
                  style={{ width: "15%", margin: "1rem" }}
                  height={30}
                />
              </div>
              <Skeleton
                variant="rectangular"
                style={{ width: "90%" }}
                height={60}
              />
            </div>
          </div>
        </Stack>
      </Card>
    </div>
  );
}

export default MobileSckeleton;
